import React, { useState, useEffect } from "react";
import {
  FaGoogle,
  FaInfoCircle,
  FaSpinner,
  FaCheck,
  FaSearch,
} from "react-icons/fa";
import toast from "react-hot-toast";
import * as api from "../../../utils/api";

const TagConfigStep = ({
  selectedProject,
  targetUrl,
  postId,
  setPostId,
  tagName,
  setTagName,
  matchOptions,
  setMatchOptions,
  loading,
  setLoading,
  conversionResourceName,
  setConversionResourceName,
  conversionLabel,
  setConversionLabel,
  createdTag,
  setCreatedTag,
  publishStatus,
  setPublishStatus,
  isPublishing,
  setIsPublishing,
  onBack,
  onReset,
}) => {
  const [step, setStep] = useState("initial"); // initial | checking | verified | creating | created | publishing | published | error
  const [errorDetails, setErrorDetails] = useState(null);
  const [tagVerified, setTagVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [creationProgress, setCreationProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState("");

  const handleVerifyTag = async () => {
    if (!tagName || !postId) {
      toast.error("Por favor, preencha o nome da tag e Post ID");
      return;
    }

    try {
      setIsVerifying(true);
      setErrorDetails(null);

      // Verificar no Google Ads
      const adsResult = await api.listConversionActions(selectedProject.id, {
        name: `${tagName}_${postId}`,
      });

      // Verificar no GTM
      const gtmResult = await api.searchGtmTags(
        selectedProject.id,
        `${tagName}_${postId}`,
        true
      );

      // Se encontrar em algum dos dois serviços
      if (
        (Array.isArray(adsResult) && adsResult.length > 0) ||
        (gtmResult.success && gtmResult.tags.length > 0)
      ) {
        toast.error(
          "Esta tag de conversão já existe. Por favor, escolha outro nome."
        );
        setErrorDetails({
          message: "Tag com este nome já existe",
          code: "DUPLICATE_NAME",
        });
        setTagVerified(false);
        setStep("error");
      } else {
        toast.success("Nome de tag disponível! Você pode prosseguir.");
        setTagVerified(true);
        setStep("verified");
      }
    } catch (error) {
      console.error("Erro ao verificar tags existentes:", error);
      toast.error("Erro ao verificar tag: " + error.message);
      setTagVerified(false);
      setStep("error");
    } finally {
      setIsVerifying(false);
    }
  };

  const handleCreateTag = async (e) => {
    e.preventDefault();

    if (!tagName.trim()) {
      toast.error("Por favor, informe um nome para a conversão");
      return;
    }

    if (!tagVerified) {
      toast.error(
        "Por favor, verifique se o nome da tag está disponível primeiro"
      );
      return;
    }

    try {
      setLoading(true);
      setStep("creating");
      setCreationProgress(10);
      setProgressStatus("Iniciando criação da conversão...");

      // 1. Criar conversão no Google Ads
      setProgressStatus("Criando ação de conversão no Google Ads...");
      const conversionResult = await api.createConversionAction(
        selectedProject.id,
        `${tagName}_${postId}`
      );
      setCreationProgress(30);

      if (!conversionResult.success) {
        throw new Error("Falha ao criar ação de conversão no Google Ads");
      }

      // Guardar resource name para uso posterior
      if (conversionResult.resourceName) {
        setConversionResourceName(conversionResult.resourceName);

        // 2. Criar objetivo de conversão
        setProgressStatus("Criando objetivo de conversão...");
        const goalResult = await api.createConversionGoal(
          selectedProject.id,
          conversionResult.resourceName,
          `${tagName.toLowerCase()}_${postId}_goal`
        );
        setCreationProgress(50);

        if (!goalResult.success) {
          throw new Error("Falha ao criar objetivo de conversão");
        }
      } else {
        throw new Error("Resposta inválida ao criar conversão");
      }

      // 3. Recuperar detalhes da conversão para obter o label
      setProgressStatus("Obtendo detalhes da conversão...");
      const conversionDetails = await api.listConversionActions(
        selectedProject.id,
        { resourceName: conversionResult.resourceName }
      );
      setCreationProgress(70);

      if (!conversionDetails || !conversionDetails.label) {
        throw new Error("Não foi possível obter o label da conversão");
      }

      const { id: conversionId, label: conversionLabelValue } =
        conversionDetails.label;
      setConversionLabel({ id: conversionId, label: conversionLabelValue });

      // 4. Criar tag no GTM
      setProgressStatus("Criando tag no Google Tag Manager...");
      const tagResult = await api.createGtmTag(selectedProject.id, {
        url: targetUrl,
        wordpressButtonMatch: matchOptions.wordpress,
        elementorButtonMatch: matchOptions.elementor,
        tagName: `${tagName}_${postId}`,
        googleConversionId: conversionId,
        googleConversionLabel: conversionLabelValue,
      });
      setCreationProgress(90);

      if (!tagResult.success) {
        if (tagResult.code === "DUPLICATE_NAME") {
          setErrorDetails({
            message:
              tagResult.error || "Já existe uma tag com este nome no container",
            code: "DUPLICATE_NAME",
          });
          setStep("error");
          return;
        }
        throw new Error(tagResult.error || "Falha ao criar tag no GTM");
      }

      setCreatedTag(tagResult.tag);
      setCreationProgress(100);
      setProgressStatus("Conversão criada com sucesso!");
      setStep("created");

      toast.success(
        `Conversão "${tagName}_${postId}" criada com sucesso no Google Ads e configurada no GTM para o projeto ${selectedProject.projectName}`
      );
    } catch (error) {
      console.error("Erro ao criar conversão:", error);
      toast.error("Erro ao criar conversão: " + error.message);
      setErrorDetails({
        message: error.message,
        code: "GENERAL_ERROR",
      });
      setStep("error");
    } finally {
      setLoading(false);
    }
  };

  const handlePublishChanges = async () => {
    try {
      setIsPublishing(true);
      setProgressStatus("Publicando alterações no Google Tag Manager...");
      setCreationProgress(0);

      // Iniciar animação de progresso para feedback visual
      const interval = setInterval(() => {
        setCreationProgress((prev) => {
          if (prev >= 90) {
            clearInterval(interval);
            return 90;
          }
          return prev + 10;
        });
      }, 300);

      const publishResult = await api.publishGtmChanges(
        selectedProject.id,
        "Publicação com novas tags de conversão"
      );

      clearInterval(interval);
      setCreationProgress(100);

      if (publishResult.success) {
        setPublishStatus(publishResult);
        setStep("published");
        setProgressStatus("Alterações publicadas com sucesso!");
        toast.success(
          "Alterações publicadas com sucesso no Google Tag Manager!"
        );
      } else {
        setPublishStatus(publishResult);
        setStep("publish-error");
        setProgressStatus("Erro ao publicar alterações");
        toast.error(
          "Erro ao publicar alterações. Você precisará publicar manualmente no GTM."
        );
      }
    } catch (error) {
      console.error("Erro ao publicar alterações:", error);
      toast.error("Erro ao publicar alterações: " + error.message);
      setPublishStatus({ success: false, error: error.message });
      setStep("publish-error");
    } finally {
      setIsPublishing(false);
    }
  };

  // Efeito para limpar o estado de verificação quando o nome da tag muda
  useEffect(() => {
    setTagVerified(false);
    setStep("initial");
  }, [tagName, postId]);

  if (!selectedProject) return null;

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
      <div className="flex items-center mb-6">
        <div className="bg-blue-100 p-2 rounded-full mr-3">
          <FaGoogle className="text-blue-500" />
        </div>
        <div>
          <h2 className="text-xl font-medium">
            Configurar Conversão no Google Ads para{" "}
            {selectedProject.projectName}
          </h2>
          <p className="text-gray-500 text-sm">
            Esta conversão será criada no Google Ads e configurada no container
            GTM {selectedProject.gtmContainerId}
          </p>
        </div>
      </div>

      <div className="bg-blue-50 border border-blue-200 rounded-md p-4 mb-6">
        <div className="flex items-start">
          <FaInfoCircle className="text-blue-500 mt-1 mr-2" />
          <div>
            <h3 className="font-medium text-blue-700">Informações da Página</h3>
            <p className="text-sm text-blue-600 mt-1">URL: {targetUrl}</p>
            {postId ? (
              <p className="text-sm text-blue-600">
                Post ID: <span className="font-bold">{postId}</span>
              </p>
            ) : (
              <div className="mt-2">
                <label
                  htmlFor="manualPostId"
                  className="block text-sm font-medium text-blue-700"
                >
                  Post ID (informe manualmente):
                </label>
                <input
                  type="text"
                  id="manualPostId"
                  defaultValue=""
                  onBlur={(e) => setPostId(e.target.value)}
                  className="mt-1 p-2 border border-blue-300 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Ex: 1234"
                  required
                />
                <p className="text-xs text-blue-500 mt-1">
                  Tente localizar o Post ID no URL ou no painel administrativo
                  do WordPress.
                </p>
              </div>
            )}
            <div className="mt-3 border-t border-blue-200 pt-2">
              <p className="text-xs text-blue-600 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                Esta ferramenta gera tags para containers web do GTM. Não é
                compatível com conversões server-side (sGTM/stape).
              </p>
            </div>
          </div>
        </div>
      </div>

      {step === "error" && errorDetails && (
        <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <FaInfoCircle className="text-red-500" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Erro ao {step === "creating" ? "criar" : "verificar"} conversão
              </h3>
              <p className="text-sm text-red-700 mt-1">
                {errorDetails.message}
              </p>

              {errorDetails.code === "DUPLICATE_NAME" && (
                <div className="mt-3">
                  <p className="text-sm text-red-700">
                    Por favor, escolha outro nome para sua tag de conversão.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {(step === "creating" || isPublishing) && (
        <div className="mb-6">
          <div className="flex items-center mb-2">
            <FaSpinner className="animate-spin text-blue-500 mr-2" />
            <p className="text-sm font-medium text-gray-700">
              {progressStatus}
            </p>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${creationProgress}%` }}
            ></div>
          </div>
        </div>
      )}

      {(step === "initial" || step === "error") && (
        <form onSubmit={handleCreateTag}>
          <div className="mb-6">
            <label
              htmlFor="tagName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Nome da Conversão
            </label>
            <div className="flex items-center">
              <input
                type="text"
                id="tagName"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded-l-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Ex: Clique Botão Compra"
                required
              />
              <div className="bg-gray-100 p-2 border border-gray-300 border-l-0 rounded-r-md text-gray-700">
                _{postId || "ID"}
              </div>
              <button
                type="button"
                onClick={handleVerifyTag}
                disabled={isVerifying || !tagName || !postId}
                className="ml-2 px-4 py-2 bg-gray-100 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-200 flex items-center disabled:opacity-50"
              >
                {isVerifying ? (
                  <FaSpinner className="animate-spin mr-2" />
                ) : (
                  <FaSearch className="mr-2" />
                )}
                Verificar
              </button>
            </div>
            <p className="text-xs text-gray-500 mt-1">
              O nome da conversão no Google Ads será concatenado com o Post ID
              para evitar duplicidades. Clique em "Verificar" para confirmar
              disponibilidade.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-sm font-medium text-gray-700 mb-3">
              Opções de Detecção de Cliques
            </h3>

            <div className="space-y-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="wordpress"
                  checked={matchOptions.wordpress}
                  onChange={(e) =>
                    setMatchOptions({
                      ...matchOptions,
                      wordpress: e.target.checked,
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="wordpress"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Detectar cliques em botões do WordPress
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="elementor"
                  checked={matchOptions.elementor}
                  onChange={(e) =>
                    setMatchOptions({
                      ...matchOptions,
                      elementor: e.target.checked,
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="elementor"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Detectar cliques em botões do Elementor
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="matchByUrl"
                  checked={matchOptions.matchByUrl}
                  onChange={(e) =>
                    setMatchOptions({
                      ...matchOptions,
                      matchByUrl: e.target.checked,
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="matchByUrl"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Restringir conversões a esta URL{" "}
                  <span className="text-green-600 text-xs">(Recomendado)</span>
                </label>
              </div>

              <div className="bg-yellow-50 border border-yellow-200 rounded-md p-3 mt-2">
                <p className="text-sm text-yellow-700">
                  <strong>Recomendação:</strong> Para maior precisão,
                  recomendamos manter ambas as opções de detecção (WordPress e
                  Elementor) e a restrição por URL ativas.
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onBack}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Voltar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
              disabled={loading || !tagName.trim() || !postId || !tagVerified}
            >
              {loading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                  Processando...
                </>
              ) : (
                "Criar Conversão"
              )}
            </button>
          </div>
        </form>
      )}

      {step === "verified" && (
        <form onSubmit={handleCreateTag}>
          <div className="mb-6">
            <label
              htmlFor="tagName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Nome da Conversão
            </label>
            <div className="flex items-center">
              <input
                type="text"
                id="tagName"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded-l-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Ex: Clique Botão Compra"
                required
                readOnly
              />
              <div className="bg-gray-100 p-2 border border-gray-300 border-l-0 rounded-r-md text-gray-700">
                _{postId || "ID"}
              </div>
              <div className="ml-2 flex items-center text-green-600">
                <FaCheck className="mr-1" />
                <span className="text-sm">Disponível</span>
              </div>
            </div>
            <p className="text-xs text-gray-500 mt-1">
              Nome verificado e disponível para uso.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-sm font-medium text-gray-700 mb-3">
              Opções de Detecção de Cliques
            </h3>

            <div className="space-y-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="wordpress"
                  checked={matchOptions.wordpress}
                  onChange={(e) =>
                    setMatchOptions({
                      ...matchOptions,
                      wordpress: e.target.checked,
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="wordpress"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Detectar cliques em botões do WordPress
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="elementor"
                  checked={matchOptions.elementor}
                  onChange={(e) =>
                    setMatchOptions({
                      ...matchOptions,
                      elementor: e.target.checked,
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="elementor"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Detectar cliques em botões do Elementor
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="matchByUrl"
                  checked={matchOptions.matchByUrl}
                  onChange={(e) =>
                    setMatchOptions({
                      ...matchOptions,
                      matchByUrl: e.target.checked,
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="matchByUrl"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Restringir conversões a esta URL{" "}
                  <span className="text-green-600 text-xs">(Recomendado)</span>
                </label>
              </div>

              <div className="bg-yellow-50 border border-yellow-200 rounded-md p-3 mt-2">
                <p className="text-sm text-yellow-700">
                  <strong>Recomendação:</strong> Para maior precisão,
                  recomendamos manter ambas as opções de detecção (WordPress e
                  Elementor) e a restrição por URL ativas.
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onBack}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Voltar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
              disabled={loading || !tagName.trim() || !postId}
            >
              {loading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                  Processando...
                </>
              ) : (
                "Criar Conversão"
              )}
            </button>
          </div>
        </form>
      )}

      {step === "created" && (
        <div>
          <div className="bg-green-50 border border-green-200 rounded-md p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <FaCheck className="text-green-500" />
              </div>
              <div className="ml-3 w-full">
                <h3 className="text-sm font-medium text-green-800">
                  Conversão criada com sucesso!
                </h3>

                <div className="mt-4 space-y-3">
                  <div className="bg-white p-3 rounded-md border border-green-300">
                    <p className="text-sm font-medium text-gray-700 mb-1">
                      Tag no Google Tag Manager:
                    </p>
                    <div className="flex items-center">
                      <code className="bg-gray-100 px-3 py-1 rounded flex-1 text-sm">
                        {createdTag?.name}
                      </code>
                      <button
                        className="ml-2 text-blue-600 hover:bg-blue-50 p-1 rounded-md"
                        onClick={() => {
                          navigator.clipboard.writeText(createdTag?.name);
                          toast.success("Nome da tag copiado!");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="bg-white p-3 rounded-md border border-green-300">
                    <p className="text-sm font-medium text-gray-700 mb-1">
                      Conversão no Google Ads:
                    </p>
                    <div className="flex items-center">
                      <code className="bg-gray-100 px-3 py-1 rounded flex-1 text-sm">{`${tagName}_${postId}`}</code>
                      <button
                        className="ml-2 text-blue-600 hover:bg-blue-50 p-1 rounded-md"
                        onClick={() => {
                          navigator.clipboard.writeText(`${tagName}_${postId}`);
                          toast.success("Nome da conversão copiado!");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="bg-white p-3 rounded-md border-2 border-green-400">
                    <p className="text-sm font-medium text-gray-700 mb-1">
                      <span className="bg-green-100 text-green-800 text-xs font-medium px-2 py-0.5 rounded">
                        IMPORTANTE
                      </span>
                      <span className="ml-2">
                        Objetivo de Conversão (para usar nas campanhas):
                      </span>
                    </p>
                    <div className="flex items-center">
                      <code className="bg-gray-100 px-3 py-1 rounded flex-1 text-sm font-medium">{`${tagName.toLowerCase()}_${postId}_goal`}</code>
                      <button
                        className="ml-2 text-blue-600 hover:bg-blue-50 p-1 rounded-md"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${tagName.toLowerCase()}_${postId}_goal`
                          );
                          toast.success("Nome do objetivo copiado!");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="bg-white p-3 rounded-md border border-gray-300">
                    <p className="text-sm font-medium text-gray-700 mb-1">
                      Detalhes técnicos:
                    </p>
                    <ul className="text-xs text-gray-600 list-disc list-inside space-y-1">
                      <li>ID do Trigger: {createdTag?.triggers?.join(", ")}</li>
                      <li>
                        ID da Conversão:{" "}
                        <span className="font-mono">{conversionLabel?.id}</span>
                      </li>
                      <li>
                        Label da Conversão:{" "}
                        <span className="font-mono">
                          {conversionLabel?.label}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-4 bg-blue-50 p-3 rounded-md border border-blue-200">
                  <p className="text-sm text-blue-700">
                    <strong>Importante:</strong> As alterações ainda precisam
                    ser publicadas no Google Tag Manager para entrarem em vigor.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onReset}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Criar Nova Conversão
            </button>
            <button
              type="button"
              onClick={handlePublishChanges}
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center"
              disabled={isPublishing}
            >
              {isPublishing ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                  Publicando...
                </>
              ) : (
                "Publicar Alterações"
              )}
            </button>
          </div>
        </div>
      )}

      {step === "published" && (
        <div>
          <div className="bg-green-50 border border-green-200 rounded-md p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <FaCheck className="text-green-500" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  Alterações publicadas com sucesso!
                </h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>
                    A versão {publishStatus?.version?.containerVersionId} foi
                    publicada no container GTM.
                  </p>
                  <p>Nome da versão: {publishStatus?.version?.name}</p>
                  <p>
                    As conversões já estão ativas e rastreando cliques nos
                    botões da página.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onReset}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Criar Nova Conversão
            </button>
          </div>
        </div>
      )}

      {step === "publish-error" && (
        <div>
          <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <FaInfoCircle className="text-yellow-500" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Conversão criada, mas não foi possível publicar
                  automaticamente
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    A conversão e as tags foram criadas com sucesso, mas não
                    conseguimos publicar automaticamente no GTM.
                  </p>
                  <p className="mt-1">
                    Motivo: {publishStatus?.error || "Erro desconhecido"}
                  </p>
                  <p className="mt-2 font-medium">
                    Por favor, acesse o Google Tag Manager e publique
                    manualmente as alterações.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onReset}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Criar Nova Conversão
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagConfigStep;
