// src/pages/ProjectDetailsPage.js
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  FaGoogle,
  FaGlobe,
  FaChartLine,
  FaTrash,
  FaCog,
  FaUsers,
  FaArrowLeft,
  FaTag,
  FaEdit,
  FaAd,
} from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import {
  IntegrateAccount,
  ManageMcc,
  EditGoogleAdsAccountModal,
  VerifyConnection,
} from "../components/googleads";
import AddToAdManager from "../components/admanager/AddToAdManager";
import AddDomain from "../components/AddDomain";
import TrafficManagers from "../components/TrafficManagers";
import ProjectConfigurations from "../components/ProjectConfigurations";
import DetailedTrackingPresets from "../components/DetailedTrackingPresets";
import TrackingTemplateWarning from "../components/TrackingTemplateWarning";
import IntegrateGtm from "../components/googletagmanager/IntegrateGtm";
import ErrorNotifications from "../components/ErrorNotifications";
import CollapsibleCard from "../components/collapsible/CollapsibleCard";
import * as api from "../utils/api";

// Componentes Base
const IntegrationCard = ({ icon: Icon, title, children, className = "" }) => {
  if (!Icon) return null; // Proteção contra Icon undefined

  return (
    <div className={`bg-white rounded-lg shadow-sm p-6 ${className}`}>
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-gray-50 rounded-lg">
          <Icon className="text-gray-700" size={20} />
        </div>
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
      </div>
      {children}
    </div>
  );
};

const StatusBadge = ({ type, text }) => {
  const styles = {
    success: "bg-green-50 text-green-700 border-green-100",
    warning: "bg-yellow-50 text-yellow-700 border-yellow-100",
    error: "bg-red-50 text-red-700 border-red-100",
    info: "bg-blue-50 text-blue-700 border-blue-100",
  };

  return (
    <span
      className={`px-2.5 py-1 text-xs font-medium rounded-full border ${styles[type]}`}
    >
      {text}
    </span>
  );
};

// Novo componente SubHeader
const SubHeader = ({ projectId, onBack }) => (
  <div className="bg-white border-b mb-6">
    <div className="max-w-5xl mx-auto px-6 py-4 flex items-center justify-between">
      <div className="flex items-center gap-4">
        <button
          onClick={onBack}
          className="text-gray-600 hover:text-gray-900 transition-colors"
        >
          <FaArrowLeft size={16} />
        </button>
        <h2 className="text-lg font-medium text-gray-900">
          Projeto #{projectId}
        </h2>
      </div>
      <div className="flex gap-3">
        <button
          onClick={() =>
            (window.location.href = `/project/${projectId}/performance`)
          }
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <FaChartLine size={16} />
          Performance
        </button>
      </div>
    </div>
  </div>
);

// Componentes de Seção
const ProjectHeader = ({ project }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
    <div>
      <h1 className="text-2xl font-bold text-gray-900">
        {project.projectName || project.projectType}
      </h1>
      <div className="flex gap-2 mt-2">
        <StatusBadge type="info" text={`ID: ${project.id}`} />
        {project.projectAbbreviation && (
          <StatusBadge
            type="info"
            text={`Sigla: ${project.projectAbbreviation}`}
          />
        )}
      </div>
    </div>
  </div>
);

const IntegrationsGrid = ({
  project,
  domain,
  setDomain,
  googleAdsAccounts,
  selectedAccount,
  handleIntegrateGoogleAds,
  handleResetGoogleAds,
  accountId,
  userScopes = [],
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const hasGtmScope = userScopes.includes("gtm");

  const handleModalClose = (wasUpdated) => {
    setIsEditModalOpen(false);
    if (wasUpdated) {
      window.location.reload();
    }
  };

  return (
    <div className="flex flex-wrap -mx-3 mb-6">
      {/* Domínio */}
      <div className="w-full md:w-1/2 px-3 pb-6">
        <CollapsibleCard
          icon={FaGlobe}
          title="Domínio"
          isIntegrated={domain ? true : false}
          defaultExpanded={!domain}
          statusText={domain ? domain : "Configure o domínio"}
        >
          <AddDomain
            projectId={project.id}
            existingDomain={domain}
            onDomainUpdated={setDomain}
          />
        </CollapsibleCard>
      </div>

      {/* Google Ads */}
      <div className="w-full md:w-1/2 px-3 pb-6">
        <CollapsibleCard
          icon={FaGoogle}
          title="Google Ads"
          isIntegrated={googleAdsAccounts.length > 0}
          defaultExpanded={googleAdsAccounts.length === 0}
          statusText={
            googleAdsAccounts.length > 0
              ? googleAdsAccounts.join(", ")
              : "Configure o Google Ads"
          }
        >
          <div className="space-y-4">
            {googleAdsAccounts.length ? (
              <>
                <div className="flex items-center justify-between">
                  <p className="text-gray-600">
                    {googleAdsAccounts.join(", ")}
                  </p>
                  <div className="flex items-center space-x-2">
                    <button
                      title="Editar conta Google Ads"
                      onClick={() => setIsEditModalOpen(true)}
                      className="text-gray-400 hover:text-blue-500 transition-colors"
                    >
                      <FaEdit size={16} />
                    </button>
                    <button
                      title="Remover integração Google Ads"
                      onClick={handleResetGoogleAds}
                      className="text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <FaTrash size={16} />
                    </button>
                  </div>
                </div>

                <VerifyConnection projectId={project.id} />

                <EditGoogleAdsAccountModal
                  isOpen={isEditModalOpen}
                  onClose={handleModalClose}
                  projectId={project.id}
                  currentAccounts={googleAdsAccounts}
                />

                <div className="mt-4 pt-4 border-t border-gray-200">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Gerenciar MCC
                  </h4>
                  <ManageMcc
                    projectId={project.id}
                    existingMccId={project.googleAdsMccId}
                    projectAccountId={project.accountId}
                  />
                </div>
              </>
            ) : (
              <>
                <IntegrateAccount projectId={project.id} />

                {project.accountId === accountId && (
                  <div className="mt-4 pt-4 border-t border-gray-200">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      Gerenciar MCC
                    </h4>
                    <ManageMcc
                      projectId={project.id}
                      existingMccId={project.googleAdsMccId}
                      projectAccountId={project.accountId}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </CollapsibleCard>
      </div>

      {/* Google Ad Manager */}
      <div className="w-full md:w-1/2 px-3 pb-6">
        <CollapsibleCard
          icon={FaAd}
          title="Google Ad Manager"
          isIntegrated={project.admanagerId ? true : false}
          defaultExpanded={!project.admanagerId}
          statusText={
            project.admanagerId
              ? "Integração concluída"
              : "Configure o Ad Manager"
          }
        >
          <AddToAdManager project={project} />
        </CollapsibleCard>
      </div>

      {/* Google Tag Manager - só mostra se o usuário tiver permissão "gtm" */}
      {hasGtmScope && (
        <div className="w-full md:w-1/2 px-3 pb-6">
          <CollapsibleCard
            icon={FaTag}
            title="Google Tag Manager"
            isIntegrated={project.gtmAccountId && project.gtmContainerId}
            defaultExpanded={!(project.gtmAccountId && project.gtmContainerId)}
            statusText={
              project.gtmAccountId && project.gtmContainerId
                ? "Integração concluída"
                : "Configure o Tag Manager"
            }
          >
            <IntegrateGtm
              projectId={project.id}
              existingGtmAccountId={project.gtmAccountId}
              existingGtmContainerId={project.gtmContainerId}
            />
          </CollapsibleCard>
        </div>
      )}
    </div>
  );
};

const ConfigurationSection = ({
  project,
  accountId,
  managers,
  setManagers,
  configurations,
  roiTargets,
}) => {
  const isOwner = project.accountId === accountId;
  const isManager = project.trafficManagersIds?.includes(accountId);

  if (!isOwner && !isManager) return null;

  return (
    <div className="space-y-6">
      {/* Componente de Notificações de Erro */}
      {project.lastErrorMessages && project.lastErrorMessages.length > 0 && (
        <ErrorNotifications errorMessages={project.lastErrorMessages} />
      )}

      {isOwner && (
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 pb-6">
            <CollapsibleCard
              icon={FaCog}
              title="Template de Rastreamento"
              defaultExpanded={false}
              isIntegrated={false}
              statusText="Templates de rastreamento"
            >
              <TrackingTemplateWarning projectId={project.id} />
            </CollapsibleCard>
          </div>

          <div className="w-full px-3 pb-6">
            <CollapsibleCard
              icon={FaUsers}
              title="Gestores de Tráfego"
              defaultExpanded={false}
              isIntegrated={managers.length > 0}
              statusText={
                managers.length > 0
                  ? `${managers.length} gestores configurados`
                  : "Configure os gestores"
              }
            >
              <TrafficManagers
                projectId={project.id}
                managers={managers}
                setManagers={setManagers}
              />
            </CollapsibleCard>
          </div>

          <div className="w-full px-3 pb-6">
            <CollapsibleCard
              icon={FaCog}
              title="Configurações do Projeto"
              defaultExpanded={false}
              isIntegrated={Object.values(configurations).some(
                (value) => value !== ""
              )}
              statusText="Configurações do projeto"
            >
              <ProjectConfigurations
                projectId={project.id}
                initialConfigurations={configurations}
                initialRoiTargets={roiTargets}
              />
            </CollapsibleCard>
          </div>
        </div>
      )}

      <div className="w-full">
        <CollapsibleCard
          icon={FaCog}
          title="Presets e Filtros"
          defaultExpanded={false}
          isIntegrated={false}
          statusText="Configurações de presets"
        >
          <DetailedTrackingPresets projectId={project.id} />
        </CollapsibleCard>
      </div>
    </div>
  );
};

const ProjectDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [domain, setDomain] = useState("");
  const [googleAdsAccounts, setGoogleAdsAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [managers, setManagers] = useState([]);
  const [configurations, setConfigurations] = useState({
    RevShare: "",
    Tax: "",
    DollarDepreciation: "",
  });
  const [roiTargets, setRoiTargets] = useState({
    roiGoal: "",
    roiAbove: "",
    roiBelow: "",
  });
  const [accountId, setAccountId] = useState("");
  const [userScopes, setUserScopes] = useState([]);
  const hasFetched = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error(
            "Token não encontrado. Por favor, faça login novamente."
          );
        }

        const decodedToken = jwtDecode(token);
        const accountId = decodedToken.id;
        setAccountId(accountId);
        if (!accountId) {
          throw new Error("ID da conta não encontrado no token.");
        }

        setUserScopes(decodedToken.scopes || []);

        const projectData = await api.getProjectById(id);
        if (!projectData) {
          throw new Error("Projeto não encontrado.");
        }

        setProject(projectData);

        const { config } = projectData;
        if (config) {
          setConfigurations({
            RevShare: config.RevShare || "",
            Tax: config.Tax || "",
            DollarDepreciation: config.DollarDepreciation || "",
          });
          setRoiTargets({
            roiGoal: config.roiGoal || "",
            roiAbove: config.roiAbove || "",
            roiBelow: config.roiBelow || "",
          });
        }

        if (projectData.googleAdsId) {
          setGoogleAdsAccounts(projectData.googleAdsId);
          if (projectData.googleAdsId.length === 1) {
            setSelectedAccount(projectData.googleAdsId[0]);
          }
        }

        const domainData = await api.getDomainInProject(id, accountId);
        setDomain(domainData || "");

        const managerData = await api.getManagerIdInProject(id, accountId);
        if (managerData && managerData.managers) {
          setManagers(managerData.managers);
        }
      } catch (error) {
        const errorMessage =
          typeof error === "string"
            ? error
            : error.message || "Erro desconhecido";
        setError(errorMessage);
        toast.error(errorMessage);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProject();
  }, [id, navigate]);

  // Função para integrar uma conta do Google Ads
  const handleIntegrateGoogleAds = async (account) => {
    try {
      const response = await api.updateGoogleAdsAccount(id, account);

      // Verifica se a resposta da API tem status de sucesso
      if (response && response.success && response.data) {
        setProject(response.data); // Atualiza o estado completo do projeto
        setGoogleAdsAccounts(response.data.googleAdsId || []);
        toast.success("Conta do Google Ads integrada com sucesso!");
      } else {
        throw new Error(
          response?.error?.message || "Erro ao integrar conta do Google Ads."
        );
      }
    } catch (error) {
      toast.error("Erro ao integrar conta do Google Ads: " + error.message);
    }
  };

  // Função para resetar/desvincular todas as contas do Google Ads
  const handleResetGoogleAds = async () => {
    if (
      !window.confirm(
        "Tem certeza que deseja desvincular todas as contas do Google Ads deste projeto?"
      )
    ) {
      return;
    }

    try {
      const result = await api.resetGoogleAdsFields(id);

      // Mantém a lógica original que verifica o atributo 'success'
      if (result.success) {
        setGoogleAdsAccounts([]);
        setSelectedAccount("");
        toast.success("Contas do Google Ads foram resetadas com sucesso!");
      } else {
        throw new Error(
          result.error || "Erro ao resetar contas do Google Ads."
        );
      }
    } catch (error) {
      toast.error("Erro ao resetar contas do Google Ads: " + error.message);
    }
  };

  const handleBack = () => {
    navigate("/google-ads-api/projects");
  };

  if (isLoading) {
    return (
      <>
        <SubHeader projectId={id} onBack={handleBack} />
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-pulse text-gray-400">Carregando...</div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <SubHeader projectId={id} onBack={handleBack} />
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-red-500">{error}</div>
        </div>
      </>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <SubHeader projectId={id} onBack={handleBack} />
      <div className="p-6">
        <Toaster position="top-right" />
        <div className="max-w-5xl mx-auto space-y-6">
          <ProjectHeader project={project} />
          <IntegrationsGrid
            project={project}
            domain={domain}
            setDomain={setDomain}
            googleAdsAccounts={googleAdsAccounts}
            selectedAccount={selectedAccount}
            handleIntegrateGoogleAds={handleIntegrateGoogleAds}
            handleResetGoogleAds={handleResetGoogleAds}
            accountId={accountId}
            userScopes={userScopes}
          />
          <ConfigurationSection
            project={project}
            accountId={accountId}
            managers={managers}
            setManagers={setManagers}
            configurations={configurations}
            roiTargets={roiTargets}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;
