import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import CampaignCreation from "./pages/CampaignCreation";
import Auth from "./pages/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import TermsOfService from "./pages/TermsOfService";
import CampaignsHistory from "./pages/CampaignsHistory";
import AccountManager from "./pages/AccountManager";
import AccessDeniedPage from "./pages/AccessDenied";
import IntegrateAccount from "./pages/IntegrateAccount";
import ProjectDetails from "./pages/ProjectDetails";
import Projects from "./pages/Projects";
import ProjectPerformance from "./pages/ProjectPerformance";
import SingleCampaignPerformance from "./pages/SingleCampaignPerformance";
import KeywordChecker from "./pages/KeywordChecker";
import SystemLogs from "./pages/SystemLogs";
import SalesReport from "./pages/SalesReport";
import WorkersMonitor from "./pages/WorkersMonitor";
import RPSCalculation from "./pages/RPSCalculation";
import Pricing from "./pages/Pricing";
import WhatsAppButton from "./components/WhatsAppButton";
import Settings from "./pages/Settings";
import Billing from "./pages/Billing";
import { Toaster } from "react-hot-toast";
import UserProjects from "./pages/UserProjects";
import ProjectAdminDetails from "./pages/ProjectAdminDetails";
import CreateConversion from "./pages/tools/CreateConversion";

function ProtectedLayout({ children }) {
  return (
    <div className="flex min-h-screen">
      <Sidebar className="min-w-64" />
      <div className="flex-1 flex flex-col min-w-0 max-w-full">
        <Navbar />
        <div className="p-4 flex-1 overflow-auto">{children}</div>
        <WhatsAppButton />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
        <Routes>
          <Route path="/login" element={<Auth />} />
          <Route path="/termos" element={<TermsOfService />} />

          {/* Rotas Protegidas */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <ProtectedLayout>
                  <Routes>
                    <Route path="campaigns" element={<CampaignCreation />} />
                    <Route
                      path="campaigns-history"
                      element={<CampaignsHistory />}
                    />
                    <Route path="admin/users" element={<AccountManager />} />
                    <Route
                      path="google-ads-api/integrate-account"
                      element={<IntegrateAccount />}
                    />
                    <Route
                      path="google-ads-api/projects"
                      element={<Projects />}
                    />
                    <Route path="project/:id" element={<ProjectDetails />} />
                    <Route
                      path="project/:id/performance"
                      element={<ProjectPerformance />}
                    />
                    <Route
                      path="project/:id/performance/:campaignId/:adGroupId"
                      element={<SingleCampaignPerformance />}
                    />
                    <Route
                      path="tools/keywordschecker"
                      element={<KeywordChecker />}
                    />
                    <Route
                      path="tools/create-conversion"
                      element={
                        <ProtectedRoute requiredScope="gtm">
                          <CreateConversion />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="tools/create-button-conversion"
                      element={
                        <ProtectedRoute requiredScope="gtm">
                          <CreateConversion />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="denied" element={<AccessDeniedPage />} />
                    <Route path="system-logs" element={<SystemLogs />} />
                    <Route path="sales-report" element={<SalesReport />} />
                    <Route
                      path="/workers-monitor"
                      element={
                        <ProtectedRoute requiredScope="admin">
                          <WorkersMonitor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/rps-calculation"
                      element={<RPSCalculation />}
                    />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/billing" element={<Billing />} />
                    <Route path="user-projects" element={<UserProjects />} />
                    <Route
                      path="user-projects/:accountId"
                      element={<UserProjects />}
                    />
                    <Route
                      path="project-admin/:id"
                      element={<ProjectAdminDetails />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/campaigns" replace />}
                    />
                  </Routes>
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
