import React, { useState } from "react";
import { verifyGoogleAdsIntegration } from "../../utils/api";
import {
  FaSpinner,
  FaCheckCircle,
  FaExclamationTriangle,
  FaExclamationCircle,
  FaInfoCircle,
} from "react-icons/fa";
import { Transition } from "@headlessui/react";
import toast from "react-hot-toast";

const VerifyConnection = ({ projectId }) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const testConnection = async () => {
    if (loading) return;

    setLoading(true);
    setResult(null);
    setShowDetails(false);

    try {
      const response = await verifyGoogleAdsIntegration(projectId);

      // Verificar se a resposta tem sucesso e se o objeto response dentro dela também tem sucesso
      // ou se tem o campo results (que indica que conseguiu buscar campanhas)
      if (
        response.success &&
        response.response?.success !== false &&
        response.response?.results
      ) {
        // Caso de sucesso real
        setResult({
          ...response,
          success: true,
        });

        // Limpar o resultado após 1.5 segundos
        setTimeout(() => {
          setResult(null);
        }, 1500);
      } else {
        // Caso tenha retornado success:true no nível superior,
        // mas dentro do campo response tenha error=true ou success=false
        setResult({
          success: false,
          integration_status: "error",
          error: response.error || {
            message:
              response.response?.message || "Falha na conexão com Google Ads",
            details: response.response?.error
              ? { error: response.response.error }
              : null,
          },
        });
      }
    } catch (error) {
      setResult({
        success: false,
        integration_status: "error",
        error: {
          message: "Erro ao verificar a conexão com Google Ads",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // Função para extrair a mensagem principal do erro
  const getMainErrorMessage = (error) => {
    // Verificar se tem erro detalhado da API do Google
    if (error?.data?.error?.details?.[0]?.errors?.[0]) {
      const googleError = error.data.error.details[0].errors[0];
      return googleError.message || "Erro não especificado";
    }

    // Verificar se tem mensagem geral do Google
    if (error?.data?.error?.message) {
      return error.data.error.message;
    }

    // Mensagem padrão do objeto de erro
    return error?.message || "Erro desconhecido na conexão";
  };

  // Função para extrair o código de erro principal
  const getErrorCode = (error) => {
    // Verificar se tem código HTTP
    if (error?.status) {
      return `HTTP ${error.status}`;
    }

    // Verificar se tem código no erro detalhado
    if (error?.data?.error?.details?.[0]?.errors?.[0]?.errorCode) {
      const errorCode = error.data.error.details[0].errors[0].errorCode;
      // Pegar a primeira chave do objeto errorCode (pode ser authenticationError, etc)
      const errorType = Object.keys(errorCode)[0];
      return errorType ? `${errorCode[errorType]}` : null;
    }

    // Verificar status geral
    return error?.data?.error?.status || null;
  };

  const renderStatus = () => {
    if (loading) {
      return (
        <span className="inline-flex items-center text-blue-600 text-xs">
          <FaSpinner className="animate-spin mr-1" />
          Verificando...
        </span>
      );
    }

    if (!result) return null;

    if (result.success) {
      return (
        <span className="inline-flex items-center text-green-600 text-xs">
          <FaCheckCircle className="mr-1" />
          Conexão OK
          {result.response?.results?.[0]?.campaign && (
            <span className="ml-1">
              • Campanha: {result.response.results[0].campaign.name}
            </span>
          )}
        </span>
      );
    } else {
      // Extrair informações relevantes do erro
      const error = result.error;
      const mainMessage = getMainErrorMessage(error);
      const errorCode = getErrorCode(error);
      const errorStatus = error?.data?.error?.status || null;

      return (
        <div>
          <span
            className="inline-flex items-center text-red-600 text-xs cursor-pointer"
            onClick={() => setShowDetails(!showDetails)}
          >
            <FaExclamationTriangle className="mr-1" />
            Falha na conexão
            {errorCode && (
              <span className="ml-1 bg-red-100 text-red-800 px-1.5 py-0.5 rounded-full text-xs">
                {errorCode}
              </span>
            )}
            <span className="ml-1 text-xs underline">
              {showDetails ? "Ocultar detalhes" : "Ver detalhes"}
            </span>
          </span>

          <Transition
            show={showDetails}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="mt-2 p-3 bg-red-50 border border-red-100 rounded-md text-xs text-red-700">
              <p className="font-medium flex items-center">
                <FaExclamationCircle className="mr-1" />
                Erro:
                {errorStatus && (
                  <span className="ml-1 bg-red-100 px-1.5 py-0.5 rounded text-xs">
                    Status: {errorStatus}
                  </span>
                )}
              </p>
              <p className="mt-1">{mainMessage}</p>

              {/* Exibir detalhes específicos do Google Ads */}
              {error?.data?.error?.details?.[0]?.errors?.[0] && (
                <div className="mt-2 border-t border-red-200 pt-2">
                  <p className="font-medium flex items-center">
                    <FaInfoCircle className="mr-1" />
                    Detalhes do Google Ads:
                  </p>

                  {error.data.error.details.map((detail, idx) => (
                    <div
                      key={idx}
                      className="mt-1 pl-2 border-l-2 border-red-200"
                    >
                      {detail.errors &&
                        detail.errors.map((err, errIdx) => (
                          <div key={errIdx} className="mb-1">
                            {err.errorCode &&
                              Object.keys(err.errorCode).map((key) => (
                                <div key={key} className="flex flex-col">
                                  <span className="text-xs font-medium">
                                    {key}:{" "}
                                    <span className="font-bold">
                                      {err.errorCode[key]}
                                    </span>
                                  </span>
                                  {err.message && (
                                    <span className="text-xs">
                                      {err.message}
                                    </span>
                                  )}
                                </div>
                              ))}
                          </div>
                        ))}
                      {detail.requestId && (
                        <div className="text-xs text-red-500 mt-1">
                          Request ID: {detail.requestId}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {/* Botão para copiar detalhes completos para debug */}
              <button
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(error, null, 2));
                  toast.success(
                    "Detalhes do erro copiados para a área de transferência"
                  );
                }}
                className="mt-2 text-xs bg-red-100 hover:bg-red-200 text-red-800 px-2 py-1 rounded transition-colors"
              >
                Copiar detalhes completos
              </button>
            </div>
          </Transition>
        </div>
      );
    }
  };

  return (
    <div className="mt-2">
      <button
        onClick={testConnection}
        className="text-blue-500 hover:text-blue-700 text-xs underline focus:outline-none"
        disabled={loading}
      >
        Testar conexão
      </button>
      <div className="mt-1">{renderStatus()}</div>
    </div>
  );
};

export default VerifyConnection;
