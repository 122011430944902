// src/components/ProjectConfigurations.js
import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import { Switch } from "@headlessui/react";
import { NumericFormat } from "react-number-format";
import { addOrUpdateProjectConfig, listProjectConfig } from "../utils/api";
import FormField from "./FormField";

// Função para formatar valores de porcentagem para exibição (com símbolo %)
const formatPercentageForDisplay = (value) => {
  if (!value && value !== 0) return "";
  return `${value}%`;
};

// Função para formatar valores monetários para exibição (com R$)
const formatCurrencyForDisplay = (value) => {
  if (!value && value !== 0) return "";
  return `R$ ${parseFloat(value).toFixed(2)}`;
};

// Função para remover formatação e obter apenas o valor numérico
const getNumericValue = (formattedValue) => {
  if (!formattedValue) return "";
  // Remove qualquer caractere que não seja número, ponto ou vírgula
  return formattedValue.replace(/[^0-9.,]/g, "").replace(",", ".");
};

const formatPercentageValue = (value, isExponential = false) => {
  if (!value && value !== 0) return "";

  // Remove qualquer caractere que não seja número
  const cleanValue = getNumericValue(value).replace(/[^\d]/g, "");
  const numValue = parseInt(cleanValue, 10);

  if (isNaN(numValue)) return "";
  if (numValue > 100) return "100";
  return numValue.toString();
};

const formatCurrencyValue = (value) => {
  if (!value) return "";
  // Remove qualquer caractere que não seja número ou ponto
  const cleanValue = getNumericValue(value).replace(/[^\d.]/g, "");
  // Substitui vírgula por ponto
  const normalizedValue = cleanValue.replace(",", ".");
  // Limita a 2 casas decimais
  const numValue = parseFloat(normalizedValue);
  if (isNaN(numValue)) return "";
  // Garante o valor mínimo de 0.01
  if (numValue < 0.01) return "0.01";
  return numValue.toFixed(2);
};

const ProjectConfigurations = ({ projectId }) => {
  const [configurations, setConfigurations] = useState({
    RevShare: "",
    Tax: "",
    DollarDepreciation: "0.0",
    exponentialAdjustment: "",
    cpcAdjustmentValue: "0.01",
    minSpendValue: "0.5",
    timezone: "",
  });

  const [roiTargets, setRoiTargets] = useState({
    roiGoal: "",
    roiAbove: "",
    roiBelow: "",
  });

  // Estado para preview do ROI
  const [roiPreview, setRoiPreview] = useState({
    goal: "",
    min: "",
    max: "",
  });

  const [adjustmentType, setAdjustmentType] = useState("fixed");
  const [timezones, setTimezones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfigVisible] = useState(true);

  // Função para atualizar o preview do ROI
  const updateRoiPreview = () => {
    const goalValue = parseFloat(roiTargets.roiGoal);
    const aboveValue = parseFloat(roiTargets.roiAbove);
    const belowValue = parseFloat(roiTargets.roiBelow);

    if (!isNaN(goalValue)) {
      setRoiPreview({
        goal: `${goalValue}%`,
        // Calcula a meta acima como: meta + (meta * porcentagem/100)
        max: !isNaN(aboveValue)
          ? `${(goalValue + (goalValue * aboveValue) / 100).toFixed(2)}%`
          : "",
        // Calcula a meta abaixo como: meta - (meta * porcentagem/100)
        min: !isNaN(belowValue)
          ? `${Math.max(0, goalValue - (goalValue * belowValue) / 100).toFixed(
              2
            )}%`
          : "",
      });
    } else {
      setRoiPreview({
        goal: "",
        max: "",
        min: "",
      });
    }
  };

  // Atualiza o preview do ROI quando os valores mudam
  useEffect(() => {
    updateRoiPreview();
  }, [roiTargets]);

  // Carrega as configurações do projeto
  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const configs = await listProjectConfig(projectId);

        // Atualiza o estado de configurations, garantindo que todos os valores sejam strings
        setConfigurations({
          RevShare:
            configs.RevShare !== null && configs.RevShare !== undefined
              ? String(configs.RevShare)
              : "",
          Tax:
            configs.Tax !== null && configs.Tax !== undefined
              ? String(configs.Tax)
              : "",
          DollarDepreciation:
            configs.DollarDepreciation !== null &&
            configs.DollarDepreciation !== undefined
              ? String(configs.DollarDepreciation)
              : "0.0",
          exponentialAdjustment:
            configs.exponentialAdjustment !== null &&
            configs.exponentialAdjustment !== undefined
              ? String(configs.exponentialAdjustment)
              : "",
          cpcAdjustmentValue:
            configs.cpcAdjustmentValue !== null &&
            configs.cpcAdjustmentValue !== undefined
              ? String(configs.cpcAdjustmentValue)
              : "0.01",
          minSpendValue:
            configs.minSpendValue !== null &&
            configs.minSpendValue !== undefined
              ? String(configs.minSpendValue)
              : "0.5",
          timezone: configs.timezone || "",
        });

        // Atualiza o estado de roiTargets, garantindo que todos os valores sejam strings
        setRoiTargets({
          roiGoal:
            configs.roiGoal !== null && configs.roiGoal !== undefined
              ? String(configs.roiGoal)
              : "",
          roiAbove:
            configs.roiAbove !== null && configs.roiAbove !== undefined
              ? String(configs.roiAbove)
              : "",
          roiBelow:
            configs.roiBelow !== null && configs.roiBelow !== undefined
              ? String(configs.roiBelow)
              : "",
        });

        // Atualiza o tipo de ajuste
        setAdjustmentType(
          configs.exponentialAdjustment !== null &&
            configs.exponentialAdjustment !== undefined
            ? "percentual"
            : "fixed"
        );
      } catch (error) {
        console.error("Erro ao carregar configurações:", error);
        toast.error("Erro ao carregar configurações do projeto");
      }
    };

    fetchConfigurations();
  }, [projectId]);

  // Carrega os timezones
  useEffect(() => {
    const fetchTimezones = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://raw.githubusercontent.com/dmfilipenko/timezones.json/master/timezones.json"
        );
        const data = await response.json();

        // Processamos os dados para evitar duplicação e garantir valores únicos
        // Usamos o texto (label) como chave do Map para evitar duplicação
        const uniqueTimezonesMap = new Map();

        // Primeiro, vamos garantir que o timezone atual seja considerado
        const currentTimezone = configurations.timezone;

        // Adiciona todos os timezones disponíveis na API
        data.forEach((tz, index) => {
          // Cada timezone pode ter vários valores UTC
          if (tz.utc && tz.utc.length > 0) {
            // Usamos o primeiro valor UTC para representar este timezone
            const mainUtcValue = tz.utc[0];

            // Usamos o índice como parte da chave para garantir unicidade
            const key = `${tz.text}_${index}`;

            // Adicionamos o timezone principal ao mapa
            uniqueTimezonesMap.set(key, {
              value: mainUtcValue,
              label: tz.text,
              offset: tz.offset,
              allValues: tz.utc, // Guardamos todos os valores para comparação posterior
            });
          }
        });

        // Se temos um timezone atual configurado, vamos garantir que ele esteja na lista
        if (currentTimezone) {
          // Procuramos se o timezone atual está em algum dos conjuntos de valores UTC
          let found = false;

          // Convertemos o Map para array para iteração
          const timezoneEntries = Array.from(uniqueTimezonesMap.entries());

          for (const [key, entry] of timezoneEntries) {
            if (entry.allValues.includes(currentTimezone)) {
              // Se encontrarmos, marcamos como encontrado
              found = true;
              // E atualizamos o valor para ser o timezone atual
              uniqueTimezonesMap.set(key, {
                ...entry,
                value: currentTimezone,
              });
              break;
            }
          }

          // Se não encontrarmos o timezone atual em nenhum conjunto, adicionamos como uma entrada separada
          if (!found) {
            uniqueTimezonesMap.set(`custom_${currentTimezone}`, {
              value: currentTimezone,
              label: `(UTC) ${currentTimezone}`,
              offset: 0,
              allValues: [currentTimezone],
            });
          }
        }

        // Convertemos o mapa para array e ordenamos por offset
        const formattedTimezones = Array.from(uniqueTimezonesMap.values())
          .sort((a, b) => a.offset - b.offset)
          // Removemos a propriedade allValues que usamos apenas para processamento
          .map(({ allValues, ...rest }) => rest);

        setTimezones(formattedTimezones);
      } catch (error) {
        console.error("Erro ao carregar timezones:", error);
        toast.error("Erro ao carregar lista de fusos horários");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTimezones();
  }, [configurations.timezone]);

  const handleAdjustmentTypeChange = async (type) => {
    setAdjustmentType(type);

    if (type === "fixed") {
      // Configurando para ajuste fixo
      const fixedValue = "0.01";
      setConfigurations((prev) => ({
        ...prev,
        exponentialAdjustment: "",
        cpcAdjustmentValue: parseFloat(fixedValue),
      }));

      await Promise.all([
        addOrUpdateProjectConfig(projectId, "exponentialAdjustment", null),
        addOrUpdateProjectConfig(
          projectId,
          "cpcAdjustmentValue",
          parseFloat(fixedValue)
        ),
      ]);

      toast.success("Ajuste fixo ativado");
    } else {
      // Configurando para ajuste percentual
      const percentValue = "10";
      setConfigurations((prev) => ({
        ...prev,
        exponentialAdjustment: percentValue,
        cpcAdjustmentValue: "",
      }));

      await Promise.all([
        addOrUpdateProjectConfig(
          projectId,
          "exponentialAdjustment",
          parseInt(percentValue)
        ),
        addOrUpdateProjectConfig(projectId, "cpcAdjustmentValue", null),
      ]);

      toast.success("Ajuste percentual ativado");
    }
  };

  // Handler unificado para mudanças em campos
  const handleFieldChange = (name, value) => {
    // Garante que o valor seja uma string
    const stringValue =
      value !== null && value !== undefined ? String(value) : "";

    // Verifica se o campo pertence a roiTargets
    if (["roiGoal", "roiAbove", "roiBelow"].includes(name)) {
      setRoiTargets((prev) => ({
        ...prev,
        [name]: stringValue,
      }));
    } else {
      // Caso contrário, pertence a configurations
      setConfigurations((prev) => ({
        ...prev,
        [name]: stringValue,
      }));
    }
  };

  // Handler para perda de foco em campos
  const handleFieldBlur = async (name, value) => {
    // Garante que o valor seja uma string
    const stringValue =
      value !== null && value !== undefined ? String(value) : "";

    // Não salva se o valor for undefined, null ou vazio para campos que podem ficar vazios
    if (stringValue === "") {
      if (
        ["RevShare", "Tax", "roiGoal", "roiAbove", "roiBelow"].includes(name)
      ) {
        return;
      }
    }

    // Para campos monetários, garantimos um valor mínimo
    let finalValue = stringValue;
    if (
      ["DollarDepreciation", "cpcAdjustmentValue", "minSpendValue"].includes(
        name
      )
    ) {
      finalValue = Math.max(0.01, parseFloat(stringValue) || 0.01);
    }

    toast.loading("Salvando configurações...");

    try {
      // Enviar o valor como número para a API
      const valueToSend =
        name === "timezone" ? finalValue : parseFloat(finalValue) || 0;
      await addOrUpdateProjectConfig(projectId, name, valueToSend);
      toast.dismiss();
      toast.success(`${name} atualizado com sucesso!`);
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const handleSaveConfigurations = async () => {
    toast.loading("Salvando configurações...");

    try {
      // Salva todas as configurações uma por vez
      for (const [key, value] of Object.entries(configurations)) {
        if (key === "timezone") {
          await addOrUpdateProjectConfig(projectId, key, value);
          continue;
        }

        // Garante que valores numéricos sejam enviados como números para a API
        let finalValue = value;

        if (key === "exponentialAdjustment") {
          finalValue = value && value !== "" ? parseInt(value) : null;
        } else if (["RevShare", "Tax"].includes(key)) {
          finalValue = parseFloat(value) || 0;
        } else if (
          [
            "DollarDepreciation",
            "cpcAdjustmentValue",
            "minSpendValue",
          ].includes(key)
        ) {
          finalValue = Math.max(0.01, parseFloat(value) || 0.01);
        }

        await addOrUpdateProjectConfig(projectId, key, finalValue);
      }

      // Salva todas as configurações de ROI uma por vez
      for (const [key, value] of Object.entries(roiTargets)) {
        const numericValue = parseFloat(value) || 0;
        await addOrUpdateProjectConfig(projectId, key, numericValue);
      }

      toast.dismiss();
      toast.success("Todas as configurações foram salvas com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao salvar configurações: " + error.message);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-6">
      {/* Configurações Básicas */}
      <FormField
        label="RevShare (Porcentagem de Receita Compartilhada):"
        name="RevShare"
        type="percentage"
        value={configurations.RevShare}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
      />

      <FormField
        label="Tax (Taxa em Porcentagem):"
        name="Tax"
        type="percentage"
        value={configurations.Tax}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
      />

      <FormField
        label="Dollar Depreciation (Depreciação do Dólar em Reais):"
        name="DollarDepreciation"
        type="currency"
        value={configurations.DollarDepreciation}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
      />

      {/* Fuso Horário */}
      <div className="flex flex-col">
        <label className="text-gray-600 mb-1 text-sm">Fuso Horário:</label>
        {isLoading ? (
          <div className="h-10 flex items-center">
            <span className="text-sm text-gray-500">
              Carregando fusos horários...
            </span>
          </div>
        ) : (
          <FormField
            name="timezone"
            type="select"
            value={configurations.timezone}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
            placeholder="Selecione um fuso horário"
            options={timezones}
          />
        )}
      </div>

      {/* Seção de Ajustes de CPC */}
      <div className="space-y-6 border-t pt-6">
        <h3 className="text-lg font-semibold text-gray-900">Ajustes de CPC</h3>

        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-blue-600"
                name="adjustmentType"
                value="fixed"
                checked={adjustmentType === "fixed"}
                onChange={() => handleAdjustmentTypeChange("fixed")}
              />
              <span className="ml-2">Ajuste Fixo</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-blue-600"
                name="adjustmentType"
                value="percentual"
                checked={adjustmentType === "percentual"}
                onChange={() => handleAdjustmentTypeChange("percentual")}
              />
              <span className="ml-2">Ajuste Percentual</span>
            </label>
          </div>

          {adjustmentType === "fixed" && (
            <FormField
              label="Valor do Ajuste Fixo (R$):"
              name="cpcAdjustmentValue"
              type="currency"
              value={configurations.cpcAdjustmentValue}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
              placeholder="R$ 0,01"
            />
          )}

          {adjustmentType === "percentual" && (
            <FormField
              label="Valor do Ajuste Percentual (%):"
              name="exponentialAdjustment"
              type="percentage"
              value={configurations.exponentialAdjustment}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
              decimalScale={0}
              placeholder="10%"
            />
          )}
        </div>

        {/* Valor Mínimo de Gasto */}
        <FormField
          label="Valor Mínimo de Gasto (R$):"
          name="minSpendValue"
          type="currency"
          value={configurations.minSpendValue}
          onChange={handleFieldChange}
          onBlur={handleFieldBlur}
          placeholder="R$ 0,50"
        />
      </div>

      {/* ROI Configuration */}
      <div className="mt-8">
        <h2 className="text-lg font-semibold mb-4">
          <FaCheckCircle className="mr-1 inline" />
          Meta de ROI (Retorno sobre Investimento)
        </h2>

        {/* Preview do ROI */}
        {roiPreview.goal && (
          <div className="bg-gray-50 p-4 rounded-lg mb-4 border border-gray-200">
            <h3 className="text-md font-medium mb-2">Preview das Metas:</h3>
            <div className="grid grid-cols-3 gap-4">
              <div className="text-center">
                <p className="text-sm text-gray-500">Abaixo da Meta</p>
                <p className="text-red-600 font-semibold">{roiPreview.min}</p>
              </div>
              <div className="text-center">
                <p className="text-sm text-gray-500">Meta</p>
                <p className="text-blue-600 font-semibold">{roiPreview.goal}</p>
              </div>
              <div className="text-center">
                <p className="text-sm text-gray-500">Acima da Meta</p>
                <p className="text-green-600 font-semibold">{roiPreview.max}</p>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 gap-6">
          <FormField
            label="Meta de ROI (%):"
            name="roiGoal"
            type="percentage"
            value={roiTargets.roiGoal}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
            placeholder="0%"
          />

          <FormField
            label="Quanto (%) acima da meta consideraremos ROI:"
            name="roiAbove"
            type="percentage"
            value={roiTargets.roiAbove}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
            placeholder="0%"
          />

          <FormField
            label="Quanto (%) abaixo da meta consideraremos ROI:"
            name="roiBelow"
            type="percentage"
            value={roiTargets.roiBelow}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
            placeholder="0%"
          />
        </div>
      </div>

      {/* Botão de Salvar */}
      <div className="border-t pt-6">
        <button
          onClick={handleSaveConfigurations}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
        >
          <FaCheckCircle />
          Salvar Todas as Configurações
        </button>
      </div>
    </div>
  );
};

export default ProjectConfigurations;
