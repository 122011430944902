import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTrash,
  FaSpinner,
  FaChartLine,
  FaCog,
  FaExclamationTriangle,
  FaClock,
} from "react-icons/fa";

const ProjectCard = ({ project, onDelete, isLoading, userId }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getProjectRole = () => {
    if (project.accountId === userId) {
      return { text: "Proprietário", className: "bg-blue-100 text-blue-800" };
    } else if (
      project.trafficManagersIds &&
      Array.isArray(project.trafficManagersIds) &&
      project.trafficManagersIds.includes(userId)
    ) {
      return { text: "Gestor", className: "bg-green-100 text-green-800" };
    }
    return { text: "Visualizador", className: "bg-gray-100 text-gray-800" };
  };

  const getProjectStatus = () => {
    if (!project.lastErrorMessages || project.lastErrorMessages.length === 0) {
      return { text: "Ativo", className: "text-green-600", icon: null };
    }

    const lastError = project.lastErrorMessages[0];
    const errorData = JSON.parse(lastError);
    const errorDate = new Date(errorData.timestamp);
    const now = new Date();
    const diffMinutes = (now - errorDate) / (1000 * 60);

    if (diffMinutes <= 40) {
      return {
        text: "Ativo",
        className: "text-green-600",
        icon: null,
      };
    }

    return { text: "Ativo", className: "text-green-600", icon: null };
  };

  const getRoiStatus = () => {
    if (!project.roiMetrics) return null;

    const { roiPercent, nPositiveCampaigns, nNegativeCampaigns } =
      project.roiMetrics;

    return {
      roiClass:
        roiPercent > 0
          ? "text-green-600"
          : roiPercent < 0
          ? "text-red-600"
          : "text-gray-900",
      positiveCampaignsClass:
        nPositiveCampaigns > 0 ? "text-green-600" : "text-gray-900",
      negativeCampaignsClass:
        nNegativeCampaigns > 0 ? "text-red-600" : "text-gray-900",
    };
  };

  const role = getProjectRole();
  const status = getProjectStatus();
  const roiStatus = getRoiStatus();

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow w-full">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between w-full gap-4">
        <div className="flex flex-col md:flex-row md:items-center md:space-x-6 flex-1 gap-4">
          <div className="flex-shrink-0">
            <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
              <span className="text-blue-600 font-medium text-[14px]">
                {project.id}
              </span>
            </div>
          </div>

          <div className="flex-1 min-w-0">
            <div className="flex flex-wrap items-center gap-3">
              <h3 className="text-lg font-medium text-gray-900">
                {project.projectName}
              </h3>
              <span className="text-[10px] font-medium text-gray-500 bg-gray-100 px-1.5 py-0.5 rounded">
                {project.projectAbbreviation}
              </span>
              <span
                className={`px-2 py-1 rounded-full text-xs font-medium ${role.className}`}
              >
                {role.text}
              </span>
            </div>
            <div className="flex flex-wrap items-center gap-4 mt-1">
              <span className="text-sm text-gray-500">
                {project.adGroups?.length || 0} Grupos de Anúncios
              </span>
              <span className={`text-sm ${status.className}`}>
                {status.icon}
                {status.text}
              </span>
            </div>
          </div>

          {project.roiMetrics && (
            <div className="flex flex-col md:flex-row md:items-center md:space-x-8 gap-4 md:gap-0">
              <div className="text-left md:text-right">
                <div className={`text-sm font-medium ${roiStatus.roiClass}`}>
                  ROI: {project.roiMetrics.roiPercent.toFixed(2)}%
                </div>
                <div className="text-xs text-gray-500">
                  <span className="font-bold">
                    Lucro: R$ {project.roiMetrics.totalNet.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="text-left md:text-right">
                <div className="text-sm">
                  <span className={roiStatus.positiveCampaignsClass}>
                    {project.roiMetrics.nPositiveCampaigns} Positivas
                  </span>
                  {" / "}
                  <span className={roiStatus.negativeCampaignsClass}>
                    {project.roiMetrics.nNegativeCampaigns} Negativas
                  </span>
                </div>
                <div className="text-xs text-gray-500">
                  <span className="font-bold">
                    Investido: R$ {project.roiMetrics.totalSpent.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="text-left md:text-right">
                <div className="text-xs text-gray-400 flex items-center gap-1">
                  <FaClock size={12} />
                  {formatDate(project.roiMetrics.lastUpdated)}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center space-x-2 md:ml-4">
          <button
            onClick={() => navigate(`/project/${project.id}/performance`)}
            className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
            title="Ver Performance"
          >
            <FaChartLine size={18} />
          </button>
          <button
            onClick={() => navigate(`/project/${project.id}`)}
            className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
            title="Configurações"
          >
            <FaCog size={18} />
          </button>
          {project.accountId === userId && (
            <button
              onClick={() => onDelete(project.id)}
              disabled={isLoading}
              className="p-2 text-gray-600 hover:text-red-600 transition-colors"
              title="Excluir Projeto"
            >
              {isLoading ? (
                <FaSpinner className="animate-spin" size={18} />
              ) : (
                <FaTrash size={18} />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
