import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const ProjectsSummary = ({ projects, activeTab, userId }) => {
  const [showDomainRoi, setShowDomainRoi] = useState(false);

  // Filtra projetos baseado na aba ativa
  const filteredProjects = projects.filter((project) => {
    if (activeTab === "owned") {
      return project.accountId === userId;
    } else {
      return (
        project.accountId !== userId &&
        project.trafficManagersIds &&
        Array.isArray(project.trafficManagersIds) &&
        project.trafficManagersIds.includes(userId)
      );
    }
  });

  // Calcula totais
  const totals = filteredProjects.reduce(
    (acc, project) => {
      if (!project.roiMetrics) return acc;

      return {
        totalNet: acc.totalNet + project.roiMetrics.totalNet,
        totalSpent: acc.totalSpent + project.roiMetrics.totalSpent,
        positiveCampaigns:
          acc.positiveCampaigns + project.roiMetrics.nPositiveCampaigns,
        negativeCampaigns:
          acc.negativeCampaigns + project.roiMetrics.nNegativeCampaigns,
      };
    },
    {
      totalNet: 0,
      totalSpent: 0,
      positiveCampaigns: 0,
      negativeCampaigns: 0,
    }
  );

  // Calcula ROI total
  const totalRoi =
    totals.totalSpent > 0 ? (totals.totalNet / totals.totalSpent) * 100 : 0;

  // Calcula ROI por domínio
  const roiByDomain = filteredProjects.reduce((acc, project) => {
    if (!project.roiMetrics) return acc;

    const domain = project.domain;
    if (!acc[domain]) {
      acc[domain] = {
        totalNet: 0,
        totalSpent: 0,
        count: 0,
      };
    }

    acc[domain].totalNet += project.roiMetrics.totalNet;
    acc[domain].totalSpent += project.roiMetrics.totalSpent;
    acc[domain].count += 1;

    return acc;
  }, {});

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="text-center">
          <div className="text-sm text-gray-500 mb-1">ROI Total</div>
          <div
            className={`text-lg font-medium ${
              totalRoi > 0
                ? "text-green-600"
                : totalRoi < 0
                ? "text-red-600"
                : "text-gray-900"
            }`}
          >
            {totalRoi.toFixed(2)}%
          </div>
        </div>
        <div className="text-center">
          <div className="text-sm text-gray-500 mb-1">Lucro Total</div>
          <div className="text-lg font-medium">
            R$ {totals.totalNet.toFixed(2)}
          </div>
        </div>
        <div className="text-center">
          <div className="text-sm text-gray-500 mb-1">Investido Total</div>
          <div className="text-lg font-medium">
            R$ {totals.totalSpent.toFixed(2)}
          </div>
        </div>
        <div className="text-center">
          <div className="text-sm text-gray-500 mb-1">Campanhas</div>
          <div className="text-lg">
            <span className="text-green-600">{totals.positiveCampaigns}</span>
            {" / "}
            <span className="text-red-600">{totals.negativeCampaigns}</span>
          </div>
        </div>
      </div>

      <div className="mt-4 pt-4 border-t">
        <button
          onClick={() => setShowDomainRoi(!showDomainRoi)}
          className="flex items-center gap-2 text-sm text-gray-500 hover:text-gray-700 transition-colors"
        >
          {showDomainRoi ? (
            <FaChevronUp size={12} />
          ) : (
            <FaChevronDown size={12} />
          )}
          ROI por Domínio
        </button>

        {showDomainRoi && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(roiByDomain).map(([domain, data]) => {
              const roi =
                data.totalSpent > 0
                  ? (data.totalNet / data.totalSpent) * 100
                  : 0;

              return (
                <div key={domain} className="bg-gray-50 rounded-lg p-3">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-medium text-gray-900 truncate">
                      {domain}
                    </div>
                    <div
                      className={`text-sm font-medium ${
                        roi > 0
                          ? "text-green-600"
                          : roi < 0
                          ? "text-red-600"
                          : "text-gray-900"
                      }`}
                    >
                      {roi.toFixed(2)}%
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 mt-1">
                    {data.count} projeto{data.count !== 1 ? "s" : ""}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsSummary;
