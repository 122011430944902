import React, { useState, useEffect } from "react";
import {
  FaExclamationCircle,
  FaAngleDown,
  FaAngleUp,
  FaTimes,
  FaFilter,
  FaSearch,
} from "react-icons/fa";

/**
 * Componente para exibir notificações de erro recente e uma lista completa de erros
 * @param {Object} props
 * @param {Array} props.errorMessages - Array de mensagens de erro em formato string JSON
 */
const ErrorNotifications = ({ errorMessages = [] }) => {
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [latestError, setLatestError] = useState(null);
  const [dismissed, setDismissed] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!errorMessages || errorMessages.length === 0) {
      setLatestError(null);
      return;
    }

    try {
      // Processa as mensagens de erro para encontrar a mais recente
      const processedErrors = errorMessages.map((errStr) => {
        try {
          const parsedError = JSON.parse(errStr);
          return {
            message: parsedError.message,
            date: new Date(parsedError.date),
            type: parsedError.type,
            formattedDate: new Date(parsedError.date).toLocaleString("pt-BR"),
            raw: errStr,
          };
        } catch (e) {
          // Se não for possível fazer parse do JSON, retorna objeto com dados básicos
          return {
            message: errStr,
            date: new Date(),
            type: "unknown",
            formattedDate: "Data desconhecida",
            raw: errStr,
          };
        }
      });

      // Ordena por data, do mais recente para o mais antigo
      const sortedErrors = processedErrors.sort((a, b) => b.date - a.date);

      // Verifica se o erro mais recente ocorreu nos últimos 30 minutos
      const mostRecentError = sortedErrors[0];
      const thirtyMinutesAgo = new Date(Date.now() - 30 * 60 * 1000);

      if (mostRecentError && mostRecentError.date > thirtyMinutesAgo) {
        setLatestError(mostRecentError);
      } else {
        setLatestError(null);
      }
    } catch (error) {
      console.error("Erro ao processar mensagens de erro:", error);
      setLatestError(null);
    }
  }, [errorMessages]);

  // Se não houver erros ou o componente foi dispensado, não exibe nada
  if (!errorMessages.length || dismissed) return null;

  // Processa todos os erros para exibição
  const processedErrors = errorMessages
    .map((errStr) => {
      try {
        const parsedError = JSON.parse(errStr);
        return {
          message: parsedError.message,
          date: new Date(parsedError.date),
          type: parsedError.type,
          formattedDate: new Date(parsedError.date).toLocaleString("pt-BR"),
          raw: errStr,
        };
      } catch (e) {
        return {
          message: errStr,
          date: new Date(),
          type: "unknown",
          formattedDate: "Data desconhecida",
          raw: errStr,
        };
      }
    })
    .sort((a, b) => b.date - a.date);

  // Filtrar erros pelo termo de pesquisa
  const filteredErrors = processedErrors.filter(
    (error) =>
      error.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
      error.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Aplicar filtro por tipo
  const filteredAndTypedErrors =
    activeFilter === "all"
      ? filteredErrors
      : filteredErrors.filter((error) => error.type === activeFilter);

  // Agrupa erros por tipo para uma melhor visualização
  const errorsByType = filteredAndTypedErrors.reduce((acc, error) => {
    if (!acc[error.type]) {
      acc[error.type] = [];
    }
    acc[error.type].push(error);
    return acc;
  }, {});

  // Obter tipos únicos de erro para o filtro
  const uniqueErrorTypes = [
    ...new Set(processedErrors.map((error) => error.type)),
  ];

  // Formata o tipo de erro para exibição
  const formatErrorType = (type) => {
    switch (type) {
      case "gam_network":
        return "Google Ad Manager";
      case "google_ads":
        return "Google Ads";
      case "gtm":
        return "Google Tag Manager";
      default:
        return type.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
    }
  };

  return (
    <div className="mb-8 transform transition-all duration-300 ease-in-out opacity-100 translate-y-0">
      {latestError && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4 relative">
          <button
            className="absolute top-2 right-2 text-red-400 hover:text-red-600"
            onClick={() => setDismissed(true)}
            aria-label="Dispensar notificação"
          >
            <FaTimes />
          </button>

          <div className="flex items-start">
            <FaExclamationCircle
              className="text-red-500 mr-3 mt-1 flex-shrink-0"
              size={20}
            />
            <div>
              <h3 className="text-red-800 font-medium text-base mb-1">
                Erro recente detectado
              </h3>
              <p className="text-red-700 text-sm mb-2">{latestError.message}</p>
              <p className="text-red-600 text-xs">
                <span className="font-medium">Sistema:</span>{" "}
                {formatErrorType(latestError.type)} ·
                <span className="font-medium ml-1">Ocorrido em:</span>{" "}
                {latestError.formattedDate}
              </p>

              <div className="mt-3 text-sm">
                <p className="text-red-600 italic">
                  Se você já corrigiu o problema, este alerta pode levar algum
                  tempo para desaparecer.
                </p>
              </div>
            </div>
          </div>

          {errorMessages.length > 1 && (
            <div className="mt-3 border-t border-red-200 pt-3">
              <button
                onClick={() => setShowAllErrors(!showAllErrors)}
                className="text-red-600 hover:text-red-700 text-sm flex items-center"
              >
                {showAllErrors ? (
                  <>
                    <FaAngleUp className="mr-1" />
                    Ocultar histórico de erros
                  </>
                ) : (
                  <>
                    <FaAngleDown className="mr-1" />
                    Ver histórico de erros ({errorMessages.length})
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      )}

      {showAllErrors && (
        <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 max-h-[550px] overflow-y-auto transition-all duration-300 ease-in-out transform translate-y-0 opacity-100">
          <h3 className="text-gray-800 font-medium text-base mb-3 flex justify-between items-center">
            <span>Histórico de Erros</span>
            <span className="text-sm text-gray-500">
              {filteredAndTypedErrors.length} erros encontrados
            </span>
          </h3>

          {/* Filtros e Pesquisa */}
          <div className="flex flex-col md:flex-row gap-2 mb-4">
            {/* Barra de pesquisa */}
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Pesquisar erros..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-3 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Filtro por tipo */}
            <div className="flex items-center">
              <FaFilter className="text-gray-500 mr-2" />
              <select
                value={activeFilter}
                onChange={(e) => setActiveFilter(e.target.value)}
                className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="all">Todos os tipos</option>
                {uniqueErrorTypes.map((type) => (
                  <option key={type} value={type}>
                    {formatErrorType(type)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {Object.keys(errorsByType).length === 0 ? (
            <div className="text-center py-6 text-gray-500">
              Nenhum erro encontrado com os filtros atuais.
            </div>
          ) : (
            Object.entries(errorsByType).map(([type, errors]) => (
              <div key={type} className="mb-4">
                <h4 className="text-gray-700 font-medium text-sm mb-2">
                  {formatErrorType(type)} ({errors.length})
                </h4>
                <ul className="space-y-2">
                  {errors.map((error, index) => (
                    <li
                      key={index}
                      className="bg-white border border-gray-200 rounded-md p-3 text-sm"
                    >
                      <p className="text-gray-800 mb-1">{error.message}</p>
                      <p className="text-gray-500 text-xs">
                        {error.formattedDate}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ErrorNotifications;
