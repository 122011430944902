import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  listAllProjects,
  createProject,
  deleteProject,
  getUserInfo,
  getAdGroupsUsage,
} from "../utils/api";
import { jwtDecode } from "jwt-decode";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ProjectsList from "../components/ProjectsList";
import PlanDashboard from "../components/PlanDashboard";
import CreateProjectModal from "../components/CreateProjectModal";
import Spinner from "../components/Spinner";

const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectAbbreviation, setProjectAbbreviation] = useState("");
  const [projectType, setProjectType] = useState("googleads/admanager");
  const [userId, setUserId] = useState(null);
  const [canCreateProject, setCanCreateProject] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [nameError, setNameError] = useState("");
  const [abbreviationError, setAbbreviationError] = useState("");
  const [userPlan, setUserPlan] = useState(null);
  const [adGroupsUsage, setAdGroupsUsage] = useState(null);

  useEffect(() => {
    const checkPermissions = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.scopes && decodedToken.scopes.includes("projects")) {
          setHasPermission(true);
          setUserId(decodedToken.id);
          setCanCreateProject(decodedToken.scopes.includes("gads-integration"));

          // Busca informações do usuário
          try {
            const userInfo = await getUserInfo();
            if (userInfo.success) {
              localStorage.setItem("userPlan", JSON.stringify(userInfo.data));
              setUserPlan(userInfo.data);
            }

            const usage = await getAdGroupsUsage(decodedToken.id);
            setAdGroupsUsage(usage);
          } catch (error) {
            console.error("Erro ao buscar informações do usuário:", error);
          }

          fetchProjects();
        } else {
          navigate("/denied");
        }
      } else {
        navigate("/login");
      }
    };

    checkPermissions();
  }, [navigate]);

  const fetchProjects = async () => {
    setLoadingProjects(true);
    try {
      const projectsData = await listAllProjects();
      if (!projectsData || !Array.isArray(projectsData)) {
        throw new Error("Dados de projetos inválidos");
      }

      setProjects(projectsData);
    } catch (error) {
      toast.error("Erro ao listar os projetos.");
      console.error("Erro ao listar projetos:", error);
    } finally {
      setLoadingProjects(false);
    }
  };

  const handleCreateProject = async () => {
    if (nameError || abbreviationError) {
      toast.error("Por favor, corrija os erros antes de prosseguir.");
      return;
    }

    setLoadingCreate(true);

    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const accountId = decodedToken.id;

      const newProjectData = {
        projectName,
        projectAbbreviation,
        projectType,
        accountId: accountId,
      };

      const response = await createProject(newProjectData);

      if (response && response.id) {
        setIsModalOpen(false);
        setProjectName("");
        setProjectAbbreviation("");
        setNameError("");
        setAbbreviationError("");
        fetchProjects();
        toast.success("Projeto criado com sucesso!");
      } else {
        throw new Error(response.error || "Erro ao criar o projeto");
      }
    } catch (error) {
      toast.error(`Erro ao criar o projeto: ${error.message}`);
      console.error("Erro ao criar projeto:", error);
    } finally {
      setLoadingCreate(false);
    }
  };

  const validateProjectName = (name) => {
    const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
    setProjectName(formattedName);

    const wordCount = formattedName.trim().split(/\s+/).length;
    if (formattedName.length > 20 || wordCount > 4) {
      setNameError("O nome deve ter no máximo 20 caracteres ou 4 palavras.");
    } else {
      setNameError("");
    }
  };

  const validateProjectAbbreviation = (abbreviation) => {
    const formattedAbbreviation = abbreviation
      .replace(/[^A-Za-z0-9]/g, "")
      .toUpperCase();
    setProjectAbbreviation(formattedAbbreviation);

    if (formattedAbbreviation.length < 3 || formattedAbbreviation.length > 7) {
      setAbbreviationError("A sigla deve ter entre 3 e 7 caracteres.");
    } else {
      setAbbreviationError("");
    }
  };

  const handleDeleteProject = async (projectId) => {
    setLoadingDelete((prev) => ({ ...prev, [projectId]: true }));
    try {
      const response = await deleteProject(projectId);

      if (response.success) {
        toast.success("Projeto deletado com sucesso!");
        fetchProjects();
      } else {
        throw new Error(response.error || "Erro ao deletar projeto");
      }
    } catch (error) {
      toast.error(`Erro ao deletar o projeto`);
      console.error("Erro ao deletar projeto:", JSON.stringify(error));
    } finally {
      setLoadingDelete((prev) => ({ ...prev, [projectId]: false }));
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setProjectName("");
    setProjectAbbreviation("");
    setNameError("");
    setAbbreviationError("");
  };

  if (!hasPermission) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 mb-32">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-medium">Projetos</h1>
        {canCreateProject && (
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-blue-700 transition-colors flex items-center gap-2"
          >
            <FaPlus size={14} />
            Novo Projeto
          </button>
        )}
      </div>

      {projects === null ? (
        <div className="flex flex-col items-center justify-center py-16">
          <Spinner className="text-blue-600 mb-4" />
          <p className="text-sm text-gray-500">Carregando projetos...</p>
        </div>
      ) : (
        <>
          <PlanDashboard
            plan={userPlan}
            usage={adGroupsUsage}
            projects={projects}
            userId={userId}
          />

          <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
            <div className="relative">
              <FaSearch className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                placeholder="Buscar projetos..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <ProjectsList
            projects={projects}
            loadingProjects={loadingProjects}
            loadingDelete={loadingDelete}
            searchTerm={searchTerm}
            canCreateProject={canCreateProject}
            userId={userId}
            onDeleteProject={handleDeleteProject}
            onOpenCreateModal={() => setIsModalOpen(true)}
          />
        </>
      )}

      <CreateProjectModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        projectName={projectName}
        projectAbbreviation={projectAbbreviation}
        projectType={projectType}
        nameError={nameError}
        abbreviationError={abbreviationError}
        loadingCreate={loadingCreate}
        onProjectNameChange={validateProjectName}
        onProjectAbbreviationChange={validateProjectAbbreviation}
        onProjectTypeChange={setProjectType}
        onCreateProject={handleCreateProject}
      />

      <Toaster position="top-right" />
    </div>
  );
};

export default Projects;
