import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import {
  getGamGroupedReport,
  getAdGroupKeywords,
  getPlacementsReport,
  getTrackingUrlTemplate,
  getExchangeRate,
  listProjectConfig,
  getUserInfo,
  getProjectConfigByKey,
} from "../utils/api";
import dayjs from "dayjs";

const TEMPLATES = {
  campaign:
    "{lpurl}?utm_source=googleads&utm_medium=display&utm_campaign={campaignId}&utm_term={campaignId}_{keyword}&utm_content={campaignId}_{placement}",
  adGroup:
    "{lpurl}?utm_source=googleads&utm_medium=display&utm_campaign={adGroupId}&utm_term={adGroupId}_{keyword}&utm_content={adGroupId}_{placement}",
};

const UtmDetailsModal = ({ isOpen, onClose, campaignData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [template, setTemplate] = useState(null);
  const [dollarRate, setDollarRate] = useState(null);
  const [mergedData, setMergedData] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  const [projectConfig, setProjectConfig] = useState(null);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [templateError, setTemplateError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [summaryData, setSummaryData] = useState({
    totalRevenueBRL: 0,
    totalRevenueUSD: 0,
    totalCost: 0,
    roi: 0,
    taxDeduction: 0,
    revShareDeduction: 0,
    dollarDepreciationDeduction: 0,
    netRevenue: 0,
  });
  const [taxConfig, setTaxConfig] = useState({
    revShare: 0,
    tax: 0,
    dollarDepreciation: 0,
  });
  const [showDeductionColumns, setShowDeductionColumns] = useState(() => {
    const savedPreference = localStorage.getItem("showDeductionColumns");
    return savedPreference !== null ? JSON.parse(savedPreference) : false;
  });

  const getTemplateType = (template) => {
    if (!template) return null;

    // Verifica se o template contém utm_term ou utm_content com campaignId ou adGroupId
    const hasUtmTerm = template.includes("utm_term");
    const hasUtmContent = template.includes("utm_content");

    if (hasUtmTerm || hasUtmContent) {
      const paramToCheck = hasUtmTerm ? "utm_term" : "utm_content";
      if (template.includes(`${paramToCheck}={campaignId}`)) return "campaign";
      if (template.includes(`${paramToCheck}={adGroupId}`)) return "adGroup";
    }

    return "custom";
  };

  // Função auxiliar para normalizar domínios (remove apenas www.)
  const normalizeDomain = (domain) => {
    if (!domain) return "";
    return domain.replace(/^www\./i, "");
  };

  // Função para gerar hash SHA-256 e truncar
  const generateTruncatedHash = async (text) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex.substring(0, 20); // Trunca para 20 caracteres
  };

  // Função para encontrar match exato entre hashes
  const findHashMatch = async (keyword, gamKeywords, campaignId) => {
    // Gera o hash da keyword do Google Ads
    const keywordHash = await generateTruncatedHash(
      keyword.toLowerCase().trim()
    );

    // Filtra apenas keywords do GAM da mesma campanha
    const campaignKeywords = gamKeywords.filter((gk) => {
      const utmTerm = gk.metrics.custom_criteria || "";
      const keywordCampaignId = utmTerm.split("utm_term=")[1]?.split("_")[0];
      return keywordCampaignId === campaignId;
    });

    // Procura por match exato do hash
    const match = campaignKeywords.find((gk) => {
      if (gk.used) return false;
      const keywordFromUtm = gk.keyword || "";
      return keywordFromUtm === keywordHash;
    });

    if (match) {
      return {
        gamKeyword: match,
        similarity: 1, // Match exato = 100%
      };
    }

    return null;
  };

  // Função para calcular a similaridade entre duas strings
  const calculateSimilarity = (str1, str2) => {
    const s1 = str1.toLowerCase().trim();
    const s2 = str2.toLowerCase().trim();

    // Se as strings são iguais
    if (s1 === s2) return 1;

    // Quebra as strings em palavras e remove palavras vazias
    const words1 = s1.split(/\s+/).filter(Boolean);
    const words2 = s2.split(/\s+/).filter(Boolean);

    // Se o número de palavras é muito diferente, não é um bom match
    if (Math.abs(words1.length - words2.length) > 2) return 0;

    // Conta palavras exatamente iguais
    let exactMatches = 0;
    const usedWords = new Set();

    words1.forEach((word1) => {
      const matchIndex = words2.findIndex(
        (word2) => !usedWords.has(word2) && word1 === word2
      );

      if (matchIndex !== -1) {
        exactMatches++;
        usedWords.add(words2[matchIndex]);
      }
    });

    // Calcula similaridade baseada em matches exatos
    const totalWords = Math.max(words1.length, words2.length);
    const similarity = exactMatches / totalWords;

    // Retorna 0 se a similaridade for menor que 80%
    return similarity >= 0.8 ? similarity : 0;
  };

  // Função para encontrar match progressivo
  const findProgressiveMatch = (keyword, gamKeywords, campaignId) => {
    const searchKeyword = keyword.toLowerCase().trim();

    // Filtra apenas keywords do GAM da mesma campanha
    const campaignKeywords = gamKeywords.filter((gk) => {
      const utmTerm = gk.metrics.custom_criteria || "";
      const keywordCampaignId = utmTerm.split("utm_term=")[1]?.split("_")[0];
      return keywordCampaignId === campaignId;
    });

    // Encontra o melhor match
    let bestMatch = null;
    let bestSimilarity = 0;

    campaignKeywords.forEach((gamKeyword) => {
      if (!gamKeyword.used) {
        const similarity = calculateSimilarity(
          searchKeyword,
          gamKeyword.keyword
        );
        // Só aceita matches com similaridade >= 80%
        if (similarity > bestSimilarity && similarity >= 0.8) {
          bestSimilarity = similarity;
          bestMatch = gamKeyword;
        }
      }
    });

    if (bestMatch) {
      return {
        gamKeyword: bestMatch,
        similarity: bestSimilarity,
      };
    }

    return null;
  };

  // Função para encontrar a melhor correspondência entre uma keyword e os items do GAM
  const findBestKeywordMatch = (keyword, gamItems) => {
    let bestMatch = null;
    let bestSimilarity = 0;

    gamItems.forEach((item) => {
      const utmTerm = item.custom_criteria || "";
      const keywordFromUtm = utmTerm.split("utm_term=")[1]?.split("_")[1];

      if (keywordFromUtm) {
        const similarity = calculateSimilarity(
          keyword.toLowerCase(),
          keywordFromUtm.toLowerCase()
        );
        if (similarity > bestSimilarity && similarity > 0.7) {
          // Threshold de 70% de similaridade
          bestSimilarity = similarity;
          bestMatch = item;
        }
      }
    });

    return bestMatch;
  };

  useEffect(() => {
    const checkUserScope = async () => {
      try {
        const userInfo = await getUserInfo();
        if (userInfo.success) {
          setIsAdmin(userInfo.data.scopes.includes("admin"));
        }
      } catch (error) {
        console.error("Erro ao verificar permissões do usuário:", error);
        setIsAdmin(false);
      }
    };

    checkUserScope();
  }, []);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!isOpen || !campaignData) return;

      try {
        setIsLoadingTemplate(true);
        setTemplateError(null);
        const response = await getTrackingUrlTemplate(campaignData.projectId);
        if (response.success) {
          if (!response.data.trackingUrlTemplate) {
            setIsLoading(false);
            return;
          }
          setTemplate(response.data.trackingUrlTemplate);
        }
      } catch (error) {
        console.error("Erro ao buscar template:", error);
        setTemplateError(
          "Não foi possível carregar o template de rastreamento"
        );
      } finally {
        setIsLoadingTemplate(false);
      }
    };

    fetchTemplate();
  }, [isOpen, campaignData]);

  const handleDateChange = (type, value) => {
    const newDateRange = {
      ...dateRange,
      [type]: dayjs(value).format("YYYY-MM-DD"),
    };
    setDateRange(newDateRange);
  };

  const exportToCsv = () => {
    // Headers diferentes baseado no tipo de campanha
    const headers = [
      campaignData?.campaignType === "DISPLAY" ? "Domínio" : "Palavra-chave",
      ...(campaignData?.campaignType !== "DISPLAY"
        ? ["Status", "Tipo de Correspondência"]
        : []),
      "Cliques (Ads)",
      "Cliques (GAM)",
      "Impressões (Ads)",
      "Impressões (GAM)",
      "CTR",
      "Custo (BRL)",
      "Conversões",
      "Taxa de Conversão",
      "Custo/Conversão",
      "PMR",
      "Receita (USD)",
      "eCPM",
      "Receita (BRL)",
      "ROI",
    ];

    const csvData = mergedData.map((row) => {
      const clicks = row.clicks || 0;
      const clicksGam = row.clicksGam || 0;
      const impressions = row.impressions || 0;
      const impressionsGam = row.impressionsGam || 0;
      const cost = row.cost || 0;
      const conversions = row.conversions || 0;
      const conversionRate = row.conversionRate || 0;
      const pmr = parseFloat(row.pmr || 0);
      const revenueUSD = row.revenueUSD || 0;
      const ecpm = row.ecpm || 0;
      const revenue = row.revenue || 0;
      const roi = row.roi || 0;
      const ctr = row.ctr || 0;

      const baseData = [
        row.domain || "",
        ...(campaignData?.campaignType !== "DISPLAY"
          ? [row.status || "", row.matchType || ""]
          : []),
        clicks,
        clicksGam,
        impressions,
        impressionsGam,
        `${ctr.toFixed(2)}%`,
        `R$ ${cost.toFixed(2)}`,
        conversions,
        `${conversionRate.toFixed(2)}%`,
        `R$ ${(conversions > 0 ? cost / conversions : 0).toFixed(2)}`,
        `${(pmr * 100).toFixed(2)}%`,
        `$ ${revenueUSD.toFixed(2)}`,
        ecpm ? `$ ${ecpm.toFixed(2)}` : "N/A",
        `R$ ${revenue.toFixed(2)}`,
        `${roi.toFixed(2)}%`,
      ];

      return baseData;
    });

    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `relatorio_${
        campaignData?.campaignType === "DISPLAY" ? "canais" : "palavras_chave"
      }_${dayjs().format("YYYY-MM-DD")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchData = async (dates = dateRange) => {
    if (!isOpen || !campaignData || !template) return;

    setIsLoading(true);
    const templateType = getTemplateType(template);

    if (!templateType || templateType === "custom") {
      setIsLoading(false);
      return;
    }

    try {
      // Buscar configurações do projeto
      const configResponse = await listProjectConfig(campaignData.projectId);
      if (configResponse.success) {
        setProjectConfig(configResponse.data);
      }

      // Buscar configurações específicas de taxas
      const revShareResponse = await getProjectConfigByKey(
        campaignData.projectId,
        "RevShare"
      );
      const taxResponse = await getProjectConfigByKey(
        campaignData.projectId,
        "Tax"
      );
      const dollarDepreciationResponse = await getProjectConfigByKey(
        campaignData.projectId,
        "DollarDepreciation"
      );

      const revShare = revShareResponse.RevShare
        ? Number(revShareResponse.RevShare)
        : 0;
      const tax = taxResponse.Tax ? Number(taxResponse.Tax) : 0;
      const dollarDepreciation = dollarDepreciationResponse.DollarDepreciation
        ? Number(dollarDepreciationResponse.DollarDepreciation)
        : 0;

      setTaxConfig({
        revShare,
        tax,
        dollarDepreciation,
      });

      // Buscar cotação do dólar
      const rate = await getExchangeRate();
      setDollarRate(rate || 5);

      const idValue =
        templateType === "campaign"
          ? campaignData.campaignId
          : campaignData.adGroupId;

      // Buscar dados do GAM com o parâmetro correto baseado no tipo de campanha
      const gamResponse = await getGamGroupedReport(
        campaignData.projectId,
        dates.startDate,
        dates.endDate,
        campaignData.campaignType === "DISPLAY" ? "utm_content" : "utm_term"
      );

      // Buscar dados específicos baseado no tipo de campanha
      if (campaignData.campaignType === "DISPLAY") {
        const placementsResponse = await getPlacementsReport(
          campaignData.projectId,
          {
            campaignId: templateType === "campaign" ? idValue : undefined,
            adGroupId: templateType === "adGroup" ? idValue : undefined,
          },
          dates.startDate,
          dates.endDate
        );

        if (gamResponse.success && placementsResponse.success) {
          // Processar dados detalhados para placements
          const detailedProcessedData = placementsResponse.data.flatMap(
            (domainData) => {
              return domainData.placements.map((placement) => {
                const gamMatch = gamResponse.data.find((item) => {
                  const utmContent = item.items?.[0]?.custom_criteria || "";
                  return (
                    utmContent.includes(`utm_content=${idValue}_`) &&
                    item.items.some((gamItem) => {
                      const gamDomain = gamItem.custom_criteria
                        .split("_")
                        .pop()
                        .replace(/^www\./i, "");
                      return gamDomain === normalizeDomain(domainData.domain);
                    })
                  );
                });

                const placementCost = placement.metrics.cost
                  ? parseFloat(placement.metrics.cost)
                  : 0;
                const placementRevenue = gamMatch ? gamMatch.total.revenue : 0;

                // Aplicar deduções
                const revenueWithRevshare =
                  placementRevenue * ((100 - revShare) / 100);
                const revenueAfterTax =
                  revenueWithRevshare * ((100 - tax) / 100);
                const revenueAfterDepreciation =
                  revenueAfterTax * ((100 - dollarDepreciation) / 100);

                const revenueInBRL = revenueAfterDepreciation * (rate || 5);
                const placementRoi =
                  placementCost > 0
                    ? ((revenueInBRL - placementCost) / placementCost) * 100
                    : 0;

                return {
                  domain:
                    placement.placementType === "YOUTUBE_VIDEO"
                      ? placement.targetUrl
                      : domainData.domain,
                  placement: placement.displayName,
                  clicks: placement.metrics.clicks || 0,
                  impressions: placement.metrics.impressions || 0,
                  cost: placementCost,
                  ctr: placement.metrics.ctr
                    ? parseFloat(placement.metrics.ctr)
                    : 0,
                  conversions: placement.metrics.conversions || 0,
                  revenueUSD: revenueWithRevshare,
                  revenueAfterTax: revenueAfterTax,
                  revenueAfterDepreciation: revenueAfterDepreciation,
                  revenue: revenueInBRL,
                  roi: placementRoi,
                  isYoutubeVideo: placement.placementType === "YOUTUBE_VIDEO",
                  taxDeductions: {
                    revShare: placementRevenue * (revShare / 100),
                    tax: revenueWithRevshare * (tax / 100),
                    dollarDepreciation:
                      revenueAfterTax * (dollarDepreciation / 100),
                  },
                };
              });
            }
          );

          setDetailedData(detailedProcessedData);

          // Calcular totais para o resumo
          let totalRevenueUSD = 0;
          let totalRevenueBRL = 0;
          let totalCost = 0;
          let totalRevShareDeduction = 0;
          let totalTaxDeduction = 0;
          let totalDollarDepreciationDeduction = 0;
          let totalNetRevenue = 0;

          const aggregatedData = placementsResponse.data.map((domainData) => {
            const domainGamMatches = gamResponse.data.filter((item) => {
              const utmContent = item.items?.[0]?.custom_criteria || "";
              return (
                utmContent.includes(`utm_content=${idValue}_`) &&
                item.items.some((gamItem) => {
                  const gamDomain = gamItem.custom_criteria
                    .split("_")
                    .pop()
                    .replace(/^www\./i, "");
                  return gamDomain === normalizeDomain(domainData.domain);
                })
              );
            });

            // Verificar se é um vídeo do YouTube e usar a targetUrl
            const isYoutubeVideo =
              domainData.placements[0]?.placementType === "YOUTUBE_VIDEO";
            const displayDomain = isYoutubeVideo
              ? domainData.placements[0]?.targetUrl
              : domainData.domain;

            const domainRevenue = domainGamMatches.reduce((sum, match) => {
              const matchRevenue = match.items.reduce((itemSum, item) => {
                const itemDomain = item.custom_criteria
                  .split("_")
                  .pop()
                  .replace(/^www\./i, "");
                if (itemDomain === normalizeDomain(domainData.domain)) {
                  return itemSum + (parseFloat(item.revenue) || 0);
                }
                return itemSum;
              }, 0);
              return sum + matchRevenue;
            }, 0);

            // Calcular métricas do GAM agregadas
            const gamMetrics = domainGamMatches.reduce(
              (metrics, match) => {
                match.items.forEach((item) => {
                  const itemDomain = item.custom_criteria
                    .split("_")
                    .pop()
                    .replace(/^www\./i, "");
                  if (itemDomain === normalizeDomain(domainData.domain)) {
                    metrics.clicks += parseInt(item.clicks) || 0;
                    metrics.impressions += parseInt(item.impressions) || 0;
                    metrics.pmr = Math.max(
                      metrics.pmr,
                      parseFloat(item.pmr) || 0
                    );
                    metrics.ecpm = parseFloat(item.ecpm) || 0;
                  }
                });
                return metrics;
              },
              { clicks: 0, impressions: 0, pmr: 0, ecpm: 0 }
            );

            const domainCost = domainData.totalMetrics.cost
              ? parseFloat(domainData.totalMetrics.cost)
              : 0;

            // Aplicar deduções
            const revenueWithRevshare =
              domainRevenue * ((100 - revShare) / 100);
            const revenueAfterTax = revenueWithRevshare * ((100 - tax) / 100);
            const revenueAfterDepreciation =
              revenueAfterTax * ((100 - dollarDepreciation) / 100);
            const revenueInBRL = revenueAfterDepreciation * (rate || 5);

            const revShareDeduction = domainRevenue * (revShare / 100);
            const taxDeduction = revenueWithRevshare * (tax / 100);
            const dollarDepreciationDeduction =
              revenueAfterTax * (dollarDepreciation / 100);

            const domainRoi =
              domainCost > 0
                ? ((revenueInBRL - domainCost) / domainCost) * 100
                : 0;

            totalRevenueUSD += revenueWithRevshare;
            totalRevenueBRL += revenueInBRL;
            totalCost += domainCost;
            totalRevShareDeduction += revShareDeduction;
            totalTaxDeduction += taxDeduction;
            totalDollarDepreciationDeduction += dollarDepreciationDeduction;
            totalNetRevenue += revenueInBRL;

            return {
              domain: displayDomain,
              clicks: domainData.totalMetrics.clicks || 0,
              impressions: domainData.totalMetrics.impressions || 0,
              clicksGam: gamMetrics.clicks,
              impressionsGam: gamMetrics.impressions,
              cost: domainCost,
              ctr:
                domainData.totalMetrics.impressions > 0
                  ? ((domainData.totalMetrics.clicks || 0) /
                      domainData.totalMetrics.impressions) *
                    100
                  : 0,
              conversions: domainData.totalMetrics.conversions || 0,
              conversionRate:
                domainData.totalMetrics.clicks > 0
                  ? ((domainData.totalMetrics.conversions || 0) /
                      domainData.totalMetrics.clicks) *
                    100
                  : 0,
              revenueUSD: revenueWithRevshare,
              revenueAfterTax: revenueAfterTax,
              revenueAfterDepreciation: revenueAfterDepreciation,
              revenue: revenueInBRL,
              roi: domainRoi,
              pmr: gamMetrics.pmr,
              ecpm: gamMetrics.ecpm,
              isYoutubeVideo: isYoutubeVideo,
              taxDeductions: {
                revShare: revShareDeduction,
                tax: taxDeduction,
                dollarDepreciation: dollarDepreciationDeduction,
              },
            };
          });

          const totalROI =
            totalCost > 0
              ? ((totalRevenueBRL - totalCost) / totalCost) * 100
              : 0;

          setSummaryData({
            totalRevenueBRL,
            totalRevenueUSD,
            totalCost,
            roi: totalROI,
            taxDeduction: totalTaxDeduction,
            revShareDeduction: totalRevShareDeduction,
            dollarDepreciationDeduction: totalDollarDepreciationDeduction,
            netRevenue:
              totalRevenueBRL -
              (totalTaxDeduction + totalDollarDepreciationDeduction) *
                (rate || 5),
          });

          setMergedData(aggregatedData);
        }
      } else {
        // Lógica para campanhas de pesquisa
        const keywordsResponse = await getAdGroupKeywords(
          campaignData.projectId,
          dates.startDate,
          dates.endDate,
          templateType === "campaign" ? idValue : undefined,
          templateType === "adGroup" ? idValue : undefined
        );

        if (gamResponse.success && keywordsResponse.success) {
          // Extrai keywords do GAM
          const gamKeywords = gamResponse.data
            .flatMap((gamItem) =>
              gamItem.items.map((item) => {
                const utmTerm = item.custom_criteria || "";
                const [campaignId, keywordHash] =
                  utmTerm.split("utm_term=")[1]?.split("_") || [];
                return {
                  keyword: keywordHash, // Agora é o hash truncado
                  campaignId,
                  metrics: item,
                  clicks: parseInt(item.clicks) || 0,
                  used: false,
                };
              })
            )
            .filter((item) => item.keyword && item.campaignId);

          // Ordenar keywords do Google Ads por número de cliques
          const sortedKeywords = [...keywordsResponse.data].sort(
            (a, b) =>
              (parseInt(b.metrics.clicks) || 0) -
              (parseInt(a.metrics.clicks) || 0)
          );

          // Processar dados detalhados para keywords
          const detailedProcessedData = await Promise.all(
            sortedKeywords.map(async (keywordData) => {
              const clicks = parseInt(keywordData.metrics.clicks) || 0;
              const cost =
                parseFloat(keywordData.metrics.costMicros) / 1000000 || 0; // Convertendo micros para reais

              if (clicks === 0) {
                return {
                  domain: keywordData.adGroupCriterion.keyword.text,
                  status: keywordData.adGroupCriterion.status,
                  negative: false,
                  keywordGam: "N/A",
                  similarity: "N/A",
                  clicks,
                  impressions: parseInt(keywordData.metrics.impressions) || 0,
                  cost,
                  conversions: parseInt(keywordData.metrics.conversions) || 0,
                  conversionRate: 0,
                  revenueUSD: 0,
                  revenue: 0,
                  roi: 0,
                  matchType: keywordData.adGroupCriterion.keyword.matchType,
                  clicksGam: 0,
                  impressionsGam: 0,
                  taxDeductions: {
                    revShare: 0,
                    tax: 0,
                    dollarDepreciation: 0,
                  },
                };
              }

              // Encontra o match usando o hash
              const match = await findHashMatch(
                keywordData.adGroupCriterion.keyword.text,
                gamKeywords,
                keywordData.campaign.id
              );

              if (match) {
                match.gamKeyword.used = true;

                const revenueWithRevshare =
                  parseFloat(match.gamKeyword.metrics.revenue) *
                  ((100 - (taxConfig?.revShare || 0)) / 100);
                const revenueInBRL = revenueWithRevshare * (rate || 5);
                const keywordRoi =
                  cost > 0 ? ((revenueInBRL - cost) / cost) * 100 : 0;

                // Calcular deduções
                const revShareDeduction =
                  parseFloat(match.gamKeyword.metrics.revenue) *
                  ((taxConfig?.revShare || 0) / 100);
                const taxDeduction =
                  revenueWithRevshare * ((taxConfig?.tax || 0) / 100);
                const dollarDepreciationDeduction =
                  (revenueWithRevshare - taxDeduction) *
                  ((taxConfig?.dollarDepreciation || 0) / 100);

                return {
                  domain: keywordData.adGroupCriterion.keyword.text,
                  status: keywordData.adGroupCriterion.status,
                  negative: false,
                  keywordGam: match.gamKeyword.keyword,
                  similarity: "100%",
                  clicks,
                  impressions: parseInt(keywordData.metrics.impressions) || 0,
                  cost,
                  conversions: parseInt(keywordData.metrics.conversions) || 0,
                  conversionRate:
                    clicks > 0
                      ? (parseInt(keywordData.metrics.conversions) / clicks) *
                        100
                      : 0,
                  revenueUSD: revenueWithRevshare,
                  revenue: revenueInBRL,
                  roi: keywordRoi,
                  matchType: keywordData.adGroupCriterion.keyword.matchType,
                  clicksGam: parseInt(match.gamKeyword.metrics.clicks) || 0,
                  impressionsGam:
                    parseInt(match.gamKeyword.metrics.impressions) || 0,
                  ecpm: parseFloat(match.gamKeyword.metrics.ecpm) || 0,
                  pmr: parseFloat(match.gamKeyword.metrics.pmr) || 0,
                  taxDeductions: {
                    revShare: revShareDeduction,
                    tax: taxDeduction,
                    dollarDepreciation: dollarDepreciationDeduction,
                  },
                };
              }

              return {
                domain: keywordData.adGroupCriterion.keyword.text,
                status: keywordData.adGroupCriterion.status,
                negative: false,
                keywordGam: "N/A",
                similarity: "N/A",
                clicks,
                impressions: parseInt(keywordData.metrics.impressions) || 0,
                cost,
                conversions: parseInt(keywordData.metrics.conversions) || 0,
                conversionRate:
                  clicks > 0
                    ? (parseInt(keywordData.metrics.conversions) / clicks) * 100
                    : 0,
                revenueUSD: 0,
                revenue: 0,
                roi: 0,
                matchType: keywordData.adGroupCriterion.keyword.matchType,
                clicksGam: 0,
                impressionsGam: 0,
                taxDeductions: {
                  revShare: 0,
                  tax: 0,
                  dollarDepreciation: 0,
                },
              };
            })
          );

          setDetailedData(detailedProcessedData);

          // Calcular totais
          const totals = detailedProcessedData.reduce(
            (acc, item) => ({
              totalRevenueUSD: acc.totalRevenueUSD + item.revenueUSD,
              totalRevenueBRL: acc.totalRevenueBRL + item.revenue,
              totalCost: acc.totalCost + item.cost,
              totalRevShareDeduction:
                acc.totalRevShareDeduction +
                (item.taxDeductions?.revShare || 0),
              totalTaxDeduction:
                acc.totalTaxDeduction + (item.taxDeductions?.tax || 0),
              totalDollarDepreciationDeduction:
                acc.totalDollarDepreciationDeduction +
                (item.taxDeductions?.dollarDepreciation || 0),
            }),
            {
              totalRevenueUSD: 0,
              totalRevenueBRL: 0,
              totalCost: 0,
              totalRevShareDeduction: 0,
              totalTaxDeduction: 0,
              totalDollarDepreciationDeduction: 0,
            }
          );

          const totalROI =
            totals.totalCost > 0
              ? ((totals.totalRevenueBRL - totals.totalCost) /
                  totals.totalCost) *
                100
              : 0;

          setSummaryData({
            totalRevenueBRL: totals.totalRevenueBRL,
            totalRevenueUSD: totals.totalRevenueUSD,
            totalCost: totals.totalCost,
            roi: totalROI,
            taxDeduction: totals.totalTaxDeduction,
            revShareDeduction: totals.totalRevShareDeduction,
            dollarDepreciationDeduction:
              totals.totalDollarDepreciationDeduction,
            netRevenue:
              totals.totalRevenueBRL -
              (totals.totalTaxDeduction +
                totals.totalDollarDepreciationDeduction) *
                (rate || 5),
          });

          setMergedData(detailedProcessedData);
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isOpen, campaignData, template]);

  const columns = [
    {
      accessorKey: "domain",
      header:
        campaignData?.campaignType === "DISPLAY" ? "Domínio" : "Palavra-chave",
      size: 200,
      enablePinning: true,
      Pin: "left",
      Cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <span>{row.original.domain}</span>
          {campaignData?.campaignType !== "DISPLAY" && (
            <>
              {row.original.status === "PAUSED" && (
                <svg
                  className="w-3 h-3 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  title="Palavra-chave pausada"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              {row.original.negative && (
                <svg
                  className="w-3 h-3 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  title="Palavra-chave negativa"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </>
          )}
        </div>
      ),
    },
    ...(campaignData?.campaignType !== "DISPLAY" && isAdmin
      ? [
          {
            accessorKey: "keywordGam",
            header: "Keyword no GAM",
            size: 200,
            Cell: ({ cell }) => (
              <div className="flex items-center gap-2">
                <span className="text-gray-600">{cell.getValue()}</span>
              </div>
            ),
          },
          {
            accessorKey: "similarity",
            header: "Match",
            size: 80,
            Cell: ({ cell }) => (
              <span
                className={`text-xs px-2 py-1 rounded ${
                  cell.getValue() !== "N/A"
                    ? parseInt(cell.getValue()) > 90
                      ? "bg-green-100 text-green-700"
                      : "bg-amber-100 text-amber-700"
                    : "bg-gray-100 text-gray-700"
                }`}
              >
                {cell.getValue()}
              </span>
            ),
          },
        ]
      : []),
    {
      accessorKey: "matchType",
      header: "Tipo de Correspondência",
      size: 120,
      Cell: ({ cell }) =>
        campaignData?.campaignType === "DISPLAY" ? null : (
          <span className="text-xs px-2 py-1 bg-gray-100 rounded">
            {cell.getValue()}
          </span>
        ),
    },
    {
      accessorKey: "clicks",
      header: "Cliques (Ads)",
      size: 100,
    },
    {
      accessorKey: "clicksGam",
      header: "Cliques (GAM)",
      size: 100,
    },
    {
      accessorKey: "impressions",
      header: "Impressões (Ads)",
      size: 120,
    },
    {
      accessorKey: "impressionsGam",
      header: "Impressões (GAM)",
      size: 120,
    },
    {
      accessorKey: "cost",
      header: "Custo",
      size: 120,
      Cell: ({ cell }) => `R$ ${parseFloat(cell.getValue()).toFixed(2)}`,
    },
    {
      accessorKey: "conversions",
      header: "Conversões",
      size: 100,
    },
    {
      accessorKey: "conversionRate",
      header: "Taxa de Conversão",
      size: 120,
      Cell: ({ row }) => {
        const clicks = row.original.clicks || 0;
        const conversions = row.original.conversions || 0;
        const rate = clicks > 0 ? (conversions / clicks) * 100 : 0;
        return `${rate.toFixed(2)}%`;
      },
    },
    {
      accessorKey: "costPerConversion",
      header: "Custo/Conversão",
      size: 120,
      Cell: ({ row }) => {
        const cost = row.original.cost || 0;
        const conversions = row.original.conversions || 0;
        const cpc = conversions > 0 ? cost / conversions : 0;
        return `R$ ${cpc.toFixed(2)}`;
      },
    },
    {
      accessorKey: "pmr",
      header: "PMR",
      size: 100,
      Cell: ({ cell }) => {
        const pmr = parseFloat(cell.getValue() || 0) * 100;
        return `${pmr.toFixed(2)}%`;
      },
    },
    {
      accessorKey: "revenueUSD",
      header: "Receita (USD)",
      size: 140,
      Cell: ({ cell }) => (
        <div>
          <span>$ {parseFloat(cell.getValue()).toFixed(2)}</span>
        </div>
      ),
    },
    {
      accessorKey: "ecpm",
      header: "eCPM",
      size: 120,
      Cell: ({ cell }) =>
        cell.getValue() ? `$ ${parseFloat(cell.getValue()).toFixed(2)}` : "N/A",
    },
    {
      accessorKey: "revenue",
      header: "Receita (BRL)",
      size: 140,
      Cell: ({ cell }) => (
        <div>
          <span>R$ {parseFloat(cell.getValue()).toFixed(2)}</span>
        </div>
      ),
    },
    {
      accessorKey: "roi",
      header: "ROI",
      size: 120,
      Cell: ({ cell }) => {
        const roi = parseFloat(cell.getValue());
        return <span className={getRoiColorClass(roi)}>{roi.toFixed(2)}%</span>;
      },
    },
    // Colunas de deduções condicionais
    ...(showDeductionColumns
      ? [
          {
            accessorKey: "revShareDeduction",
            header: "Rev Share",
            size: 120,
            Cell: ({ row }) => {
              const revShareValue =
                (row.original.taxDeductions?.revShare || 0) * (dollarRate || 0);
              return revShareValue > 0 ? (
                <span className="text-red-400 font-medium">
                  {taxConfig.revShare}% / R${revShareValue.toFixed(2)}
                </span>
              ) : null;
            },
          },
          {
            accessorKey: "taxDeduction",
            header: "Imposto",
            size: 120,
            Cell: ({ row }) => {
              const taxValue =
                (row.original.taxDeductions?.tax || 0) * (dollarRate || 0);
              return taxValue > 0 ? (
                <span className="text-red-400 font-medium">
                  {taxConfig.tax}% / R${taxValue.toFixed(2)}
                </span>
              ) : null;
            },
          },
          {
            accessorKey: "dollarDepreciationDeduction",
            header: "Depreciação",
            size: 120,
            Cell: ({ row }) => {
              const depreciationValue =
                (row.original.taxDeductions?.dollarDepreciation || 0) *
                (dollarRate || 0);
              return depreciationValue > 0 ? (
                <span className="text-red-400 font-medium">
                  {taxConfig.dollarDepreciation}% / R$
                  {depreciationValue.toFixed(2)}
                </span>
              ) : null;
            },
          },
        ]
      : []),
  ].filter((column) => {
    // Remove colunas específicas de pesquisa quando for display
    if (campaignData?.campaignType === "DISPLAY") {
      // Não remover colunas de dedução se showDeductionColumns for true
      if (
        showDeductionColumns &&
        [
          "revShareDeduction",
          "taxDeduction",
          "dollarDepreciationDeduction",
        ].includes(column.accessorKey)
      ) {
        return true;
      }
      return !["matchType", "keywordGam", "similarity"].includes(
        column.accessorKey
      );
    }
    return true;
  });

  if (!isOpen) return null;

  // Validação para tracking template não configurado
  if (isLoadingTemplate) {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{ zIndex: 99999 }}
      >
        <div className="bg-white rounded-lg w-[95%] max-w-xl p-4 shadow-lg relative">
          <div className="flex flex-col items-center justify-center py-8">
            <div className="h-8 w-8 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            <span className="text-sm text-gray-500 mt-4">
              Carregando template de rastreamento...
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (templateError) {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{ zIndex: 99999 }}
      >
        <div className="bg-white rounded-lg w-[95%] max-w-xl p-4 shadow-lg relative">
          <button
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
            onClick={onClose}
          >
            &times;
          </button>

          <div className="mt-6 mb-8">
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    <span className="font-bold">Erro ao carregar template</span>
                  </p>
                  <p className="text-sm text-red-600 mt-1">{templateError}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              onClick={onClose}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!template) {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{ zIndex: 99999 }}
      >
        <div className="bg-white rounded-lg w-[95%] max-w-xl p-4 shadow-lg relative">
          <button
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
            onClick={onClose}
          >
            &times;
          </button>

          <div className="mt-6 mb-8">
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    <span className="font-bold">
                      Template de rastreamento não configurado
                    </span>
                  </p>
                  <p className="text-sm text-red-600 mt-1">
                    Para utilizar o rastreamento por canais, é necessário
                    configurar um template de URL de rastreamento nas
                    configurações do projeto.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              onClick={onClose}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    );
  }

  const templateType = getTemplateType(template);
  const templateWarning = !templateType || templateType === "custom";

  const getRoiColorClass = (roi) => {
    if (roi < 0) return "text-red-600 font-bold";
    if (roi >= 40) return "text-green-600 font-bold";
    return "text-amber-600 font-bold";
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      style={{ zIndex: 99999 }}
    >
      <div
        className="bg-white rounded-lg w-[95%] max-w-7xl p-4 shadow-lg relative max-h-[90vh] overflow-y-auto"
        style={{ zIndex: 100000 }}
      >
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="text-lg font-semibold text-gray-800 mb-4 mt-6">
          Detalhes do UTM: {campaignData?.utm}
        </h2>

        {templateWarning ? (
          <div className="text-sm text-gray-600 mb-4 flex items-center gap-2">
            <svg
              className="w-4 h-4 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
            Configure o template de URL de rastreamento para{" "}
            {campaignData?.campaignType === "DISPLAY"
              ? "Nível de Campanha"
              : "Nível de Grupo de Anúncios"}
            .
          </div>
        ) : (
          <>
            <div className="flex flex-col md:flex-row gap-4 mb-4 items-center">
              <div className="flex items-center gap-4">
                <div>
                  <label className="block text-sm text-gray-600 mb-1">
                    Data Inicial
                  </label>
                  <input
                    type="date"
                    value={dateRange.startDate}
                    max={dateRange.endDate}
                    onChange={(e) =>
                      handleDateChange("startDate", e.target.value)
                    }
                    className="border rounded px-2 py-1 text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-600 mb-1">
                    Data Final
                  </label>
                  <input
                    type="date"
                    value={dateRange.endDate}
                    min={dateRange.startDate}
                    max={dayjs().format("YYYY-MM-DD")}
                    onChange={(e) =>
                      handleDateChange("endDate", e.target.value)
                    }
                    className="border rounded px-2 py-1 text-sm"
                  />
                </div>
                <button
                  onClick={() => fetchData()}
                  disabled={isLoading}
                  className="mt-6 px-3 py-1 bg-gray-100 text-gray-600 rounded hover:bg-gray-200 text-sm flex items-center gap-1 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <div className="h-4 w-4 border-2 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
                  ) : (
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      />
                    </svg>
                  )}
                  Atualizar
                </button>
              </div>

              <div className="flex-grow"></div>

              <button
                onClick={exportToCsv}
                className="px-4 py-2 bg-blue-50 text-blue-600 rounded hover:bg-blue-100 text-sm flex items-center gap-2"
                disabled={isLoading || mergedData.length === 0}
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
                Exportar CSV
              </button>
            </div>

            <div className="text-xs text-gray-500 mb-4 flex items-center gap-2">
              <svg className="w-3 h-3" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              {templateType === "campaign"
                ? "Rastreamento por ID da Campanha"
                : "Rastreamento por ID do Grupo de Anúncios"}
              {campaignData?.campaignType === "DISPLAY" &&
                templateType === "adGroup" && (
                  <span className="text-green-600">
                    ✓ Configuração recomendada
                  </span>
                )}
            </div>

            <div className="text-xs text-gray-400 mb-6">
              <span className="italic">
                Funcionalidade em fase beta - Os dados podem levar alguns
                segundos para carregar.
              </span>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg mb-4">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {isLoading ? (
                  <div className="col-span-4 flex flex-col items-center justify-center py-8">
                    <div className="h-8 w-8 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                    <span className="text-sm text-gray-500 mt-2">
                      Carregando dados dos canais...
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="text-sm">
                      <p className="text-gray-600">Receita (USD)</p>
                      <p className="text-lg font-semibold">
                        $ {summaryData.totalRevenueUSD.toFixed(2)}
                        {taxConfig.revShare > 0 && (
                          <span className="text-xs text-gray-500 ml-1">
                            (após {taxConfig.revShare}% revshare)
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="text-sm">
                      <p className="text-gray-600">Receita (BRL)</p>
                      <p className="text-lg font-semibold">
                        R$ {summaryData.totalRevenueBRL.toFixed(2)}
                      </p>
                      <div className="text-xs text-gray-500">
                        {taxConfig.revShare > 0 && (
                          <p>
                            RevShare: -{taxConfig.revShare}% (R${" "}
                            {(
                              summaryData.revShareDeduction * (dollarRate || 0)
                            ).toFixed(2)}
                            )
                          </p>
                        )}
                        {taxConfig.tax > 0 && (
                          <p>
                            Taxa: -{taxConfig.tax}% (R${" "}
                            {(
                              summaryData.taxDeduction * (dollarRate || 0)
                            ).toFixed(2)}
                            )
                          </p>
                        )}
                        {taxConfig.dollarDepreciation > 0 && (
                          <p>
                            Depreciação: -{taxConfig.dollarDepreciation}% (R${" "}
                            {(
                              summaryData.dollarDepreciationDeduction *
                              (dollarRate || 0)
                            ).toFixed(2)}
                            )
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="text-sm">
                      <p className="text-gray-600">Custo</p>
                      <p className="text-lg font-semibold">
                        R$ {summaryData.totalCost.toFixed(2)}
                      </p>
                    </div>
                    <div className="text-sm">
                      <p className="text-gray-600">ROI</p>
                      <p
                        className={`text-lg ${getRoiColorClass(
                          summaryData.roi
                        )}`}
                      >
                        {summaryData.roi.toFixed(2)}%
                      </p>
                    </div>
                    <div className="text-xs text-gray-500 col-span-2 md:col-span-4">
                      <p>Cotação do dólar: R$ {(dollarRate || 0).toFixed(2)}</p>
                      {(taxConfig.revShare > 0 ||
                        taxConfig.tax > 0 ||
                        taxConfig.dollarDepreciation > 0) && (
                        <p className="font-semibold mt-1">
                          Valor líquido após deduções: R${" "}
                          {summaryData.netRevenue.toFixed(2)}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            {!isLoading && (
              <MaterialReactTable
                columns={columns}
                data={mergedData}
                enablePagination={false}
                enableColumnResizing={false}
                enableColumnOrdering={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                enableSorting={true}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enablePinning={true}
                localization={MRT_Localization_PT_BR}
                muiTableHeadCellProps={{
                  sx: {
                    fontSize: "0.9rem",
                    backgroundColor: "#f3f4f6",
                    fontWeight: "bold",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: { fontSize: "0.8rem" },
                }}
                initialState={{
                  density: "compact",
                  sorting: [{ id: "clicks", desc: true }],
                  columnPinning: {
                    left: ["domain"],
                  },
                }}
                muiTablePaperProps={{
                  sx: {
                    boxShadow: "none",
                    border: "1px solid #e5e7eb",
                  },
                }}
              />
            )}

            {/* Switch para controlar a exibição das colunas de deduções */}
            <div className="mt-6 mb-2 flex items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showDeductionColumns}
                  onChange={(e) => {
                    const newValue = e.target.checked;
                    setShowDeductionColumns(newValue);
                    localStorage.setItem(
                      "showDeductionColumns",
                      JSON.stringify(newValue)
                    );
                  }}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-700">
                  Exibir colunas de deduções detalhadas
                </span>
              </label>
            </div>
          </>
        )}

        <div className="mt-4">
          <p className="text-xs text-gray-400 italic">
            Dados atualizados diariamente. Alterações nas configurações de
            rastreamento serão refletidas no próximo dia.
          </p>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <button
            className="px-4 py-2 bg-gray-100 text-gray-600 rounded hover:bg-gray-200 text-sm"
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UtmDetailsModal;
