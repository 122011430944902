import React, { useState, useEffect } from "react";
import { FaMousePointer } from "react-icons/fa";
import ProjectSelector from "./ProjectSelector";
import UrlConfigStep from "./UrlConfigStep";
import TagConfigStep from "./TagConfigStep";
import StepsGuide from "./StepsGuide";

const CreateConversion = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tagName, setTagName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState("select-project"); // "select-project" | "url-config" | "tag-config"
  const [currentGuideStep, setCurrentGuideStep] = useState("project");
  const [targetUrl, setTargetUrl] = useState("");
  const [urlLoading, setUrlLoading] = useState(false);
  const [urlFetched, setUrlFetched] = useState(false);
  const [postId, setPostId] = useState(null);
  const [matchOptions, setMatchOptions] = useState({
    wordpress: true,
    elementor: true,
    matchByUrl: true,
  });

  // Estados para armazenar dados da conversão
  const [conversionResourceName, setConversionResourceName] = useState(null);
  const [conversionLabel, setConversionLabel] = useState(null);
  const [createdTag, setCreatedTag] = useState(null);
  const [publishStatus, setPublishStatus] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);

  // Efeito para atualizar o passo do guia com base no passo atual
  useEffect(() => {
    switch (step) {
      case "select-project":
        setCurrentGuideStep("project");
        break;
      case "url-config":
        setCurrentGuideStep("url");
        break;
      case "tag-config":
        // Verifica se já criou a tag para mostrar o passo de sucesso
        if (createdTag) {
          setCurrentGuideStep("success");
        } else {
          setCurrentGuideStep("tag");
        }
        break;
      default:
        setCurrentGuideStep("project");
    }

    // Se estiver publicando, atualiza o guia
    if (isPublishing) {
      setCurrentGuideStep("publishing");
    }

    // Se já publicou com sucesso, atualiza o guia
    if (publishStatus && publishStatus.success) {
      setCurrentGuideStep("published");
    }
  }, [step, createdTag, isPublishing, publishStatus]);

  // Renderizar o conteúdo com base no passo atual
  const renderStepContent = () => {
    switch (step) {
      case "select-project":
        return (
          <ProjectSelector
            loading={loading}
            errorMessage={errorMessage}
            projects={projects}
            setProjects={setProjects}
            setLoading={setLoading}
            setErrorMessage={setErrorMessage}
            onSelectProject={(project) => {
              setSelectedProject(project);
              setStep("url-config");
            }}
          />
        );
      case "url-config":
        return (
          <UrlConfigStep
            selectedProject={selectedProject}
            targetUrl={targetUrl}
            setTargetUrl={setTargetUrl}
            urlLoading={urlLoading}
            setUrlLoading={setUrlLoading}
            urlFetched={urlFetched}
            setUrlFetched={setUrlFetched}
            postId={postId}
            setPostId={setPostId}
            onBack={() => {
              setSelectedProject(null);
              setTagName("");
              setTargetUrl("");
              setPostId(null);
              setUrlFetched(false);
              setStep("select-project");
            }}
            onNext={() => setStep("tag-config")}
          />
        );
      case "tag-config":
        return (
          <TagConfigStep
            selectedProject={selectedProject}
            targetUrl={targetUrl}
            postId={postId}
            setPostId={setPostId}
            tagName={tagName}
            setTagName={setTagName}
            matchOptions={matchOptions}
            setMatchOptions={setMatchOptions}
            loading={loading}
            setLoading={setLoading}
            conversionResourceName={conversionResourceName}
            setConversionResourceName={setConversionResourceName}
            conversionLabel={conversionLabel}
            setConversionLabel={setConversionLabel}
            createdTag={createdTag}
            setCreatedTag={setCreatedTag}
            publishStatus={publishStatus}
            setPublishStatus={setPublishStatus}
            isPublishing={isPublishing}
            setIsPublishing={setIsPublishing}
            onBack={() => {
              setTagName("");
              setStep("url-config");
            }}
            onReset={() => {
              setTagName("");
              setTargetUrl("");
              setPostId(null);
              setUrlFetched(false);
              setSelectedProject(null);
              setMatchOptions({
                wordpress: true,
                elementor: true,
                matchByUrl: true,
              });
              setConversionResourceName(null);
              setConversionLabel(null);
              setCreatedTag(null);
              setPublishStatus(null);
              setStep("select-project");
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-5xl mx-auto p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900 flex items-center">
          <FaMousePointer className="mr-2 text-blue-500" />
          Criar Conversão de Cliques em Botões
        </h1>
        <p className="text-gray-600 mt-1">
          Configure conversões de cliques em botões no Google Ads usando o
          Google Tag Manager
        </p>
        <div className="mt-3 bg-blue-50 border border-blue-200 rounded-md p-3">
          <p className="text-sm text-blue-700">
            <strong>Nota:</strong> Este sistema cria tags especialmente para
            containers GTM web e não tem suporte (por enquanto) para o modelo de
            conversões via servidor (stape/sGTM).
          </p>
        </div>
      </div>

      <StepsGuide currentStep={currentGuideStep} />

      {renderStepContent()}
    </div>
  );
};

export default CreateConversion;
