import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  getGoogleAdsAuthUrl,
  getGoogleAdsAccountsDetails,
  updateGoogleAdsAccount,
} from "../../utils/api";
import {
  FaGoogle,
  FaSpinner,
  FaExclamationTriangle,
  FaInfoCircle,
  FaCheckCircle,
  FaAngleRight,
  FaCircle,
  FaEdit,
  FaTrash,
  FaSearch,
  FaKeyboard,
} from "react-icons/fa";

const IntegrateAccountModal = ({ isOpen, onClose, projectId }) => {
  const [loading, setLoading] = useState(false);
  const [authComplete, setAuthComplete] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [accountError, setAccountError] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [manualInput, setManualInput] = useState("");
  const [showManualInput, setShowManualInput] = useState(false);

  const handleIntegrateAccount = async () => {
    setLoading(true);
    try {
      const { authUrl } = await getGoogleAdsAuthUrl();

      if (authUrl) {
        localStorage.setItem(
          "googleAdsIntegration",
          JSON.stringify({ projectId })
        );
        window.location.href = authUrl;
      } else {
        toast.error("Falha ao gerar a URL de autenticação.");
      }
    } catch (error) {
      console.error("Erro durante a integração da conta:", error);
      toast.error("Ocorreu um erro ao tentar integrar sua conta.");
    } finally {
      setLoading(false);
    }
  };

  const handleManualSubmit = async () => {
    if (!manualInput.trim()) {
      toast.error("Por favor, insira um ID de conta válido");
      return;
    }

    if (!/^\d+$/.test(manualInput.trim())) {
      toast.error("O ID da conta deve conter apenas números");
      return;
    }

    try {
      toast.loading("Salvando sua conta...");
      const response = await updateGoogleAdsAccount(
        projectId,
        manualInput.trim()
      );

      if (response && response.success) {
        toast.dismiss();
        toast.success("Conta Google Ads integrada com sucesso!");
        onClose();
        window.location.reload();
      } else {
        toast.dismiss();
        toast.error(response?.message || "Erro ao integrar conta Google Ads");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Erro ao integrar conta: " + (error.message || "Erro desconhecido")
      );
      console.error("Erro ao selecionar conta:", error);
    }
  };

  React.useEffect(() => {
    const checkCallbackState = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (
        urlParams.has("googleads_auth") &&
        urlParams.get("googleads_auth") === "success"
      ) {
        setAuthComplete(true);
        setLoadingAccounts(true);

        try {
          const result = await getGoogleAdsAccountsDetails(projectId);
          if (result.success && result.accounts) {
            setAccounts(result.accounts);
            console.log("Contas carregadas:", result.accounts);
          } else {
            setAccountError(
              result.message ||
                "Não foi possível carregar as contas disponíveis"
            );
          }
        } catch (error) {
          setAccountError("Erro ao buscar contas de anúncios");
        } finally {
          setLoadingAccounts(false);
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        }
      }
    };

    if (isOpen) {
      checkCallbackState();
    }
  }, [isOpen, projectId]);

  const handleSelectAccount = async (accountId) => {
    setSelectedAccountId(accountId);
  };

  const handleConfirmSelection = async () => {
    if (!selectedAccountId) {
      toast.error("Por favor, selecione uma conta para continuar");
      return;
    }

    try {
      toast.loading("Salvando sua conta...");
      const response = await updateGoogleAdsAccount(
        projectId,
        selectedAccountId
      );

      if (response && response.success) {
        toast.dismiss();
        toast.success("Conta Google Ads integrada com sucesso!");
        onClose();
        window.location.reload();
      } else {
        toast.dismiss();
        toast.error(response?.message || "Erro ao integrar conta Google Ads");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Erro ao integrar conta: " + (error.message || "Erro desconhecido")
      );
      console.error("Erro ao selecionar conta:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="p-5 border-b">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900 flex items-center">
              <FaGoogle className="mr-2 text-blue-500" />
              {authComplete
                ? "Selecionar Conta Google Ads"
                : "Integrar Google Ads"}
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="p-5">
          {!authComplete ? (
            <>
              <div className="mb-5">
                <div className="flex items-start mb-4">
                  <div className="flex-shrink-0 mt-0.5">
                    <FaInfoCircle className="h-5 w-5 text-blue-500" />
                  </div>
                  <div className="ml-3">
                    <h4 className="text-sm font-medium text-gray-900">
                      Selecione a Conta Correta
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">
                      Você deve selecionar a conta DE ANÚNCIOS que utiliza para
                      suas campanhas. Não selecione uma conta MCC se você não
                      realiza campanhas diretamente nela.
                    </p>
                  </div>
                </div>

                <div className="flex items-start mb-4">
                  <div className="flex-shrink-0 mt-0.5">
                    <FaExclamationTriangle className="h-5 w-5 text-yellow-500" />
                  </div>
                  <div className="ml-3">
                    <h4 className="text-sm font-medium text-gray-900">
                      Conta não aparece?
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">
                      Se sua conta não aparecer na lista, provavelmente o email
                      utilizado não possui permissões de acesso. Verifique se
                      você está usando o email correto e se possui as permissões
                      necessárias.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 flex justify-end">
                <button
                  onClick={onClose}
                  className="mr-3 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleIntegrateAccount}
                  disabled={loading}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
                >
                  {loading ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Processando...
                    </>
                  ) : (
                    <>
                      <FaGoogle className="mr-2" />
                      Conectar com Google
                    </>
                  )}
                </button>
              </div>
            </>
          ) : (
            <div>
              {loadingAccounts ? (
                <div className="flex flex-col items-center justify-center py-6">
                  <FaSpinner className="animate-spin text-blue-500 text-xl mb-2" />
                  <p className="text-sm text-gray-500">
                    Buscando suas contas disponíveis...
                  </p>
                </div>
              ) : accountError ? (
                <div className="bg-red-50 p-4 rounded-md mb-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <FaExclamationTriangle className="h-5 w-5 text-red-400" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Erro ao buscar contas
                      </h3>
                      <p className="mt-2 text-sm text-red-700">
                        {accountError}
                      </p>
                    </div>
                  </div>
                </div>
              ) : showManualInput ? (
                <div className="space-y-4">
                  <div>
                    <label
                      htmlFor="accountId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      ID da Conta Google Ads
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="accountId"
                        value={manualInput}
                        onChange={(e) => {
                          const numbersOnly = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setManualInput(numbersOnly);
                        }}
                        className="shadow-sm block w-full py-3 px-4 text-base border-gray-300 rounded-md focus:ring-0 focus:border-blue-500 transition-colors duration-200 ease-in-out placeholder-gray-400"
                        placeholder="Digite apenas números"
                        style={{
                          fontWeight: manualInput ? "500" : "400",
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end space-x-3">
                    <button
                      onClick={() => setShowManualInput(false)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                    >
                      Voltar
                    </button>
                    <button
                      onClick={handleManualSubmit}
                      className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              ) : (
                <div className="space-y-4">
                  <button
                    onClick={() => {
                      setLoadingAccounts(true);
                      getGoogleAdsAccountsDetails(projectId)
                        .then((result) => {
                          if (result.success && result.accounts) {
                            setAccounts(result.accounts);
                            if (result.accounts.length === 1) {
                              handleSelectAccount(
                                result.accounts[0].customer?.id
                              );
                              handleConfirmSelection();
                            }
                          } else {
                            setAccountError(
                              result.message ||
                                "Não foi possível carregar as contas disponíveis"
                            );
                          }
                        })
                        .catch((error) => {
                          setAccountError("Erro ao buscar contas de anúncios");
                        })
                        .finally(() => {
                          setLoadingAccounts(false);
                        });
                    }}
                    className="w-full flex items-center justify-center px-4 py-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <FaSearch className="mr-2" />
                    Buscar automaticamente
                  </button>
                  <button
                    onClick={() => setShowManualInput(true)}
                    className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <FaKeyboard className="mr-2" />
                    Digitar manualmente
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const EditGoogleAdsAccountModal = ({
  isOpen,
  onClose,
  projectId,
  currentAccounts = [],
}) => {
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountError, setAccountError] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [manualInput, setManualInput] = useState("");
  const [showManualInput, setShowManualInput] = useState(false);
  const [showAccountsList, setShowAccountsList] = useState(false);

  const handleManualSubmit = async () => {
    if (!manualInput.trim()) {
      toast.error("Por favor, insira um ID de conta válido");
      return;
    }

    if (!/^\d+$/.test(manualInput.trim())) {
      toast.error("O ID da conta deve conter apenas números");
      return;
    }

    try {
      toast.loading("Atualizando conta...");
      const response = await updateGoogleAdsAccount(
        projectId,
        manualInput.trim()
      );

      if (response && response.success) {
        toast.dismiss();
        toast.success("Conta Google Ads atualizada com sucesso!");
        onClose(true);
      } else {
        toast.dismiss();
        toast.error(response?.message || "Erro ao atualizar conta Google Ads");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Erro ao atualizar conta: " + (error.message || "Erro desconhecido")
      );
      console.error("Erro ao selecionar conta:", error);
    }
  };

  const handleAutomaticSearch = async () => {
    setLoadingAccounts(true);
    setAccountError(null);

    try {
      const result = await getGoogleAdsAccountsDetails(projectId);
      if (result.success && result.accounts) {
        setAccounts(result.accounts);
        setShowAccountsList(true);
      } else {
        setAccountError(
          result.message || "Não foi possível carregar as contas disponíveis"
        );
      }
    } catch (error) {
      console.error("Erro ao buscar contas:", error);
      setAccountError("Erro ao buscar contas de anúncios");
    } finally {
      setLoadingAccounts(false);
    }
  };

  const handleSelectAccount = async (accountId) => {
    try {
      toast.loading("Atualizando conta...");
      const response = await updateGoogleAdsAccount(projectId, accountId);

      if (response && response.success) {
        toast.dismiss();
        toast.success("Conta Google Ads atualizada com sucesso!");
        onClose(true);
      } else {
        toast.dismiss();
        toast.error(response?.message || "Erro ao atualizar conta Google Ads");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Erro ao atualizar conta: " + (error.message || "Erro desconhecido")
      );
    }
  };

  const getAccountStatus = (account) => {
    const accountId = account.customer?.id;
    return currentAccounts.includes(accountId.toString()) ? "current" : "";
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="p-5 border-b">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900 flex items-center">
              <FaGoogle className="mr-2 text-blue-500" />
              Atualizar Conta Google Ads
            </h3>
            <button
              onClick={() => onClose(false)}
              className="text-gray-400 hover:text-gray-500"
            >
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="p-5">
          {loadingAccounts ? (
            <div className="flex flex-col items-center justify-center py-6">
              <FaSpinner className="animate-spin text-blue-500 text-xl mb-2" />
              <p className="text-sm text-gray-500">
                Buscando suas contas disponíveis...
              </p>
            </div>
          ) : accountError ? (
            <div className="bg-red-50 p-4 rounded-md mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <FaExclamationTriangle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Erro ao buscar contas
                  </h3>
                  <p className="mt-2 text-sm text-red-700">{accountError}</p>
                </div>
              </div>
            </div>
          ) : showManualInput ? (
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="accountId"
                  className="block text-sm font-medium text-gray-700"
                >
                  ID da Conta Google Ads
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="accountId"
                    value={manualInput}
                    onChange={(e) => {
                      const numbersOnly = e.target.value.replace(/[^0-9]/g, "");
                      setManualInput(numbersOnly);
                    }}
                    className="shadow-sm block w-full py-3 px-4 text-base border-gray-300 rounded-md focus:ring-0 focus:border-blue-500 transition-colors duration-200 ease-in-out placeholder-gray-400"
                    placeholder="Digite apenas números"
                    style={{
                      fontWeight: manualInput ? "500" : "400",
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowManualInput(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                >
                  Voltar
                </button>
                <button
                  onClick={handleManualSubmit}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Salvar
                </button>
              </div>
            </div>
          ) : showAccountsList ? (
            <div className="space-y-4">
              <div className="max-h-72 overflow-y-auto border rounded-md">
                {accounts.map((account) => {
                  const accountId = account.customer?.id;
                  const accountStatus = getAccountStatus(account);
                  return (
                    <div
                      key={accountId}
                      onClick={() => handleSelectAccount(accountId)}
                      className={`p-4 border-b last:border-b-0 cursor-pointer transition-colors ${
                        accountStatus === "current"
                          ? "bg-green-50"
                          : "hover:bg-gray-50"
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-1">
                          <div className="flex items-center">
                            {accountStatus === "current" ? (
                              <FaCheckCircle className="text-green-500 mr-2 flex-shrink-0" />
                            ) : (
                              <FaCircle
                                className="text-gray-300 mr-2 flex-shrink-0"
                                size={14}
                              />
                            )}
                            <p className="font-medium text-gray-900">
                              {account.customer?.descriptiveName ||
                                `Conta ${accountId}`}
                              {accountStatus === "current" && (
                                <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                                  Atual
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="ml-6">
                            <p className="text-sm text-gray-500">
                              ID: {accountId}
                            </p>
                            <p
                              className={`text-xs ${
                                account.customer?.status === "ENABLED"
                                  ? "text-green-600"
                                  : "text-red-600"
                              }`}
                            >
                              {account.customer?.status === "ENABLED"
                                ? "Conta ativa"
                                : "Conta inativa"}
                            </p>
                          </div>
                        </div>
                        <FaAngleRight className="text-gray-400" />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => {
                    setShowAccountsList(false);
                    setAccounts([]);
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                >
                  Voltar
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <button
                onClick={handleAutomaticSearch}
                className="w-full flex items-center justify-center px-4 py-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <FaSearch className="mr-2" />
                Buscar automaticamente
              </button>
              <button
                onClick={() => setShowManualInput(true)}
                className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <FaKeyboard className="mr-2" />
                Digitar manualmente
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const IntegrateAccount = ({ projectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
      >
        <FaGoogle className="mr-2" /> Integrar sua conta Google Ads
      </button>
      <p className="text-gray-500 text-sm mt-2">
        Conecte sua conta do Google Ads para gerenciar suas campanhas
      </p>

      <IntegrateAccountModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projectId={projectId}
      />
    </div>
  );
};

export default IntegrateAccount;
