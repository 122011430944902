// MenuItems.jsx
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaBullhorn,
  FaChevronDown,
  FaChevronUp,
  FaPen,
  FaGoogle,
  FaLock,
  FaTools,
  FaUserShield,
  FaUsers,
  FaKey,
  FaTachometerAlt,
  FaClipboardList,
  FaChartBar,
  FaMicrochip,
  FaCalculator,
  FaTag,
  FaSignOutAlt,
} from "react-icons/fa";

const MenuItems = ({
  activeMenu,
  toggleMenu,
  isMinimized,
  isAdmin,
  hasGadsIntegration,
  userScopes = [],
  onCloseMenu,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hasGtmScope = userScopes.includes("gtm");

  const handleLinkClick = () => {
    if (onCloseMenu) {
      onCloseMenu();
    }
  };

  const handleLogout = async () => {
    try {
      // Limpar todos os dados do localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userData");

      // Redirecionar para a página de login
      navigate("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const menuItemClass = (isActive) =>
    `px-4 py-2.5 flex items-center gap-3 transition-colors ${
      isActive
        ? "bg-gray-100 text-gray-900 font-medium"
        : "text-gray-700 hover:bg-gray-50 hover:text-gray-900"
    }`;

  const submenuItemClass = (isActive) =>
    `px-4 py-2 flex items-center gap-2 transition-colors ${
      isActive
        ? "text-gray-900 font-medium bg-gray-50"
        : "text-gray-600 hover:text-gray-900 hover:bg-gray-50"
    }`;

  const iconClass = (isActive) =>
    `${isActive ? "text-blue-600" : "text-gray-400"} text-lg`;

  const chevronClass = "text-gray-400 text-sm";

  const lockedItemClass =
    "px-4 py-2 flex items-center gap-2 text-gray-400 cursor-not-allowed";

  return (
    <ul className="py-2">
      {/* Dashboard */}
      <li>
        <Link
          to="/dashboard"
          className={menuItemClass(location.pathname === "/dashboard")}
          onClick={handleLinkClick}
        >
          <FaTachometerAlt
            className={iconClass(location.pathname === "/dashboard")}
          />
          {!isMinimized && <span>Dashboard</span>}
        </Link>
      </li>

      {/* Campanhas */}
      <li>
        <button
          onClick={() => toggleMenu("campaigns")}
          className={`w-full ${menuItemClass(activeMenu === "campaigns")}`}
        >
          <FaBullhorn className={iconClass(activeMenu === "campaigns")} />
          {!isMinimized && (
            <>
              <span className="flex-1 text-left">Campanhas</span>
              {activeMenu === "campaigns" ? (
                <FaChevronUp className={chevronClass} />
              ) : (
                <FaChevronDown className={chevronClass} />
              )}
            </>
          )}
        </button>
        {activeMenu === "campaigns" && !isMinimized && (
          <ul className="pl-10 py-1 bg-gray-50">
            <li>
              <Link
                to="/campaigns"
                className={submenuItemClass(location.pathname === "/campaigns")}
                onClick={handleLinkClick}
              >
                Criação de Campanha
              </Link>
            </li>
            <li>
              <Link
                to="/campaigns-history"
                className={submenuItemClass(
                  location.pathname === "/campaigns-history"
                )}
                onClick={handleLinkClick}
              >
                Histórico de Campanhas
              </Link>
            </li>
          </ul>
        )}
      </li>

      {/* Criação de conteúdo */}
      <li>
        <button
          onClick={() => toggleMenu("contentCreation")}
          className={`w-full ${menuItemClass(
            activeMenu === "contentCreation"
          )}`}
        >
          <FaPen className={iconClass(activeMenu === "contentCreation")} />
          {!isMinimized && (
            <>
              <span className="flex-1 text-left">Criação de conteúdo</span>
              {activeMenu === "contentCreation" ? (
                <FaChevronUp className={chevronClass} />
              ) : (
                <FaChevronDown className={chevronClass} />
              )}
            </>
          )}
        </button>
        {activeMenu === "contentCreation" && !isMinimized && (
          <ul className="pl-10 py-1 bg-gray-50">
            <li>
              <Link
                to="/tools/keywordschecker"
                className={submenuItemClass(
                  location.pathname === "/tools/keywordschecker"
                )}
                onClick={handleLinkClick}
              >
                <FaKey
                  className={iconClass(
                    location.pathname === "/tools/keywordschecker"
                  )}
                />
                KeywordChecker
              </Link>
            </li>
            <li className={lockedItemClass}>
              <FaTools className="text-gray-300" />
              <span>KAuto Article</span>
              <FaLock className="ml-auto text-gray-300" size={12} />
            </li>
            <li className={lockedItemClass}>
              <FaTools className="text-gray-300" />
              <span>KAuto Keyword Planner</span>
              <FaLock className="ml-auto text-gray-300" size={12} />
            </li>
            <li className={lockedItemClass}>
              <FaTools className="text-gray-300" />
              <span>KAuto Policy Detector</span>
              <FaLock className="ml-auto text-gray-300" size={12} />
            </li>
          </ul>
        )}
      </li>

      {/* Google Ads API */}
      {hasGadsIntegration && (
        <li>
          <button
            onClick={() => toggleMenu("googleAdsAPI")}
            className={`w-full ${menuItemClass(activeMenu === "googleAdsAPI")}`}
          >
            <FaGoogle className={iconClass(activeMenu === "googleAdsAPI")} />
            {!isMinimized && (
              <>
                <span className="flex-1 text-left">Google ADS API</span>
                {activeMenu === "googleAdsAPI" ? (
                  <FaChevronUp className={chevronClass} />
                ) : (
                  <FaChevronDown className={chevronClass} />
                )}
              </>
            )}
          </button>
          {activeMenu === "googleAdsAPI" && !isMinimized && (
            <ul className="pl-10 py-1 bg-gray-50">
              <li>
                <Link
                  to="/google-ads-api/projects"
                  className={submenuItemClass(
                    location.pathname === "/google-ads-api/projects"
                  )}
                  onClick={handleLinkClick}
                >
                  Projetos
                </Link>
              </li>
            </ul>
          )}
        </li>
      )}

      {/* Ferramentas */}
      <li>
        <button
          onClick={() => toggleMenu("tools")}
          className={`w-full ${menuItemClass(activeMenu === "tools")}`}
        >
          <FaTools className={iconClass(activeMenu === "tools")} />
          {!isMinimized && (
            <>
              <span className="flex-1 text-left">Ferramentas</span>
              {activeMenu === "tools" ? (
                <FaChevronUp className={chevronClass} />
              ) : (
                <FaChevronDown className={chevronClass} />
              )}
            </>
          )}
        </button>
        {activeMenu === "tools" && !isMinimized && (
          <ul className="pl-10 py-1 bg-gray-50">
            {hasGtmScope && (
              <li>
                <Link
                  to="/tools/create-conversion"
                  className={submenuItemClass(
                    location.pathname === "/tools/create-conversion" ||
                      location.pathname === "/tools/create-button-conversion"
                  )}
                  onClick={handleLinkClick}
                >
                  <FaTag
                    className={iconClass(
                      location.pathname === "/tools/create-conversion" ||
                        location.pathname === "/tools/create-button-conversion"
                    )}
                  />
                  Criar Conversão/Tag
                </Link>
              </li>
            )}
          </ul>
        )}
      </li>

      {/* Admin */}
      {isAdmin && (
        <li>
          <button
            onClick={() => toggleMenu("admin")}
            className={`w-full ${menuItemClass(activeMenu === "admin")}`}
          >
            <FaUserShield className={iconClass(activeMenu === "admin")} />
            {!isMinimized && (
              <>
                <span className="flex-1 text-left">Administrador</span>
                {activeMenu === "admin" ? (
                  <FaChevronUp className={chevronClass} />
                ) : (
                  <FaChevronDown className={chevronClass} />
                )}
              </>
            )}
          </button>
          {activeMenu === "admin" && !isMinimized && (
            <ul className="pl-10 py-1 bg-gray-50">
              <li>
                <Link
                  to="/admin/users"
                  className={submenuItemClass(
                    location.pathname === "/admin/users"
                  )}
                  onClick={handleLinkClick}
                >
                  <FaUsers
                    className={iconClass(location.pathname === "/admin/users")}
                  />
                  Usuários
                </Link>
              </li>
              <li>
                <Link
                  to="/system-logs"
                  className={submenuItemClass(
                    location.pathname === "/system-logs"
                  )}
                  onClick={handleLinkClick}
                >
                  <FaClipboardList
                    className={iconClass(location.pathname === "/system-logs")}
                  />
                  Logs do Sistema
                </Link>
              </li>
              <li>
                <Link
                  to="/sales-report"
                  className={submenuItemClass(
                    location.pathname === "/sales-report"
                  )}
                  onClick={handleLinkClick}
                >
                  <FaChartBar
                    className={iconClass(location.pathname === "/sales-report")}
                  />
                  Relatório de Vendas
                </Link>
              </li>
              <li>
                <Link
                  to="/workers-monitor"
                  className={submenuItemClass(
                    location.pathname === "/workers-monitor"
                  )}
                  onClick={handleLinkClick}
                >
                  <FaMicrochip
                    className={iconClass(
                      location.pathname === "/workers-monitor"
                    )}
                  />
                  Workers Monitor
                </Link>
              </li>
            </ul>
          )}
        </li>
      )}
    </ul>
  );
};

export default MenuItems;
