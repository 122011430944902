import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import * as api from "../utils/api";
import { TrackingTemplateConsentModal } from "./consent-modals";

const TrackingTemplateWarning = ({ projectId }) => {
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState(null);
  const [customTemplate, setCustomTemplate] = useState("");

  const DEFAULT_TEMPLATE =
    "{lpurl}?utm_source=googleads&utm_medium=display&utm_campaign={campaignId}&utm_term={campaignId}_{keyword}&utm_content={campaignId}_{placement}";

  const TEMPLATES = {
    campaign: DEFAULT_TEMPLATE,
    custom: customTemplate || DEFAULT_TEMPLATE,
  };

  // Descrições dos templates para exibir no modal
  const TEMPLATE_DESCRIPTIONS = {
    campaign: (
      <div>
        <p>
          Este template rastreia no nível de campanha, adicionando os seguintes
          parâmetros UTM às URLs:
        </p>
        <ul className="list-disc list-inside mt-2 space-y-1">
          <li>
            <strong>utm_source=googleads</strong> - Identifica o Google Ads como
            fonte de tráfego
          </li>
          <li>
            <strong>utm_medium=display</strong> - Identifica o meio como display
          </li>
          <li>
            <strong>utm_campaign={"{campaignId}"}</strong> - Rastreia o ID da
            campanha específica
          </li>
          <li>
            <strong>utm_term={"{campaignId}_{keyword}"}</strong> - Rastreia a
            palavra-chave com prefixo de ID da campanha
          </li>
          <li>
            <strong>utm_content={"{campaignId}_{placement}"}</strong> - Rastreia
            o posicionamento com prefixo de ID da campanha
          </li>
        </ul>
        <p className="mt-2">
          Este template permite rastreamento preciso e atribuição de conversões
          por campanha.
        </p>
      </div>
    ),
    custom: (
      <div>
        <p>Você está prestes a aplicar o seguinte template personalizado:</p>
        <pre className="bg-gray-100 p-2 mt-2 rounded text-xs font-mono overflow-x-auto whitespace-pre-wrap break-words">
          {customTemplate || DEFAULT_TEMPLATE}
        </pre>
        <p className="mt-2">
          Certifique-se de que seu template contém pelo menos o parâmetro{" "}
          {"{{lpurl}}"} para garantir redirecionamento adequado.
        </p>
      </div>
    ),
  };

  // Nomes amigáveis dos templates
  const TEMPLATE_NAMES = {
    campaign: "Template de Rastreamento por Campanha",
    custom: "Template de Rastreamento Personalizado",
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await api.getTrackingUrlTemplate(projectId);
        if (response.success && response.data.trackingUrlTemplate) {
          const fetchedTemplate = response.data.trackingUrlTemplate;
          setTemplate(fetchedTemplate);

          // Se o template não corresponder ao padrão, configurá-lo como personalizado
          if (fetchedTemplate !== DEFAULT_TEMPLATE) {
            setCustomTemplate(fetchedTemplate);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar template:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplate();
  }, [projectId]);

  const getTemplateType = (template) => {
    if (!template) return null;
    if (template === DEFAULT_TEMPLATE) return "campaign";
    return "custom";
  };

  const handleTemplatePrepare = (type) => {
    // Se selecionar custom, não abre modal imediatamente
    if (type === "custom") {
      setSelectedTemplateType("custom");
      // Inicializa o template personalizado com o padrão se estiver vazio
      if (!customTemplate) {
        setCustomTemplate(DEFAULT_TEMPLATE);
      }
      return;
    }

    // Em vez de aplicar imediatamente, armazenamos o tipo selecionado e abrimos o modal
    if (type && type !== getTemplateType(template)) {
      setSelectedTemplateType(type);
      setIsModalOpen(true);
    }
  };

  const handleTemplateChange = async () => {
    if (!selectedTemplateType) return;

    try {
      setIsLoading(true);
      const newTemplate = TEMPLATES[selectedTemplateType];
      const response = await api.setTrackingUrlTemplate(projectId, newTemplate);

      if (response.success) {
        setTemplate(newTemplate);
        toast.success("Template de URL atualizado com sucesso!");
      } else {
        throw new Error(response.message || "Erro ao atualizar template");
      }
    } catch (error) {
      toast.error(error.message || "Erro ao atualizar template");
    } finally {
      setIsLoading(false);
      // Fechar o modal após a conclusão
      setIsModalOpen(false);
    }
  };

  // Função para aplicar o template personalizado
  const handleApplyCustomTemplate = () => {
    if (!customTemplate || customTemplate.trim() === "") {
      toast.error("O template personalizado não pode estar vazio");
      return;
    }

    if (!customTemplate.includes("{lpurl}")) {
      toast.error("O template deve conter o parâmetro {lpurl}");
      return;
    }

    // Abrir o modal de confirmação
    setIsModalOpen(true);
  };

  // Função para cancelar a aplicação do template
  const handleCancelTemplate = () => {
    setSelectedTemplateType(null);
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <div className="text-gray-500">Carregando template...</div>;
  }

  const currentType = getTemplateType(template);

  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <span
            className={`w-3 h-3 rounded-full ${
              currentType ? "bg-green-500" : "bg-yellow-500"
            }`}
          ></span>
          <span className="text-sm font-medium">
            {currentType ? "Template configurado" : "Template não configurado"}
          </span>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Selecione o tipo de template:
          </label>
          <select
            value={currentType || ""}
            onChange={(e) => handleTemplatePrepare(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg text-sm bg-white"
            disabled={isLoading}
          >
            <option value="">Selecione um template</option>
            <option value="campaign">Template de Campanha (Recomendado)</option>
            <option value="custom">Template Personalizado</option>
          </select>
          <p className="mt-1 text-xs text-gray-500">
            Selecionar um template modificará as configurações de rastreamento
            da sua conta no Google Ads.
          </p>
        </div>

        {selectedTemplateType === "custom" && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Edite seu template personalizado:
            </label>
            <textarea
              value={customTemplate}
              onChange={(e) => setCustomTemplate(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg text-sm font-mono bg-white min-h-[80px]"
              placeholder={DEFAULT_TEMPLATE}
              disabled={isLoading}
            />
            <p className="text-xs text-gray-500 mt-1">
              O parâmetro {"{lpurl}"} é obrigatório. Você pode usar{" "}
              {"{campaignId}"}, {"{adGroupId}"}, {"{keyword}"}, {"{placement}"},
              etc.
            </p>
            <button
              onClick={handleApplyCustomTemplate}
              className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-sm"
              disabled={isLoading}
            >
              Aplicar Template Personalizado
            </button>
          </div>
        )}

        {template && (
          <div className="mt-4">
            <p className="text-sm text-gray-700 font-medium mb-1">
              Template atual:
            </p>
            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200">
              <p className="text-xs text-gray-500 break-all font-mono">
                {template}
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Modal de Consentimento */}
      <TrackingTemplateConsentModal
        isOpen={isModalOpen}
        onClose={handleCancelTemplate}
        onConfirm={handleTemplateChange}
        templateName={
          selectedTemplateType ? TEMPLATE_NAMES[selectedTemplateType] : ""
        }
        templateDescription={
          selectedTemplateType
            ? TEMPLATE_DESCRIPTIONS[selectedTemplateType]
            : ""
        }
      />
    </>
  );
};

export default TrackingTemplateWarning;
