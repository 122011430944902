import React from "react";
import {
  FaSearch,
  FaLink,
  FaCheck,
  FaGlobeAmericas,
  FaGoogle,
  FaExternalLinkAlt,
} from "react-icons/fa";

const StepsGuide = ({ currentStep }) => {
  const steps = [
    {
      id: "project",
      icon: FaGlobeAmericas,
      title: "Selecionar Projeto",
      description: "Escolha um projeto que tenha GTM configurado",
      active: currentStep === "project",
      completed: currentStep !== "project",
    },
    {
      id: "url",
      icon: FaLink,
      title: "Informar a URL",
      description: "Informe a URL da página com os botões a serem rastreados",
      active: currentStep === "url",
      completed:
        currentStep === "tag" ||
        currentStep === "success" ||
        currentStep === "publishing" ||
        currentStep === "published",
    },
    {
      id: "tag",
      icon: FaGoogle,
      title: "Configurar Conversão",
      description: "Defina nome, verificação e opções da tag de conversão",
      active: currentStep === "tag",
      completed:
        currentStep === "success" ||
        currentStep === "publishing" ||
        currentStep === "published",
    },
    {
      id: "success",
      icon: FaCheck,
      title: "Publicar Alterações",
      description:
        "As conversões foram criadas e estão prontas para publicação",
      active: currentStep === "success" || currentStep === "publishing",
      completed: currentStep === "published",
    },
  ];

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm mb-6">
      <h2 className="text-lg font-medium mb-5">Como funciona:</h2>
      <div className="space-y-6">
        {steps.map((step, index) => (
          <div key={step.id} className="flex">
            <div className="flex-shrink-0">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  step.active
                    ? "bg-blue-500 text-white"
                    : step.completed
                    ? "bg-green-500 text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
              >
                {step.completed ? (
                  <FaCheck className="text-sm" />
                ) : (
                  <step.icon className="text-sm" />
                )}
              </div>
              {index < steps.length - 1 && (
                <div
                  className={`ml-4 w-0.5 h-6 ${
                    step.completed ? "bg-green-500" : "bg-gray-200"
                  }`}
                ></div>
              )}
            </div>
            <div className="ml-4">
              <h3
                className={`text-sm font-medium ${
                  step.active
                    ? "text-blue-700"
                    : step.completed
                    ? "text-green-700"
                    : "text-gray-500"
                }`}
              >
                {step.title}
              </h3>
              <p
                className={`text-xs mt-0.5 ${
                  step.active || step.completed
                    ? "text-gray-600"
                    : "text-gray-400"
                }`}
              >
                {step.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 pt-4 border-t border-gray-100">
        <div className="flex items-center text-xs text-blue-600">
          <FaExternalLinkAlt className="mr-2" />
          <span>
            O sistema criará tags no GTM e ações de conversão no Google Ads
            automaticamente
          </span>
        </div>
        <div className="flex items-center text-xs text-gray-500 mt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>
            Compatível apenas com containers GTM web. Conversões server-side
            (sGTM/stape) não são suportadas no momento.
          </span>
        </div>
      </div>
    </div>
  );
};

export default StepsGuide;
