import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaCheck } from "react-icons/fa";

const CollapsibleCard = ({
  title,
  icon: Icon,
  isIntegrated = false,
  defaultExpanded = false,
  statusText,
  children,
}) => {
  // Cada componente tem seu próprio estado de expansão
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  // Atualiza o estado expandido quando defaultExpanded muda
  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const toggleExpand = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
      {/* Cabeçalho do Card */}
      <div
        className={`bg-white px-4 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-50 transition-colors ${
          isIntegrated ? "border-l-4 border-green-500" : ""
        }`}
        onClick={toggleExpand}
      >
        <div className="flex items-center space-x-3">
          <div
            className={`p-2 rounded-full ${
              isIntegrated
                ? "bg-green-100 text-green-600"
                : "bg-blue-100 text-blue-600"
            }`}
          >
            <Icon className="text-lg" />
          </div>
          <div>
            <h3 className="font-medium text-gray-900">{title}</h3>
            {isIntegrated ? (
              <p className="text-sm text-green-600 flex items-center">
                <FaCheck size={12} className="mr-1" />
                {statusText || "Integração concluída"}
              </p>
            ) : (
              <p className="text-sm text-gray-500">
                {statusText || "Configure a integração"}
              </p>
            )}
          </div>
        </div>
        <div className="text-gray-400">
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>

      {/* Conteúdo Colapsável */}
      {isExpanded && (
        <div className="border-t border-gray-200 p-4 bg-white">{children}</div>
      )}
    </div>
  );
};

export default CollapsibleCard;
