// TextWithTooltip.jsx

import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css"; // Importe o CSS do react-tooltip

const TextWithTooltip = ({ text, link, slice }) => {
  const truncatedText =
    text.length > 30 && slice ? `${text.slice(0, 30)}...` : text;

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id={`textTooltip-${text}`}
          data-tooltip-content={text}
          className="cursor-pointer text-blue-500 hover:underline"
        >
          {truncatedText}
        </a>
      ) : (
        <span
          data-tooltip-id={`textTooltip-${text}`}
          data-tooltip-content={text}
          className="cursor-pointer"
        >
          {truncatedText}
        </span>
      )}
      <Tooltip
        id={`textTooltip-${text}`}
        place="right"
        effect="solid"
        offset={{ main: 10, cross: 10 }}
        style={{
          zIndex: 9999, // z-index alto para sobrepor outros elementos
          position: "absolute", // Posicionamento absoluto
        }}
      />
    </div>
  );
};

export default TextWithTooltip;
