import React from "react";
import { NumericFormat } from "react-number-format";

/**
 * Componente FormField - Campo de formulário especializado
 *
 * @param {Object} props
 * @param {string} props.label - Texto do rótulo do campo
 * @param {string} props.name - Nome do campo (identificador)
 * @param {string} props.type - Tipo de campo ('percentage', 'currency', 'text', 'select')
 * @param {any} props.value - Valor atual do campo
 * @param {Function} props.onChange - Função a ser chamada quando o valor mudar
 * @param {Function} props.onBlur - Função a ser chamada quando o campo perder o foco
 * @param {string} props.placeholder - Texto de placeholder
 * @param {string} props.helpText - Texto de ajuda opcional
 * @param {Array} props.options - Opções para select
 * @param {number} props.decimalScale - Número de casas decimais (para tipos numéricos)
 * @param {boolean} props.fixedDecimalScale - Se deve fixar o número de casas decimais
 * @param {boolean} props.disabled - Se o campo está desabilitado
 * @param {boolean} props.required - Se o campo é obrigatório
 * @param {string} props.className - Classes CSS adicionais
 * @param {Object} props.inputProps - Props adicionais para o input
 */
const FormField = ({
  label,
  name,
  type = "text",
  value,
  onChange,
  onBlur,
  placeholder = "",
  helpText,
  options = [],
  decimalScale = 2,
  fixedDecimalScale = true,
  disabled = false,
  required = false,
  className = "",
  inputProps = {},
}) => {
  // Determina o tipo de componente a ser renderizado com base no tipo
  const renderField = () => {
    switch (type) {
      case "percentage":
        return (
          <NumericFormat
            value={value}
            onValueChange={(values) => onChange(name, values.floatValue)}
            onBlur={() => onBlur(name, value)}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            suffix="%"
            placeholder={placeholder || "0%"}
            disabled={disabled}
            className={`border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm ${
              disabled ? "bg-gray-100" : ""
            }`}
            {...inputProps}
          />
        );

      case "currency":
        return (
          <NumericFormat
            value={value}
            onValueChange={(values) => onChange(name, values.floatValue)}
            onBlur={() => onBlur(name, value)}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            placeholder={placeholder || "R$ 0,00"}
            disabled={disabled}
            className={`border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm ${
              disabled ? "bg-gray-100" : ""
            }`}
            {...inputProps}
          />
        );

      case "select":
        return (
          <select
            name={name}
            value={value || ""}
            onChange={(e) => onChange(name, e.target.value)}
            onBlur={() => onBlur(name, value)}
            disabled={disabled}
            className={`border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm ${
              disabled ? "bg-gray-100" : ""
            }`}
            {...inputProps}
          >
            <option value="">{placeholder || "Selecione..."}</option>
            {options.map((option, index) => (
              <option key={`${option.value}_${index}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );

      case "number":
        return (
          <NumericFormat
            value={value}
            onValueChange={(values) => onChange(name, values.floatValue)}
            onBlur={() => onBlur(name, value)}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            placeholder={placeholder || "0"}
            disabled={disabled}
            className={`border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm ${
              disabled ? "bg-gray-100" : ""
            }`}
            {...inputProps}
          />
        );

      case "text":
      default:
        return (
          <input
            type="text"
            name={name}
            value={value || ""}
            onChange={(e) => onChange(name, e.target.value)}
            onBlur={() => onBlur(name, value)}
            placeholder={placeholder}
            disabled={disabled}
            className={`border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm ${
              disabled ? "bg-gray-100" : ""
            }`}
            {...inputProps}
          />
        );
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <label className="text-gray-600 mb-1 text-sm">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      {renderField()}
      {helpText && <p className="mt-1 text-xs text-gray-500">{helpText}</p>}
    </div>
  );
};

export default FormField;
