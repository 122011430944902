import React from "react";
import CountdownPromo from "./CountdownPromo";

const PlanDashboard = ({ plan, usage, projects, userId }) => {
  if (!plan?.plan) return null;

  const isFree = plan.plan.name.toLowerCase() === "free";
  const endDate = isFree
    ? "Vitalício"
    : new Date(plan.plan.endDate).toLocaleDateString();

  // Filtra apenas os projetos onde o usuário é proprietário
  const ownedProjects =
    projects?.filter((project) => project.accountId === userId) || [];

  const planFeatures = [
    {
      title: "Projetos",
      value: `${ownedProjects.length || 0} / ${
        plan.plan.projects_available || 0
      }`,
      percentage: Math.round(
        ((ownedProjects.length || 0) / (plan.plan.projects_available || 1)) *
          100
      ),
    },
    {
      title: "Grupos de Anúncios",
      value: `${usage?.totalAdGroups?.totalAdGroups || 0} / ${
        plan.plan.googleads?.gads_groups_available || 0
      }`,
      percentage: Math.round(
        ((usage?.totalAdGroups?.totalAdGroups || 0) /
          (plan.plan.googleads?.gads_groups_available || 1)) *
          100
      ),
    },
  ];

  const automationFeatures = [
    { name: "Bot CPC", enabled: plan.plan.automations?.bot_cpc || false },
    {
      name: "Otimização Search",
      enabled: false,
      inDevelopment: true,
    },
    {
      name: "Otimização Display",
      enabled: false,
      inDevelopment: true,
    },
  ];

  const integrationFeatures = [
    {
      name: "Google Ads",
      enabled: plan.plan.googleads?.gads_integration || false,
    },
    {
      name: "Meta Ads",
      enabled: false,
      inDevelopment: true,
    },
    {
      name: "Pinterest Ads",
      enabled: false,
      inDevelopment: true,
    },
    {
      name: "Bing Ads",
      enabled: false,
      inDevelopment: true,
    },
  ];

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        {/* Cabeçalho do Plano */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-bold capitalize">{plan.plan.name}</h2>
            <p className="text-sm text-gray-500">{plan.plan.description}</p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-500">Validade</p>
            <p className="text-lg font-semibold">{endDate}</p>
          </div>
        </div>

        {/* Métricas Principais */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          {planFeatures.map((feature, index) => (
            <div key={index} className="p-4 border rounded-lg">
              <p className="text-sm text-gray-500">{feature.title}</p>
              <p className="text-xl font-bold">{feature.value}</p>
              {feature.percentage !== undefined && (
                <div className="mt-2">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className={`h-2 rounded-full ${
                        feature.percentage > 80
                          ? "bg-red-500"
                          : feature.percentage > 60
                          ? "bg-yellow-500"
                          : "bg-green-500"
                      }`}
                      style={{ width: `${feature.percentage}%` }}
                    />
                  </div>
                  <p className="text-xs text-gray-500 mt-1">
                    {feature.percentage}% utilizado
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Recursos do Plano */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Automações */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-3">
              Automações
            </h3>
            <div className="space-y-2">
              {automationFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <span
                    className={`w-2 h-2 rounded-full ${
                      feature.inDevelopment
                        ? "bg-yellow-500"
                        : feature.enabled
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } mr-2`}
                  />
                  <span
                    className={`text-sm ${
                      feature.inDevelopment
                        ? "text-yellow-700"
                        : feature.enabled
                        ? "text-gray-700"
                        : "text-gray-400"
                    }`}
                  >
                    {feature.name}
                    {feature.inDevelopment && (
                      <span className="ml-2 text-xs text-yellow-600">
                        (Em desenvolvimento)
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Integrações */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-3">
              Integrações
            </h3>
            <div className="space-y-2">
              {integrationFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <span
                    className={`w-2 h-2 rounded-full ${
                      feature.inDevelopment
                        ? "bg-yellow-500"
                        : feature.enabled
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } mr-2`}
                  />
                  <span
                    className={`text-sm ${
                      feature.inDevelopment
                        ? "text-yellow-700"
                        : feature.enabled
                        ? "text-gray-700"
                        : "text-gray-400"
                    }`}
                  >
                    {feature.name}
                    {feature.inDevelopment && (
                      <span className="ml-2 text-xs text-yellow-600">
                        (Em desenvolvimento)
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Usando o componente separado */}
      {isFree && <CountdownPromo />}
    </>
  );
};

export default PlanDashboard;
