import React from "react";
import { FaSpinner } from "react-icons/fa";

const CreateProjectModal = ({
  isOpen,
  onClose,
  projectName,
  projectAbbreviation,
  projectType,
  nameError,
  abbreviationError,
  loadingCreate,
  onProjectNameChange,
  onProjectAbbreviationChange,
  onProjectTypeChange,
  onCreateProject,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Novo Projeto</h2>
        </div>

        <div className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nome do Projeto
              </label>
              <input
                type="text"
                value={projectName}
                onChange={(e) => onProjectNameChange(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Nome do Projeto"
              />
              {nameError && (
                <p className="mt-1 text-xs text-red-500">{nameError}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Sigla do Projeto
              </label>
              <input
                type="text"
                value={projectAbbreviation}
                onChange={(e) => onProjectAbbreviationChange(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Sigla do Projeto"
              />
              {abbreviationError && (
                <p className="mt-1 text-xs text-red-500">{abbreviationError}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tipo do Projeto
              </label>
              <select
                value={projectType}
                onChange={(e) => onProjectTypeChange(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                disabled
              >
                <option value="googleads/admanager">
                  Arbitragem - AdManager/Google Ads
                </option>
              </select>
            </div>

            <p className="text-xs text-gray-500 mt-4">
              Atenção: Todos os projetos que utilizam AdManager devem
              obrigatoriamente incluir parâmetros UTM. Esses parâmetros devem
              estar presentes no link final dos anúncios na plataforma
              selecionada.
            </p>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              Cancelar
            </button>
            <button
              onClick={onCreateProject}
              disabled={loadingCreate || nameError || abbreviationError}
              className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:bg-blue-300 flex items-center gap-2"
            >
              {loadingCreate && <FaSpinner className="animate-spin" />}
              Criar Projeto
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectModal;
