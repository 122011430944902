import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { saveGoogleAdsTokens } from "../utils/api";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const CompleteIntegration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    // Recupera os dados do localStorage
    const integrationData = JSON.parse(
      localStorage.getItem("googleAdsIntegration")
    );

    if (code && integrationData) {
      handleSaveTokens(code, integrationData.projectId);
    } else {
      toast.error("Dados de integração não encontrados.");
    }
  }, []);

  const handleSaveTokens = async (code, projectId) => {
    try {
      // Recupera o token JWT e decodifica para obter o ID da conta
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const accountId = decodedToken.id;

      // Envia o código e os dados para salvar os tokens
      const response = await saveGoogleAdsTokens(code, projectId, accountId);
      if (response.success) {
        toast.success("Conta Google Ads integrada com sucesso!");

        // Redireciona para a página do projeto com o parâmetro de autenticação bem-sucedida
        navigate(`/project/${projectId}?googleads_auth=success`);
      } else {
        toast.error("Falha ao integrar a conta Google Ads.");
        navigate(`/project/${projectId}`);
      }
    } catch (error) {
      console.error("Erro ao salvar tokens:", error);
      toast.error("Ocorreu um erro ao tentar salvar os tokens.");
      navigate(`/project/${projectId}`);
    } finally {
      removeUrlParams(); // Remove os parâmetros da URL após a verificação
      localStorage.removeItem("googleAdsIntegration"); // Limpa os dados temporários
    }
  };

  const removeUrlParams = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <Toaster position="top-right" />
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mb-4"></div>
          <h1 className="text-xl font-medium text-gray-900 mb-2">
            Processando integração
          </h1>
          <p className="text-gray-500 text-center">
            Estamos processando sua integração com o Google Ads. Por favor,
            aguarde um momento...
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompleteIntegration;
