import React, { useState, useEffect } from "react";
import {
  FaGoogle,
  FaSpinner,
  FaTrash,
  FaCheck,
  FaExclamationTriangle,
} from "react-icons/fa";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import { updateProjectMcc, removeProjectMcc } from "../../utils/api";

const ManageMcc = ({ projectId, existingMccId, projectAccountId }) => {
  const [mccId, setMccId] = useState("");
  const [loading, setLoading] = useState(false);
  const [removingMcc, setRemovingMcc] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [showMccInput, setShowMccInput] = useState(false);

  // Verifica se o usuário atual é o dono do projeto
  useEffect(() => {
    const checkOwner = () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;

        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;

        setIsOwner(userId === projectAccountId);
      } catch (error) {
        console.error("Erro ao verificar proprietário:", error);
      }
    };

    checkOwner();
  }, [projectAccountId]);

  // Formatar entrada para conter apenas números
  const handleMccChange = (e) => {
    const value = e.target.value;
    const numbersOnly = value.replace(/\D/g, ""); // Remove tudo que não é número
    setMccId(numbersOnly);
  };

  const handleUpdateMcc = async () => {
    if (!mccId) {
      toast.error("Digite um MCC ID válido");
      return;
    }

    setLoading(true);
    try {
      const response = await updateProjectMcc(projectId, mccId);
      if (response.success) {
        toast.success("MCC atualizado com sucesso!");
        window.location.reload(); // Recarrega a página para atualizar os dados
        setMccId(""); // Limpa o input
      } else {
        throw new Error(response.error?.message || "Erro ao atualizar MCC");
      }
    } catch (error) {
      console.error("Erro ao atualizar MCC:", error);
      toast.error(error.message || "Erro ao atualizar MCC");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMcc = async () => {
    if (!existingMccId) {
      toast.error("Projeto não possui MCC");
      return;
    }

    if (
      !window.confirm("Tem certeza que deseja remover o MCC deste projeto?")
    ) {
      return;
    }

    setRemovingMcc(true);
    try {
      const response = await removeProjectMcc(projectId);
      if (response.success) {
        toast.success("MCC removido com sucesso!");
        window.location.reload(); // Recarrega a página para atualizar os dados
      } else {
        throw new Error(response.error?.message || "Erro ao remover MCC");
      }
    } catch (error) {
      console.error("Erro ao remover MCC:", error);
      toast.error(error.message || "Erro ao remover MCC");
    } finally {
      setRemovingMcc(false);
    }
  };

  // Toggle para mostrar/esconder o campo de input do MCC
  const toggleMccInput = () => {
    setShowMccInput(!showMccInput);
    if (!showMccInput) {
      setMccId(""); // Limpa o input quando exibido
    }
  };

  // Se não for o proprietário, não exibe o componente
  if (!isOwner) {
    return null;
  }

  return (
    <div className="space-y-4">
      {existingMccId ? (
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              <span className="text-green-700">MCC configurado</span>
            </div>
            <div className="flex gap-2">
              <button
                onClick={handleRemoveMcc}
                disabled={removingMcc}
                className={`text-sm text-red-600 hover:underline flex items-center gap-1 ${
                  removingMcc ? "opacity-50" : ""
                }`}
              >
                {removingMcc ? (
                  <>
                    <FaSpinner className="animate-spin" size={12} />
                    <span>Removendo...</span>
                  </>
                ) : (
                  <>
                    <FaTrash size={12} />
                    <span>Remover</span>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="bg-gray-50 p-3 rounded-md">
            <p className="text-sm mb-1">
              <strong>MCC ID:</strong> {existingMccId}
            </p>
          </div>
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <label
                htmlFor="mcc-switch"
                className="text-sm font-medium text-gray-700"
              >
                Adicionar ID MCC
              </label>
              <div className="relative inline-block w-10 align-middle select-none">
                <input
                  type="checkbox"
                  id="mcc-switch"
                  checked={showMccInput}
                  onChange={toggleMccInput}
                  className="opacity-0 absolute h-0 w-0"
                />
                <label
                  htmlFor="mcc-switch"
                  className={`block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out ${
                    showMccInput ? "bg-blue-600" : "bg-gray-300"
                  }`}
                  style={{ width: "2.5rem" }}
                >
                  <span
                    className={`block h-5 w-5 ml-0.5 mt-0.5 rounded-full bg-white shadow transform transition-transform duration-200 ease-in-out ${
                      showMccInput ? "translate-x-4" : "translate-x-0"
                    }`}
                  ></span>
                </label>
              </div>
            </div>
          </div>

          {showMccInput && (
            <div className="mt-3 space-y-3">
              <div>
                <label
                  htmlFor="mcc-id"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Google Ads MCC ID
                </label>
                <input
                  id="mcc-id"
                  type="text"
                  className="w-full border border-gray-300 rounded-md p-2 text-sm"
                  value={mccId}
                  onChange={handleMccChange}
                  placeholder="Digite o ID do MCC (apenas números)"
                  disabled={loading}
                />
                <p className="text-xs text-gray-500 mt-1">
                  O MCC (My Client Center) permite gerenciar múltiplas contas do
                  Google Ads.
                </p>
              </div>

              <div className="flex justify-end pt-2">
                <button
                  onClick={handleUpdateMcc}
                  disabled={loading || !mccId}
                  className={`flex items-center gap-2 px-4 py-2 rounded-md ${
                    loading || !mccId
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700 text-white"
                  }`}
                >
                  {loading ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    <FaGoogle />
                  )}
                  <span>Atualizar MCC</span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ManageMcc;
