import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  FaAd,
  FaCopy,
  FaTrash,
  FaSpinner,
  FaExclamationTriangle,
  FaExternalLinkAlt,
} from "react-icons/fa";
import {
  updateAdManagerAccount,
  addOrUpdateDomainInProject,
  getDomainInProject,
  resetGoogleAdManagerFields,
} from "../../utils/api";

const AddToAdManager = ({ project }) => {
  const [gamId, setGamId] = useState("");
  const [domain, setDomain] = useState("");
  const [isDomainSet, setIsDomainSet] = useState(false);
  const [isGamIntegrated, setIsGamIntegrated] = useState(false);
  const [integrationType, setIntegrationType] = useState(""); // "Manual" ou "Automática"
  const [isRemoving, setIsRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [authError, setAuthError] = useState(null);

  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;
  const gamIdRegex = /^\d+$/;

  useEffect(() => {
    if (project && project.admanagerId) {
      setGamId(project.admanagerId);
      setIsGamIntegrated(true);
    } else {
      setIsGamIntegrated(false);
    }

    const checkDomain = async () => {
      try {
        const existingDomain = await getDomainInProject(project.id);
        if (existingDomain) {
          setDomain(existingDomain);
          setIsDomainSet(true);
        }
      } catch (error) {
        console.error("Erro ao verificar o domínio:", error);
        setAuthError(
          "Erro ao verificar o domínio. Tente novamente mais tarde."
        );
      }
    };

    checkDomain();
  }, [project]);

  const handleSave = async () => {
    if (integrationType === "Manual") {
      if (!gamIdRegex.test(gamId)) {
        toast.error("O ID do Google Ad Manager deve conter apenas números.");
        return;
      }

      if (!domainRegex.test(domain)) {
        toast.error("Por favor, insira um domínio válido. Ex: seudominio.com");
        return;
      }

      try {
        setLoading(true);
        const response = await updateAdManagerAccount(project.id, gamId);
        // Verifica se o admanagerId foi atualizado na resposta
        if (response.admanagerId === gamId) {
          const domainResponse = await addOrUpdateDomainInProject(
            project.id,
            domain
          );
          if (domainResponse.domain === domain) {
            toast.success(
              "Google Ad Manager e domínio vinculados com sucesso!"
            );
            setIsGamIntegrated(true);
            setAuthError(null);

            // Recarregar a página para atualizar os componentes pai
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            toast.error("Erro ao vincular o domínio");
          }
        } else {
          toast.error("Erro ao vincular o Google Ad Manager");
        }
      } catch (error) {
        console.error("Erro ao vincular o Google Ad Manager:", error);
        toast.error(
          "Erro ao vincular o Google Ad Manager: " +
            (error.message || "Erro desconhecido")
        );
        setAuthError(
          "Falha na integração. Verifique os dados e tente novamente."
        );
      } finally {
        setLoading(false);
      }
    } else if (integrationType === "Automática") {
      window.location.href = "/oauth/google-admanager";
    } else {
      toast.error("Por favor, selecione um tipo de integração.");
    }
  };

  const handleRemoveIntegration = async () => {
    setIsRemoving(true);

    try {
      // Usar a nova função para resetar os campos do Google Ad Manager
      const result = await resetGoogleAdManagerFields(project.id);

      // Verificar se o projeto retornado não tem mais admanagerId
      if (result && !result.admanagerId) {
        toast.success("Integração com Google Ad Manager removida com sucesso!");
        setIsGamIntegrated(false);
        setGamId("");
        setAuthError(null);

        // Recarrega a página para atualizar o estado completo
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error("Erro ao remover integração do Google Ad Manager");
      }
    } catch (error) {
      console.error("Erro ao remover integração:", error);
      toast.error(
        "Erro ao remover integração: " + (error.message || "Erro desconhecido")
      );
    } finally {
      setIsRemoving(false);
      setShowConfirmation(false);
    }
  };

  const handleCopyServiceAccount = () => {
    navigator.clipboard.writeText(
      "adpilott@adpilot-423403.iam.gserviceaccount.com"
    );
    toast.success("Conta de Serviço copiada com sucesso!");
  };

  // Renderizar mensagens de erro
  const renderAuthMessages = () => {
    if (!authError) return null;

    return (
      <div className="mb-4">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md flex items-start">
          <FaExclamationTriangle className="text-red-500 mt-1 mr-2 flex-shrink-0" />
          <p className="text-sm">{authError}</p>
        </div>
      </div>
    );
  };

  // Renderizar o indicador de carregamento global
  const renderGlobalLoadingIndicator = () => {
    if (!loading) return null;

    return (
      <div className="flex items-center justify-center py-2 mb-3 bg-blue-50 rounded-md">
        <FaSpinner className="animate-spin text-blue-500 mr-2" />
        <span className="text-sm text-blue-700">
          Processando solicitação...
        </span>
      </div>
    );
  };

  return (
    <div>
      {renderAuthMessages()}
      {renderGlobalLoadingIndicator()}

      {isGamIntegrated ? (
        <div>
          <div className="bg-gray-50 p-4 rounded-md mb-4">
            <p className="text-sm mb-1">
              <strong>ID da Conta:</strong> {gamId}
            </p>
            {domain && (
              <p className="text-sm">
                <strong>Domínio:</strong> {domain}
              </p>
            )}
          </div>

          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setIsGamIntegrated(false)}
              disabled={isRemoving}
              className={`text-sm text-blue-600 hover:underline py-2 px-3 rounded border border-transparent hover:border-blue-200 flex items-center ${
                isRemoving ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Alterar
            </button>
            <button
              onClick={() => setShowConfirmation(true)}
              disabled={isRemoving}
              className={`text-sm text-red-600 hover:bg-red-50 py-2 px-3 rounded border border-transparent hover:border-red-200 flex items-center ${
                isRemoving ? "opacity-50" : ""
              }`}
            >
              {isRemoving ? (
                <>
                  <FaSpinner className="animate-spin mr-1" size={12} />
                  <span>Removendo...</span>
                </>
              ) : (
                <>
                  <FaTrash className="mr-1" size={12} />
                  <span>Remover</span>
                </>
              )}
            </button>
          </div>

          {showConfirmation && (
            <div className="border border-red-200 bg-red-50 p-4 rounded-md mt-4">
              <p className="text-red-700 text-sm mb-3">
                Tem certeza que deseja remover a integração com o Google Ad
                Manager? Esta ação não pode ser desfeita.
              </p>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowConfirmation(false)}
                  className="px-3 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 text-sm"
                  disabled={isRemoving}
                >
                  Cancelar
                </button>
                <button
                  onClick={handleRemoveIntegration}
                  className="px-3 py-2 bg-red-600 text-white rounded hover:bg-red-700 text-sm flex items-center"
                  disabled={isRemoving}
                >
                  {isRemoving ? (
                    <>
                      <FaSpinner className="animate-spin mr-1" />
                      Removendo...
                    </>
                  ) : (
                    <>
                      <FaTrash className="mr-1" />
                      Confirmar
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tipo de Integração
              </label>
              <select
                value={integrationType}
                onChange={(e) => setIntegrationType(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 text-sm"
                disabled={loading}
              >
                <option value="">Selecione</option>
                <option value="Manual">Manual</option>
                <option value="Automática" disabled>
                  Automática (Em breve)
                </option>
              </select>
            </div>

            {integrationType === "Manual" && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Conta de Serviço
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      readOnly
                      value="adpilott@adpilot-423403.iam.gserviceaccount.com"
                      className="border border-gray-300 p-2 rounded w-full bg-gray-50 text-sm"
                    />
                    <button
                      onClick={handleCopyServiceAccount}
                      className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                      title="Copiar conta de serviço"
                    >
                      <FaCopy />
                    </button>
                  </div>
                  <p className="text-xs text-gray-500 mt-1">
                    Adicione esta conta como administrador na sua conta do
                    Google Ad Manager
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    ID do Google Ad Manager
                  </label>
                  <input
                    type="text"
                    value={gamId}
                    onChange={(e) => setGamId(e.target.value)}
                    placeholder="Ex: 123456789"
                    className="w-full border border-gray-300 rounded-md p-2 text-sm"
                    disabled={loading}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Domínio do Site
                  </label>
                  <input
                    type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    placeholder="Ex: seusite.com"
                    className="w-full border border-gray-300 rounded-md p-2 text-sm"
                    disabled={loading}
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    Digite o domínio principal sem http:// ou www
                  </p>
                </div>
              </>
            )}
          </div>

          <div className="flex justify-end pt-4">
            <button
              onClick={handleSave}
              disabled={
                loading ||
                !integrationType ||
                (integrationType === "Manual" && (!gamId || !domain))
              }
              className={`flex items-center gap-2 px-4 py-2 rounded-md ${
                loading ||
                !integrationType ||
                (integrationType === "Manual" && (!gamId || !domain))
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700 text-white"
              }`}
            >
              {loading ? (
                <FaSpinner className="animate-spin" />
              ) : integrationType === "Automática" ? (
                <FaExternalLinkAlt />
              ) : (
                <FaAd />
              )}
              <span>
                {integrationType === "Automática"
                  ? "Conectar ao Google Ad Manager"
                  : "Integrar Google Ad Manager"}
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddToAdManager;
