// CampaignPerformanceTable.jsx

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  FaExternalLinkAlt,
  FaSearch,
  FaAngleDown,
  FaAngleUp,
  FaInfoCircle,
  FaSpinner,
  FaTimes,
  FaEdit,
  FaCheck,
  FaSyncAlt,
  FaEye,
  FaPause,
  FaPlay,
  FaSave,
  FaUndo,
  FaSort,
} from "react-icons/fa";
import {
  modifyAdGroupBid,
  getRealTimeEvents,
  toggleAutomaticCpcForCampaign,
} from "../utils/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import CpcEditor from "./CpcEditor";
import LastCpcAdjust from "./LastAdjustCpc";
import DisplayEventCount from "./analytics/DisplayEventCount";
import PreviewReport from "./performanceTable/previewsReports";
import CpcCampaignDataCell from "./performanceTable/cpcMaxAndMin";
import UtmDetailsModal from "./UtmDetailsModal";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import TextWithTooltip from "./TextWithTooltip";
import LabelDisplay from "./performanceTable/LabelDisplay";
import BulkActionsBar from "./BulkActionsBar";

dayjs.extend(utc);
dayjs.extend(timezone);

const CampaignPerformanceTable = ({
  reports: initialReports,
  singleCampaign = false,
  config,
  cpcBotEnabled = false,
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUtmModalOpen, setIsUtmModalOpen] = useState(false);
  const [selectedUtmData, setSelectedUtmData] = useState(null);
  const [editableCPC, setEditableCPC] = useState(null);
  const [editedCPCValue, setEditedCPCValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingAutoCpc, setLoadingAutoCpc] = useState(null);
  const [gaEventList, setGaEventList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [reports, setReports] = useState(initialReports);
  const [isHovered, setIsHovered] = useState(null);
  const [showUpdateNotice, setShowUpdateNotice] = useState(false);

  const projectId = reports.length > 0 ? reports[0].projectId : undefined;

  useEffect(() => {
    let intervalId;

    if (!singleCampaign && projectId) {
      const fetchGaEvents = async () => {
        try {
          const result = await getRealTimeEvents(projectId);
          if (result.success) {
            setGaEventList(result.ga4events || []);
          } else {
            console.error("Failed to fetch GA events:", result.error);
          }
        } catch (error) {
          console.error("Error fetching GA events:", error);
        }
      };

      fetchGaEvents();
      intervalId = setInterval(fetchGaEvents, 60000);
    }

    return () => clearInterval(intervalId);
  }, [singleCampaign, projectId]);

  const openModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCampaign(null);
  };

  const handleEditCPC = (campaignId, adGroupId, currentValue) => {
    setEditableCPC(campaignId);
    setEditedCPCValue(currentValue);
  };

  const handleSaveCPC = async (
    campaignId,
    adGroupId,
    projectId,
    biddingStrategyType,
    currentValue
  ) => {
    if (parseFloat(editedCPCValue) === parseFloat(currentValue)) {
      toast.error("O valor inserido é o mesmo que o atual.");
      return;
    }

    setIsSubmitting(true);

    const adjustType =
      biddingStrategyType === 10 ? "target_cpa_micros" : "cpc_bid_micros";

    const response = await modifyAdGroupBid({
      projectId,
      campaignId,
      adGroupId,
      adjustValue: parseFloat(editedCPCValue),
      adjustType,
    });

    if (response.success) {
      toast.success(response.message || "CPC atualizado com sucesso!");

      setEditableCPC(null);
    } else {
      toast.error(response.message || "Falha ao atualizar o CPC.");
    }

    setIsSubmitting(false);
  };

  // Função para calcular cpcMin e cpcMax com base nos dados e na configuração
  const calculateCpcValue = (campaign, type) => {
    // Sua lógica para calcular o cpcMin ou cpcMax
    // Exemplo simples:
    const baseCpc = parseFloat(campaign.currentBid) || 0;
    const adjustment = config?.cpcAdjustment || 0;

    if (type === "min") {
      return baseCpc - adjustment;
    } else if (type === "max") {
      return baseCpc + adjustment;
    }
    return baseCpc;
  };

  // Adicione esta função para manipular o clique no UTM
  const handleUtmClick = (campaignData) => {
    setSelectedUtmData(campaignData);
    setIsUtmModalOpen(true);
  };

  const handleToggleAutoCpc = async (
    campaignId,
    adGroupId,
    projectId,
    currentStatus
  ) => {
    if (loadingAutoCpc !== null) return; // Verificar apenas se já existe uma atualização em andamento

    try {
      // Definir qual item está em carregamento
      setLoadingAutoCpc(`${campaignId}-${adGroupId}`);

      const response = await toggleAutomaticCpcForCampaign({
        projectId,
        campaignId,
        adGroupId,
        enabled: !currentStatus,
      });

      if (response.success) {
        toast.success(
          response.message || "Status de CPC automático atualizado com sucesso!"
        );

        // Criar uma cópia profunda dos relatórios e atualizar o item específico
        const updatedReports = reports.map((report) => {
          if (
            report.campaignId === campaignId &&
            report.adGroupId === adGroupId
          ) {
            return {
              ...report,
              enableAutomaticCpcBidAdjustment: !currentStatus,
            };
          }
          return report;
        });

        // Atualizar o estado local
        setReports(updatedReports);

        // Forçar atualização da tabela
        setForceUpdate((prev) => prev + 1);

        // Armazenar para persistência entre renderizações
        if (typeof window !== "undefined") {
          window.reportsDraft = updatedReports;
        }
      } else {
        toast.error(
          response.message || "Falha ao atualizar status de CPC automático."
        );
      }
    } catch (error) {
      toast.error("Erro ao alterar o status de CPC automático.");
      console.error("Erro ao alterar CPC automático:", error);
    } finally {
      // Pequeno delay para a animação ficar mais visível
      setTimeout(() => {
        setLoadingAutoCpc(null); // Limpar o estado de carregamento
      }, 300);
    }
  };

  // Definição das colunas para o Material React Table
  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorKey: "info",
        id: "info",
        header: "",
        enableColumnOrdering: false,
        enableHiding: false,
        enableSorting: false,
        enableFiltering: false,
        size: 40,
        minSize: 40,
        maxSize: 40,
        sticky: "left",
        Cell: ({ row }) => (
          <FaInfoCircle
            className="text-gray-500 hover:text-gray-700 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              openModal(row.original);
            }}
          />
        ),
      },
      {
        accessorKey: "autoCpc",
        id: "autoCpc",
        header: "Auto CPC",
        enableColumnOrdering: false,
        enableHiding: false,
        enableSorting: false,
        enableFiltering: false,
        enableColumnActions: false,
        enableResizing: false,
        size: 130,
        minSize: 80,
        maxSize: 130,
        sticky: "left",
        Cell: ({ row }) => {
          const isEnabled =
            row.original.enableAutomaticCpcBidAdjustment === true;
          const isLoading =
            loadingAutoCpc ===
            `${row.original.campaignId}-${row.original.adGroupId}`;

          return (
            <div className="flex items-center justify-center">
              {isLoading ? (
                // Spinner minimalista durante o carregamento, com o mesmo tamanho do switch
                <div className="w-9 h-5 flex items-center justify-center transition-opacity duration-200">
                  <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
              ) : (
                <label className="inline-flex items-center cursor-pointer transition-opacity duration-200">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={isEnabled}
                      onChange={() => {
                        if (loadingAutoCpc === null) {
                          handleToggleAutoCpc(
                            row.original.campaignId,
                            row.original.adGroupId,
                            row.original.projectId,
                            isEnabled
                          );
                        }
                      }}
                      disabled={loadingAutoCpc !== null}
                      className="sr-only peer"
                    />
                    <div
                      className={`w-9 h-5 rounded-full transition-colors
                        ${isEnabled ? "bg-blue-500" : "bg-gray-200"} 
                        peer-focus:outline-none 
                        after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                        after:bg-white after:border-gray-300 after:border after:rounded-full 
                        after:h-4 after:w-4 after:transition-all 
                        ${
                          isEnabled
                            ? "after:translate-x-4 after:border-white"
                            : ""
                        }`}
                    ></div>
                  </div>
                </label>
              )}
            </div>
          );
        },
      },
      // Inserção da coluna 'Data' após 'info' quando 'singleCampaign' é true
      ...(singleCampaign
        ? [
            {
              id: "createdAt",
              header: "Data",
              accessorKey: "createdAt",
              minSize: 100,
              enableSorting: true,
              filterFn: "between",
              filterVariant: "date",
              Cell: ({ cell }) => dayjs(cell.getValue()).format("DD/MM HH:mm"),
            },
          ]
        : []),
      {
        id: "campaignName",
        header: "Nome da Campanha",
        accessorFn: (row) => row.campaignName || "N/A",
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ cell, row }) => (
          <div
            className="cursor-pointer hover:bg-gray-100"
            onClick={(e) => {
              e.stopPropagation();
              row.toggleSelected();
            }}
          >
            {cell.getValue()}
          </div>
        ),
      },
      {
        id: "campaignType",
        header: "Tipo de Campanha",
        accessorFn: (row) => {
          const campaignTypes = {
            UNSPECIFIED: "Não Especificado",
            UNKNOWN: "Desconhecido",
            SEARCH: "Pesquisa",
            DISPLAY: "Display",
            SHOPPING: "Shopping",
            HOTEL: "Hotel",
            VIDEO: "Vídeo",
            MULTI_CHANNEL: "Multi Canal",
            LOCAL: "Local",
            SMART: "Smart",
            PERFORMANCE_MAX: "Performance Max",
            LOCAL_SERVICES: "Serviços Locais",
            DISCOVERY: "Discovery",
            TRAVEL: "Viagem",
          };

          return campaignTypes[row.campaignType] || "Não Especificado";
        },
        minSize: 120,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ cell }) => <div className="text-sm">{cell.getValue()}</div>,
      },
      {
        id: "adGroupName",
        header: "Nome do Grupo",
        accessorFn: (row) => row.adGroupName || "N/A",
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ cell, row }) => (
          <div
            className="cursor-pointer hover:bg-gray-100"
            onClick={(e) => {
              e.stopPropagation();
              row.toggleSelected();
            }}
          >
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: "utm",
        id: "utm",
        header: "UTM",
        minSize: 100,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ cell, row }) => (
          <div className="flex items-center gap-2">
            <button
              onClick={() => handleUtmClick(row.original)}
              className="text-blue-500 hover:underline cursor-pointer flex items-center gap-2"
            >
              {cell.getValue()} <FaExternalLinkAlt />
            </button>
            {row.original.campaignDetails.adGroups[0]?.ads[0]
              ?.final_urls[0] && (
              <a
                href={`${row.original.campaignDetails.adGroups[0]?.ads[0]?.final_urls[0]}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-blue-500 hover:underline"
              >
                <FaExternalLinkAlt />
              </a>
            )}
          </div>
        ),
      },
      // Ajuste da coluna 'G-Analytics' para usar o componente 'DisplayEventCount'
      ...(!singleCampaign
        ? [
            {
              id: "gaEvents",
              header: "G-Analytics",
              accessorKey: "utm",
              minSize: 100,
              enableSorting: true,
              filterFn: "between",
              filterVariant: "range",
              Cell: ({ cell }) => (
                <DisplayEventCount
                  eventName={String(cell.getValue())}
                  eventList={gaEventList}
                />
              ),
            },
          ]
        : []),
      {
        id: "cpcAdjustTime",
        header: "Último ajuste de CPC",
        accessorFn: (row) => row.cpcInfo?.adjustTime || "N/A",
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
        Cell: ({ row }) => (
          <LastCpcAdjust
            campaignId={row.original.campaignId}
            adGroupId={row.original.adGroupId}
            projectId={row.original.projectId}
          />
        ),
      },
      {
        id: "biddingStrategy",
        header: "Estratégia de Lances",
        accessorFn: (row) => {
          const BiddingStrategyType = {
            0: "CPC Manual", // UNSPECIFIED
            1: "CPC Manual", // UNKNOWN
            2: "CPC Otimizado", // ENHANCED_CPC
            3: "CPC Manual", // MANUAL_CPC
            4: "CPM Manual", // MANUAL_CPM
            5: "Maximizar cliques", // PAGE_ONE_PROMOTED
            6: "CPA Desejado", // TARGET_CPA
            7: "Target Outrank Share", // TARGET_OUTRANK_SHARE
            8: "ROAS Desejado", // TARGET_ROAS
            9: "Maximizar Cliques", // TARGET_SPEND Gasto Desejado
            10: "Maximizar Conversões", // MAXIMIZE_CONVERSIONS
            11: "Maximizar Valor de Conversão", // MAXIMIZE_CONVERSION_VALUE
            12: "Percent CPC", // PERCENT_CPC
            13: "CPV Manual", // MANUAL_CPV
            14: "Target CPM", // TARGET_CPM
            15: "Parcela de Impreção", // TARGET_IMPRESSION_SHARE
            16: "Comissão", // COMMISSION
            17: "Invalid", // INVALID
            18: "Manual CPA", // MANUAL_CPA
          };
          const bid = row.campaignDetails.bidding_strategy_type; // Supondo que 'bid' seja um número
          if (
            row.campaignDetails.bidding_strategy_type === 10 &&
            row.adGroupDetails.type === "ASSET_GROUP"
          )
            return "P. Max";
          return BiddingStrategyType[bid] || "CPC Manual"; // Valor padrão se o ID não for encontrado
        },
        minSize: 150,
        enableSorting: true,
        filterFn: "contains",
      },
      {
        id: "labels",
        header: "Rótulos",
        accessorFn: (row) => {
          if (!row.labels || !config?.GadsAccountLabels) {
            return ["N/A"];
          }

          const labelsArray = Array.isArray(row.labels) ? row.labels : [];

          const labels = labelsArray.map((resourceName) => {
            const foundLabel = config.GadsAccountLabels.find(
              (lbl) => lbl.resourceName === resourceName
            );

            if (!foundLabel) {
              return "N/A";
            }

            return foundLabel.name;
          });

          return labels.length > 0 ? labels : ["N/A"];
        },
        minSize: 100,
        Cell: ({ row }) => (
          <LabelDisplay project={config} labels={row.original.labels} />
        ),
        enableSorting: true,
        filterFn: "contains",
      },
      {
        id: "cpcMin",
        header: "CPC Min (R$)",
        accessorFn: (row) => {
          const cpcMinValue = calculateCpcValue(row, "min");
          return parseFloat(cpcMinValue) || 0;
        },
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ row }) => (
          <CpcCampaignDataCell
            campaign={row.original}
            config={config}
            type="min"
          />
        ),
      },
      {
        id: "cpc",
        header: "CPC (R$)",
        accessorFn: (row) => parseFloat(row.currentBid) || 0,
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ row }) => {
          const campaignId = row.original.campaignId;
          const adGroupId = row.original.adGroupId;
          const currentBid = row.original.currentBid.toFixed(2);
          const bid = row.original.campaignDetails.bidding_strategy_type;
          const cpcInReais = currentBid ? currentBid : "∞";

          return (
            <div className="flex items-center gap-2">
              {editableCPC === campaignId ? (
                <input
                  type="text"
                  value={editedCPCValue}
                  onChange={(e) => setEditedCPCValue(e.target.value)}
                  className="border border-gray-300 p-0.5 text-xs rounded flex-grow w-full max-w-xs"
                  disabled={isSubmitting}
                />
              ) : (
                `R$ ${cpcInReais}`
              )}
              {!singleCampaign && editableCPC !== campaignId && (
                <FaEdit
                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={() =>
                    handleEditCPC(campaignId, adGroupId, cpcInReais)
                  }
                />
              )}
              {editableCPC === campaignId && (
                <FaCheck
                  className={`${
                    isSubmitting ? "text-gray-400" : "text-green-500"
                  } cursor-pointer`}
                  onClick={() =>
                    !isSubmitting &&
                    handleSaveCPC(
                      campaignId,
                      adGroupId,
                      row.original.projectId,
                      bid,
                      cpcInReais
                    )
                  }
                />
              )}
            </div>
          );
        },
      },

      {
        id: "cpcMax",
        header: "CPC Max (R$)",
        accessorFn: (row) => {
          const cpcMaxValue = calculateCpcValue(row, "max");
          return parseFloat(cpcMaxValue) || 0;
        },
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ row }) => (
          <CpcCampaignDataCell
            campaign={row.original}
            config={config}
            type="max"
          />
        ),
      },
      {
        accessorKey: "roiPercentage",
        header: "ROI (%)",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const roiStyle =
            value > 0
              ? "text-green-500"
              : value < 0
              ? "text-red-500"
              : "text-black";
          return (
            <span className={`font-semibold ${roiStyle}`}>
              {value?.toFixed(2)}%
            </span>
          );
        },
      },
      {
        accessorKey: "googleAdsCost",
        header: "Investimento",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) => `R$ ${cell.getValue()?.toFixed(2)}`,
      },
      {
        accessorKey: "roiRevenue",
        header: "Lucro",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const lucroStyle =
            value > 0
              ? "text-green-500"
              : value < 0
              ? "text-red-500"
              : "text-black";
          return <span className={lucroStyle}>R$ {value?.toFixed(2)}</span>;
        },
      },
      {
        accessorKey: "previewsRegister1",
        header: "Registro anterior ~20",
        minSize: 150,
        enableSorting: false,
        enableFiltering: false,
        Cell: ({ row }) => (
          <PreviewReport data={row.original.previousReports} index={2} />
        ),
      },
      {
        accessorKey: "previewsRegister2",
        header: "Registro anterior ~40",
        minSize: 150,
        enableSorting: false,
        enableFiltering: false,
        Cell: ({ row }) => (
          <PreviewReport data={row.original.previousReports} index={1} />
        ),
      },
      {
        accessorKey: "previewsRegister3",
        header: "Registro anterior ~60",
        minSize: 150,
        enableSorting: false,
        enableFiltering: false,
        Cell: ({ row }) => (
          <PreviewReport data={row.original.previousReports} index={0} />
        ),
      },
      {
        accessorKey: "adManagerRevenueBRL",
        header: "Total Ganho (BRL)",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) =>
          cell.getValue() !== null && cell.getValue() !== undefined
            ? `R$ ${cell.getValue().toFixed(2)}`
            : "N/A",
      },
      {
        accessorKey: "adManagerRevenueUSD",
        header: "Total Ganho (USD)",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) =>
          cell.getValue() !== null && cell.getValue() !== undefined
            ? `$${cell.getValue().toFixed(2)}`
            : "N/A",
      },
      {
        accessorKey: "googleAdsClicks",
        header: "Google Ads Clicks",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        id: "conversions",
        header: "Google Ads Conversions",
        minSize: 150,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        accessorFn: (row) => row.adGroupDetails?.metrics?.conversions || 0,
        Cell: ({ cell }) => cell.getValue().toFixed(1),
      },
      {
        accessorKey: "googleAdsImpressions",
        header: "Google Ads Impressions",
        minSize: 150,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        accessorKey: "adManagerClicks",
        header: "Ad Manager Clicks",
        minSize: 120,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        accessorKey: "adManagerImpressions",
        header: "Ad Manager Impressions",
        minSize: 150,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
      },
      {
        accessorKey: "adManagerPmr",
        header: "PMR",
        minSize: 100,
        enableSorting: true,
        filterFn: "between",
        filterVariant: "range",
        Cell: ({ cell }) =>
          cell.getValue() ? `${(cell.getValue() * 100).toFixed(2)}%` : "N/A",
      },
    ];

    return baseColumns;
  }, [
    editableCPC,
    editedCPCValue,
    isSubmitting,
    gaEventList,
    config,
    singleCampaign,
    loadingAutoCpc,
  ]);

  // Atualizar o columnOrder quando columns ou singleCampaign mudar
  const [columnOrder, setColumnOrder] = useState(() => {
    const savedOrder = localStorage.getItem("columnOrder");
    if (savedOrder && savedOrder !== "undefined") {
      try {
        const parsedOrder = JSON.parse(savedOrder);
        // Verifica se todas as colunas atuais estão presentes na ordem salva
        const currentColumnIds = columns.map(
          (column) => column.id || column.accessorKey
        );
        const missingColumns = currentColumnIds.filter(
          (id) => !parsedOrder.includes(id)
        );

        // Se houver colunas faltando, adiciona-as ao final da ordem
        if (missingColumns.length > 0) {
          return [...parsedOrder, ...missingColumns];
        }
        return parsedOrder;
      } catch (e) {
        console.error("Falha ao analisar columnOrder do localStorage:", e);
        localStorage.removeItem("columnOrder");
      }
    }
    return columns.map((column) => column.id || column.accessorKey);
  });

  useEffect(() => {
    const savedOrder = localStorage.getItem("columnOrder");
    if (savedOrder && savedOrder !== "undefined") {
      try {
        const parsedOrder = JSON.parse(savedOrder);
        // Verifica se todas as colunas atuais estão presentes na ordem salva
        const currentColumnIds = columns.map(
          (column) => column.id || column.accessorKey
        );
        const missingColumns = currentColumnIds.filter(
          (id) => !parsedOrder.includes(id)
        );

        // Se houver colunas faltando, adiciona-as ao final da ordem
        if (missingColumns.length > 0) {
          setColumnOrder([...parsedOrder, ...missingColumns]);
        } else {
          setColumnOrder(parsedOrder);
        }
      } catch (e) {
        const defaultOrder = columns.map(
          (column) => column.id || column.accessorKey
        );
        setColumnOrder(defaultOrder);
      }
    } else {
      const defaultOrder = columns.map(
        (column) => column.id || column.accessorKey
      );
      setColumnOrder(defaultOrder);
    }
  }, [singleCampaign, columns]);

  // Atualizar columnOrder quando cpcBotEnabled mudar
  useEffect(() => {
    // Se o Bot de CPC for desabilitado, remover autoCpc do columnOrder
    // Se for habilitado, adicionar autoCpc após "info" se não estiver lá
    if (!cpcBotEnabled) {
      // Ocultar a coluna quando o bot estiver desabilitado
      setColumnVisibility((prev) => ({
        ...prev,
        autoCpc: false,
      }));
    } else {
      // Mostrar a coluna quando o bot estiver habilitado
      setColumnVisibility((prev) => ({
        ...prev,
        autoCpc: true,
      }));

      // Verificar se autoCpc já está no columnOrder
      if (!columnOrder.includes("autoCpc")) {
        // Encontrar a posição de "info" para adicionar logo após
        const infoIndex = columnOrder.indexOf("info");
        if (infoIndex !== -1) {
          const newColumnOrder = [...columnOrder];
          newColumnOrder.splice(infoIndex + 1, 0, "autoCpc");
          setColumnOrder(newColumnOrder);
        } else {
          // Se "info" não for encontrado, adicionar ao início
          setColumnOrder(["autoCpc", ...columnOrder]);
        }
      }
    }
  }, [cpcBotEnabled, columnOrder]);

  // useEffect para salvar columnOrder no localStorage quando mudar
  useEffect(() => {
    localStorage.setItem("columnOrder", JSON.stringify(columnOrder));
  }, [columnOrder]);

  // Definir ordenação inicial
  const initialSorting = [
    {
      id: "googleAdsCost",
      desc: true,
    },
  ];

  // useEffect para salvar columnVisibility no localStorage quando mudar
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem("columnVisibility");
    if (savedVisibility && savedVisibility !== "undefined") {
      try {
        const parsedVisibility = JSON.parse(savedVisibility);
        return parsedVisibility;
      } catch (e) {
        console.error("Falha ao analisar columnVisibility do localStorage:", e);
        localStorage.removeItem("columnVisibility");
      }
    }
    return {};
  });

  // UseEffect para salvar columnVisibility no localStorage quando mudar
  useEffect(() => {
    localStorage.setItem("columnVisibility", JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  // Estado para armazenar os tamanhos das colunas
  const [columnSizing, setColumnSizing] = useState(() => {
    const savedSizing = localStorage.getItem("columnSizing");
    if (savedSizing && savedSizing !== "undefined") {
      try {
        return JSON.parse(savedSizing);
      } catch (e) {
        console.error("Falha ao analisar columnSizing do localStorage:", e);
        localStorage.removeItem("columnSizing");
      }
    }
    return {};
  });

  // useEffect para salvar columnSizing no localStorage quando mudar
  useEffect(() => {
    localStorage.setItem("columnSizing", JSON.stringify(columnSizing));
  }, [columnSizing]);

  const handleRowSelectionChange = (updatedSelectedRows) => {
    setSelectedRows(updatedSelectedRows);
    setForceUpdate((prev) => prev + 1);

    // Armazena os grupos de anúncios selecionados no localStorage
    const selectedAdGroups = Object.keys(updatedSelectedRows)
      .filter((key) => updatedSelectedRows[key])
      .map((key) => {
        const row = reports.find((report) => report.adGroupId === key);
        return {
          projectId: row.projectId,
          campaignId: row.campaignId,
          adGroupId: row.adGroupId,
          campaignName: row.campaignName,
          adGroupName: row.adGroupName,
          currentBid: row.currentBid,
          biddingStrategy: row.campaignDetails.bidding_strategy_type,
        };
      });

    localStorage.setItem("selectedCampaigns", JSON.stringify(selectedAdGroups));
  };

  // Função para marcar corretamente os relatórios quando a tabela é renderizada
  useEffect(() => {
    // O problema também pode ser que as atualizações não estão sendo refletidas nos reports originais
    // Este efeito garante que o estado de enableAutomaticCpcBidAdjustment esteja presente em todos os relatórios
    const hasUpdatedReports =
      window.reportsDraft &&
      window.reportsDraft.some(
        (report) => "enableAutomaticCpcBidAdjustment" in report
      );

    if (hasUpdatedReports && window.reportsDraft) {
      // Se tivermos reportsDraft com o campo enableAutomaticCpcBidAdjustment, use-os
      setReports(window.reportsDraft);
      setForceUpdate((prev) => prev + 1);
    }
  }, []);

  // Atualiza a interface sempre que forceUpdate mudar
  useEffect(() => {
    // Este efeito existe apenas para forçar a re-renderização quando forceUpdate mudar
  }, [forceUpdate]);

  // Atualizar reports quando initialReports mudar
  useEffect(() => {
    setReports(initialReports);
  }, [initialReports]);

  // Ocultar o aviso após alguns segundos
  useEffect(() => {
    let timer;
    if (showUpdateNotice) {
      timer = setTimeout(() => {
        setShowUpdateNotice(false);
      }, 7000); // Ocultar após 7 segundos
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showUpdateNotice]);

  // Handler para operações em massa bem-sucedidas
  const handleSuccessfulOperation = useCallback(() => {
    setShowUpdateNotice(true);
  }, []);

  return (
    <div className="max-w-full">
      {showUpdateNotice && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-blue-50 text-blue-800 px-4 py-2 rounded-md shadow-md z-50 flex items-center text-sm animate-fadeIn">
          <FaInfoCircle className="mr-2 text-blue-500" />
          As alterações foram aplicadas, mas podem não aparecer imediatamente.
          Atualize a página para visualizar.
        </div>
      )}

      <div className="flex justify-between items-center mb-2">
        <div></div>
        <div className="flex justify-end">
          <span className="m-2 text-xs">
            <strong>Horário do relatório: </strong>
            {reports && reports.length > 0 && reports[0].createdAt
              ? dayjs(reports[0].createdAt).format("DD/MM HH:mm")
              : "N/A"}
          </span>
        </div>
      </div>

      <MaterialReactTable
        columns={columns}
        data={reports}
        enableColumnOrdering
        enableRowSelection
        enableColumnDragging
        enableColumnResizing
        enableGlobalFilter
        enableColumnFilterModes
        enableColumnFilters
        enableColumnHiding
        enableSorting
        localization={MRT_Localization_PT_BR}
        muiTableBodyCellProps={{
          style: { fontSize: "0.75rem" },
        }}
        muiTableHeadCellProps={{
          style: {
            fontSize: "0.75rem",
            whiteSpace: "nowrap",
          },
        }}
        state={{
          columnVisibility,
          columnOrder,
          columnSizing,
          isLoading: false,
          rowSelection: selectedRows,
        }}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        onColumnSizingChange={setColumnSizing}
        initialState={{
          showGlobalFilter: true,
          density: "compact",
          sorting: initialSorting,
          columnPinning: {
            left: ["mrt-row-select", "info", "autoCpc"],
          },
        }}
        columnResizeMode="onChange"
        muiToolbarAlertBannerProps={undefined}
        enablePinning
        displayColumnDefOptions={{
          "mrt-row-select": {
            enableColumnActions: false,
            enableHiding: false,
            enableColumnDragging: false,
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            enableSorting: false,
            size: 40,
            minSize: 40,
            maxSize: 40,
            sticky: "left",
          },
          info: {
            enableColumnDragging: false,
            enableColumnOrdering: false,
            size: 40,
            minSize: 40,
            maxSize: 40,
            sticky: "left",
          },
          autoCpc: {
            enableColumnActions: false,
            enableHiding: false,
            enableColumnDragging: false,
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            enableSorting: false,
            enableResizing: false,
            size: 80,
            minSize: 80,
            maxSize: 80,
            sticky: "left",
          },
        }}
        muiTableBodyRowProps={({ row }) => {
          const isLoading =
            loadingAutoCpc ===
            `${row.original.campaignId}-${row.original.adGroupId}`;
          return {
            sx: {
              cursor: "default",
              backgroundColor: isLoading ? "#ebf5ff" : undefined, // Azul bem claro quando estiver carregando
            },
            onClick: (event) => event.stopPropagation(),
          };
        }}
        getRowId={(row) => row.adGroupId}
        onRowSelectionChange={handleRowSelectionChange}
      />

      {selectedRows &&
        Object.values(selectedRows).filter(Boolean).length >= 2 && (
          <BulkActionsBar
            key={forceUpdate}
            selectedRows={selectedRows}
            reports={reports}
            onClose={() => {
              setSelectedRows({});
              setForceUpdate((prev) => prev + 1);
            }}
            onSuccessfulOperation={handleSuccessfulOperation}
          />
        )}

      {isModalOpen && selectedCampaign && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          style={{ zIndex: 999999 }}
        >
          <div className="bg-white rounded-lg w-full max-w-md p-6 shadow-lg relative mx-2 max-h-[85vh] overflow-y-auto">
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            <h2 className="text-xl font-medium text-gray-800 mb-5">
              {selectedCampaign.campaignName}
            </h2>

            <div className="bg-gray-50 rounded-lg p-4 mb-6">
              <div className="grid grid-cols-2 gap-y-3 gap-x-4 text-sm">
                <div className="text-gray-500">ID da Campanha</div>
                <div className="text-gray-800 font-medium text-right">
                  {selectedCampaign.campaignId}
                </div>

                <div className="text-gray-500">ID do Grupo</div>
                <div className="text-gray-800 font-medium text-right">
                  {selectedCampaign.adGroupId}
                </div>

                <div className="text-gray-500">Nome do Grupo</div>
                <div className="text-gray-800 font-medium text-right">
                  {selectedCampaign.adGroupName || "N/A"}
                </div>

                <div className="text-gray-500">UTM</div>
                <div className="text-gray-800 font-medium text-right">
                  {selectedCampaign.utm || "N/A"}
                </div>

                <div className="text-gray-500">ROI</div>
                <div
                  className={`font-medium text-right ${
                    selectedCampaign.roiPercentage > 0
                      ? "text-green-600"
                      : selectedCampaign.roiPercentage < 0
                      ? "text-red-600"
                      : "text-gray-800"
                  }`}
                >
                  {selectedCampaign.roiPercentage
                    ? `${selectedCampaign.roiPercentage.toFixed(2)}%`
                    : "N/A"}
                </div>

                <div className="text-gray-500">Lucro</div>
                <div
                  className={`font-medium text-right ${
                    selectedCampaign.roiRevenue > 0
                      ? "text-green-600"
                      : selectedCampaign.roiRevenue < 0
                      ? "text-red-600"
                      : "text-gray-800"
                  }`}
                >
                  R${" "}
                  {selectedCampaign.roiRevenue
                    ? selectedCampaign.roiRevenue.toFixed(2)
                    : "N/A"}
                </div>

                <div className="text-gray-500">Investimento</div>
                <div className="text-gray-800 font-medium text-right">
                  R${" "}
                  {selectedCampaign.googleAdsCost
                    ? selectedCampaign.googleAdsCost.toFixed(2)
                    : "N/A"}
                </div>

                <div className="text-gray-500">Ganho (BRL)</div>
                <div className="text-gray-800 font-medium text-right">
                  R${" "}
                  {selectedCampaign.adManagerRevenueBRL
                    ? selectedCampaign.adManagerRevenueBRL.toFixed(2)
                    : "N/A"}
                </div>

                <div className="text-gray-500">Ganho (USD)</div>
                <div className="text-gray-800 font-medium text-right">
                  ${" "}
                  {selectedCampaign.adManagerRevenueUSD
                    ? selectedCampaign.adManagerRevenueUSD.toFixed(2)
                    : "N/A"}
                </div>
              </div>
            </div>

            <CpcEditor
              campaignId={selectedCampaign.campaignId}
              adGroupId={selectedCampaign.adGroupId}
              projectId={selectedCampaign.projectId}
              onClose={closeModal}
            />

            <div className="mt-6 flex justify-end space-x-3">
              <Link
                to={`/project/${selectedCampaign.projectId}/performance/${selectedCampaign.campaignId}/${selectedCampaign.adGroupId}`}
                className="text-blue-500 hover:text-blue-700 text-sm font-medium transition-colors"
                onClick={closeModal}
              >
                Ver Histórico Completo
              </Link>
              <button
                className="text-gray-500 hover:text-gray-700 text-sm font-medium transition-colors"
                onClick={closeModal}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Novo Modal de UTM */}
      <UtmDetailsModal
        isOpen={isUtmModalOpen}
        onClose={() => setIsUtmModalOpen(false)}
        campaignData={selectedUtmData}
      />
    </div>
  );
};

// Adicionar estilos para animação de fade-in
const style = document.createElement("style");
style.textContent = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translate(-50%, -20px); }
    to { opacity: 1; transform: translate(-50%, 0); }
  }
  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }
`;
document.head.appendChild(style);

export default CampaignPerformanceTable;
