import React, { useEffect } from "react";
import { FaTag, FaSpinner, FaInfoCircle } from "react-icons/fa";
import CollapsibleCard from "../../../components/collapsible/CollapsibleCard";
import * as api from "../../../utils/api";

const ProjectSelector = ({
  loading,
  errorMessage,
  projects,
  setProjects,
  setLoading,
  setErrorMessage,
  onSelectProject,
}) => {
  // Carregar todos os projetos que têm GTM configurado
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        setErrorMessage("");

        // Buscar todos os projetos e filtrar os que têm GTM configurado
        const response = await api.listAllProjects();

        if (Array.isArray(response)) {
          // Resposta é um array direto
          const projectsWithGtm = response.filter(
            (project) => project.gtmAccountId && project.gtmContainerId
          );

          setProjects(projectsWithGtm);

          if (projectsWithGtm.length === 0) {
            setErrorMessage(
              "Nenhum projeto com Google Tag Manager configurado foi encontrado."
            );
          }
        } else {
          setErrorMessage(
            "Não foi possível carregar os projetos. Formato de resposta inválido."
          );
        }
      } catch (error) {
        console.error("Erro ao carregar projetos:", error);
        setErrorMessage(
          "Ocorreu um erro ao carregar os projetos. Por favor, tente novamente."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [setLoading, setErrorMessage, setProjects]);

  const renderProjectList = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center py-10">
          <FaSpinner className="animate-spin text-blue-500 text-2xl" />
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div className="bg-red-50 border border-red-200 rounded-md p-4 my-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <FaInfoCircle className="text-red-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{errorMessage}</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {projects.map((project) => (
          <div
            key={project.id}
            className="border border-gray-200 rounded-lg p-4 hover:border-blue-300 cursor-pointer transition-colors bg-white"
            onClick={() => onSelectProject(project)}
          >
            <div className="flex items-center mb-2">
              <div className="bg-blue-100 p-2 rounded-full mr-3">
                <FaTag className="text-blue-500" />
              </div>
              <h3 className="font-medium text-lg">{project.projectName}</h3>
            </div>
            <div className="text-sm text-gray-500 pl-10">
              <p>ID: {project.id}</p>
              <p>Conta GTM: {project.gtmAccountId}</p>
              <p>Container GTM: {project.gtmContainerId}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <CollapsibleCard
      title="Selecione um Projeto"
      icon={FaTag}
      defaultExpanded={true}
    >
      {renderProjectList()}
    </CollapsibleCard>
  );
};

export default ProjectSelector;
