// Sidebar.jsx
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CreditsContext } from "../contexts/CreditsContext";
import {
  FaPlus,
  FaAngleLeft,
  FaAngleRight,
  FaBars,
  FaCalculator,
} from "react-icons/fa";
import GeneratePix from "./GeneratePix";
import MenuItems from "./MenuItems"; // Import the MenuItems component
import AssessmentIcon from "@mui/icons-material/Assessment";
import { getCurrentSubscription } from "../utils/api";

const Sidebar = () => {
  const { credits } = useContext(CreditsContext);
  const [username, setUsername] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasGadsIntegration, setHasGadsIntegration] = useState(false);
  const [userScopes, setUserScopes] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isPixModalOpen, setIsPixModalOpen] = useState(false); // State for PIX modal
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
  const didMountRef = useRef(false);
  const location = useLocation();
  const [currentPlan, setCurrentPlan] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (didMountRef.current) return;
    didMountRef.current = true;

    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      setUsername(payload.username);
      setIsAdmin(payload.scopes && payload.scopes.includes("admin"));
      setHasGadsIntegration(
        payload.scopes &&
          (payload.scopes.includes("gads-integration") ||
            payload.scopes.includes("projects"))
      );
      // Set user scopes from token
      setUserScopes(payload.scopes || []);
    }

    if (location.pathname.includes("/campaigns")) {
      setActiveMenu("campaigns");
    } else if (location.pathname.includes("/tools")) {
      setActiveMenu("tools");
    } else if (location.pathname.includes("/google-ads-api")) {
      setActiveMenu("googleAdsAPI");
    } else if (location.pathname.includes("/admin")) {
      setActiveMenu("admin");
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchSubscription = async () => {
      const response = await getCurrentSubscription();
      if (response.success && response.subscription) {
        setCurrentPlan(response.subscription.planName);
      }
    };

    fetchSubscription();
  }, []);

  const toggleMenu = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const toggleSidebar = () => {
    setIsMinimized((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const handleLogout = () => {
    try {
      // Limpar todos os dados do localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userData");

      // Redirecionar para a página de login
      navigate("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  return (
    <>
      {/* Navbar Mobile */}
      <div className="md:hidden bg-[#f8fafc] border-b text-gray-800 flex items-center justify-between p-4 fixed w-full z-10">
        <div className="text-xl font-bold">
          Kaizen<span className="text-blue-600">ADS</span>
        </div>
        <button
          onClick={toggleMobileMenu}
          className="text-gray-700 hover:text-gray-900 transition-colors"
        >
          <FaBars className="text-xl" />
        </button>
      </div>

      {/* Menu Mobile */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-[#f8fafc] text-gray-900 fixed top-14 left-0 w-full h-full z-10 overflow-y-auto">
          <div className="p-6 border-b border-gray-100">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-700 font-semibold">
                {username.charAt(0).toUpperCase()}
              </div>
              <div>
                <div className="font-semibold text-gray-900">{username}</div>
                <div className="text-sm font-medium text-gray-700">
                  {currentPlan &&
                    currentPlan.charAt(0).toUpperCase() + currentPlan.slice(1)}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-200 shadow-sm">
              <div className="text-sm">
                <span className="text-gray-700 font-medium">Créditos:</span>
                <span className="ml-2 font-bold text-gray-900">{credits}</span>
              </div>
              <button
                onClick={() => {
                  setIsPixModalOpen(true);
                  setIsMobileMenuOpen(false);
                }}
                className="text-blue-700 hover:text-blue-800 text-sm font-semibold"
              >
                Adicionar
              </button>
            </div>
          </div>

          <MenuItems
            activeMenu={activeMenu}
            toggleMenu={toggleMenu}
            isMinimized={false}
            isAdmin={isAdmin}
            hasGadsIntegration={hasGadsIntegration}
            userScopes={userScopes}
            onCloseMenu={() => setIsMobileMenuOpen(false)}
          />

          <div className="p-4 border-t border-gray-100 mt-auto">
            <button
              onClick={handleLogout}
              className="flex items-center justify-center gap-2 text-gray-700 hover:text-red-600 transition-colors font-medium w-full"
            >
              <span className="text-sm">Sair da conta</span>
            </button>
          </div>
        </div>
      )}

      {/* Sidebar Desktop */}
      <div
        className={`bg-[#f8fafc] border-r border-gray-200 ${
          isMinimized ? "w-20" : "w-72"
        } min-h-screen transition-all duration-300 hidden md:flex md:flex-col`}
      >
        {/* Header */}
        <div className="p-6 border-b border-gray-100 flex items-center justify-between">
          {!isMinimized && (
            <div className="text-xl font-bold text-gray-900">
              Kaizen<span className="text-blue-600">ADS</span>
            </div>
          )}
          <button
            onClick={toggleSidebar}
            className="text-gray-600 hover:text-gray-900 transition-colors p-1.5 rounded-lg hover:bg-gray-100"
          >
            {isMinimized ? <FaAngleRight /> : <FaAngleLeft />}
          </button>
        </div>

        {/* User Info */}
        {!isMinimized && (
          <div className="p-6 border-b border-gray-100">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-700 font-semibold">
                {username.charAt(0).toUpperCase()}
              </div>
              <div>
                <div className="font-semibold text-gray-900">{username}</div>
                <div className="text-sm font-medium text-gray-700">
                  {currentPlan &&
                    currentPlan.charAt(0).toUpperCase() + currentPlan.slice(1)}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-200 shadow-sm">
              <div className="text-sm">
                <span className="text-gray-700 font-medium">Créditos:</span>
                <span className="ml-2 font-bold text-gray-900">{credits}</span>
              </div>
              <button
                onClick={() => setIsPixModalOpen(true)}
                className="text-blue-700 hover:text-blue-800 text-sm font-semibold"
              >
                Adicionar
              </button>
            </div>
          </div>
        )}

        {/* Menu Items */}
        <div className="flex-1 overflow-y-auto">
          <MenuItems
            activeMenu={activeMenu}
            toggleMenu={toggleMenu}
            isMinimized={isMinimized}
            isAdmin={isAdmin}
            hasGadsIntegration={hasGadsIntegration}
            userScopes={userScopes}
            onCloseMenu={() => {}}
          />
        </div>

        {/* Footer */}
        {!isMinimized && (
          <div className="p-4 border-t border-gray-100">
            <button
              onClick={handleLogout}
              className="flex items-center justify-center gap-2 text-gray-700 hover:text-red-600 transition-colors font-medium w-full"
            >
              <span className="text-sm">Sair da conta</span>
            </button>
          </div>
        )}
      </div>

      {/* PIX Modal */}
      {isPixModalOpen && (
        <GeneratePix isOpen={isPixModalOpen} setIsOpen={setIsPixModalOpen} />
      )}
    </>
  );
};

export default Sidebar;
