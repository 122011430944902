import React, { useState } from "react";
import ProjectCard from "./ProjectCard";
import ProjectsSummary from "./ProjectsSummary";

const ProjectsList = ({
  projects,
  loadingProjects,
  loadingDelete,
  searchTerm,
  canCreateProject,
  userId,
  onDeleteProject,
  onOpenCreateModal,
}) => {
  const [activeTab, setActiveTab] = useState("owned");

  // Filtra os projetos baseado no tipo (próprios ou gerenciados)
  const filteredProjects = projects.filter((project) => {
    const matchesSearch = searchTerm
      ? project.projectName?.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    const isOwned = project.accountId === userId;
    const isManaged =
      project.accountId !== userId &&
      project.trafficManagersIds &&
      Array.isArray(project.trafficManagersIds) &&
      project.trafficManagersIds.includes(userId);

    if (activeTab === "owned") {
      return isOwned && matchesSearch;
    } else {
      return isManaged && matchesSearch;
    }
  });

  // Ordena os projetos por nome em ordem alfabética
  const sortedProjects = [...filteredProjects].sort((a, b) => {
    const nameA = a.projectName || "";
    const nameB = b.projectName || "";
    return nameA.localeCompare(nameB);
  });

  // Conta projetos próprios e gerenciados
  const ownedCount = projects.filter(
    (project) => project.accountId === userId
  ).length;
  const managedCount = projects.filter(
    (project) =>
      project.accountId !== userId &&
      project.trafficManagersIds &&
      Array.isArray(project.trafficManagersIds) &&
      project.trafficManagersIds.includes(userId)
  ).length;

  return (
    <div className="space-y-6">
      <div className="flex space-x-4 border-b">
        <button
          onClick={() => setActiveTab("owned")}
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "owned"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500 hover:text-gray-700"
          }`}
        >
          Meus Projetos
        </button>
        <button
          onClick={() => setActiveTab("managed")}
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "managed"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500 hover:text-gray-700"
          }`}
        >
          Projetos Gerenciados
        </button>
      </div>

      {projects.length > 0 && (
        <ProjectsSummary
          projects={projects}
          activeTab={activeTab}
          userId={userId}
        />
      )}

      {loadingProjects ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
        </div>
      ) : projects.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-500 mb-4">
            {activeTab === "owned"
              ? "Você ainda não tem projetos."
              : "Você não está gerenciando nenhum projeto."}
          </p>
          {activeTab === "owned" && canCreateProject && (
            <button
              onClick={onOpenCreateModal}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-blue-700 transition-colors"
            >
              Criar Novo Projeto
            </button>
          )}
        </div>
      ) : sortedProjects.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-500 mb-4">
            Nenhum projeto encontrado para sua busca.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {sortedProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onDelete={onDeleteProject}
              isLoading={loadingDelete[project.id]}
              userId={userId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectsList;
