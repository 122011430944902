import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

let isRefreshing = false;
let refreshSubscribers = [];
let refreshTokenAttempts = 0;

const MAX_REFRESH_ATTEMPTS = 3;

function onRefreshed(token) {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(cb) {
  refreshSubscribers.push(cb);
}

function redirectToLogin() {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  window.location.href = "/login";
}

function isNetworkError(error) {
  return (
    !error.response &&
    (error.message.includes("Network Error") ||
      error.message.includes("timeout"))
  );
}

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Verifica se o erro é relacionado a problemas de rede (conexão)
    if (isNetworkError(error)) {
      return Promise.reject(error); // Retorna o erro sem invalidar o token ou redirecionar
    }

    // Verifica se o erro é devido ao token expirado com base na resposta da API
    const isTokenExpiredError =
      error.response?.status === 401 &&
      error.response.data &&
      error.response.data.error === "Unauthorized" &&
      error.response.data.message === "Authorization token expired";

    if (isTokenExpiredError && originalRequest.url !== "/accounts/login") {
      // Verificar se já ultrapassou o número máximo de tentativas
      if (refreshTokenAttempts >= MAX_REFRESH_ATTEMPTS) {
        redirectToLogin();
        return Promise.reject(error);
      }

      // Verificar se já estamos tentando atualizar o token
      if (!isRefreshing) {
        isRefreshing = true;
        refreshTokenAttempts += 1;

        const refreshToken = localStorage.getItem("refreshToken");

        if (!refreshToken) {
          redirectToLogin();
          return Promise.reject(error);
        }

        try {
          const response = await api.post("/accounts/refresh-token", {
            refreshToken,
          });
          const newToken = response.data.token;
          localStorage.setItem("token", newToken);
          isRefreshing = false;
          refreshTokenAttempts = 0;

          onRefreshed(newToken);
        } catch (err) {
          isRefreshing = false;
          redirectToLogin();
          return Promise.reject(err);
        }
      }

      // Cria uma promessa para reexecutar a requisição original após o token ser atualizado
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(api(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    if (error.response?.status === 401) {
      redirectToLogin();
    }

    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  const response = await api.post("accounts/login", { username, password });
  localStorage.setItem("token", response.data.token);
  localStorage.setItem("refreshToken", response.data.refreshToken);
  return response;
};

export const register = async (name, email, username, password) => {
  try {
    const response = await api.post("accounts/create-account", {
      name,
      email,
      username,
      password,
    });
    return response.data;
  } catch (error) {
    throw error.response.data; // Retorna os dados de erro da resposta
  }
};

export const checkTokenCredits = async () => {
  const response = await api.get("accounts/check-credits");
  return response.data;
};

export const createCampaign = async (campaignData) => {
  try {
    const response = await api.post("gads/full-campaign", campaignData);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const checkCampaignDuplicate = async (campaignData) => {
  try {
    const response = await api.post("campaign/log/search", campaignData);
    return response.data;
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const getCampaignLogs = async () => {
  try {
    const response = await api.get("/campaign/log");
    return response.data;
  } catch (error) {
    console.error("Erro ao obter logs de campanhas:", error);
    return { success: false, error: error.response.data };
  }
};

export const getUsers = async () => {
  try {
    const response = await api.get("/accounts/list-users");
    return response.data;
  } catch (error) {
    console.error("Erro ao obter lista de usuários:", error);
    return { success: false, error: error.response.data };
  }
};

export const getUserById = async (id) => {
  try {
    const response = await api.get(`/accounts/view-account/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter usuário:", error);
    return { success: false, error: error.response.data };
  }
};

export const addUserScope = async (id, scopes) => {
  try {
    const response = await api.put(`/accounts/add-scope/${id}`, { scopes });
    return response.data;
  } catch (error) {
    console.error("Erro ao adicionar escopo ao usuário:", error);
    return { success: false, error: error.response.data };
  }
};

export const removeUserScope = async (id, scopes) => {
  try {
    const response = await api.put(`/accounts/remove-scope/${id}`, { scopes });
    return response.data;
  } catch (error) {
    console.error("Erro ao remover escopo do usuário:", error);
    return { success: false, error: error.response.data };
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await api.delete(`/accounts/delete-account/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao deletar usuário:", error);
    return { success: false, error: error.response.data };
  }
};

export const addCredits = async (id, amount) => {
  try {
    const response = await api.post(`/accounts/add-credits`, { id, amount });
    return response.data;
  } catch (error) {
    console.error("Erro ao adicionar créditos:", error);
    return { success: false, error: error.response.data };
  }
};

export const decrementCredits = async (id, amount) => {
  try {
    const response = await api.post(`/accounts/decrement-credits`, {
      id,
      amount,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao decrementar créditos:", error);
    return { success: false, error: error.response.data };
  }
};

export const banUser = async (id) => {
  try {
    const response = await api.put(`/accounts/ban-user/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao banir usuário:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para obter a URL de autenticação OAuth2
export const getGoogleAdsAuthUrl = async () => {
  try {
    const response = await api.get("/gads/auth-url");
    return response.data;
  } catch (error) {
    console.error("Erro ao obter URL de autenticação do Google Ads:", error);
    throw error.response.data;
  }
};

// Função para enviar o código de autorização e salvar os tokens
export const saveGoogleAdsTokens = async (code, projectId) => {
  try {
    const response = await api.post("/gads/auth-callback", { code, projectId });
    return response.data;
  } catch (error) {
    console.error("Erro ao salvar tokens do Google Ads:", error);
    throw error.response.data;
  }
};

// Função para criar um novo projeto
export const createProject = async (projectData) => {
  try {
    const response = await api.post("/projects", projectData);
    return response.data;
  } catch (error) {
    console.error("Erro ao criar projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para deletar um projeto
export const deleteProject = async (projectId) => {
  try {
    const response = await api.delete(`/projects/${projectId}`);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erro ao deletar projeto:", error);
    return {
      success: false,
      error: error.response ? error.response.data : "Erro desconhecido",
    };
  }
};

// Função para buscar um projeto pelo ID
export const getProjectById = async (id) => {
  try {
    const response = await api.get(`/projects/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para listar todos os projetos de um usuário
export const listAllProjects = async () => {
  try {
    const response = await api.get("/projects");
    return response.data;
  } catch (error) {
    console.error("Erro ao listar projetos:", error);
    return [];
  }
};

// Função para deletar um projeto pelo ID
export const deleteProjectById = async (id) => {
  try {
    const response = await api.delete(`/projects/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao deletar projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para adicionar Google Ad Manager ao projeto
export const addGoogleAdManagerToProject = async (id, admanagerData) => {
  try {
    const response = await api.post(`/projects/${id}/admanager`, admanagerData);
    return response.data;
  } catch (error) {
    console.error("Erro ao adicionar Google Ad Manager ao projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para listar todas as configurações de um projeto
export const listProjectConfig = async (id) => {
  try {
    const response = await api.get(`/projects/${id}/config`);
    return response.data;
  } catch (error) {
    console.error("Erro ao listar configurações do projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para buscar uma configuração específica de um projeto
export const getProjectConfigByKey = async (id, key) => {
  try {
    const response = await api.get(`/projects/${id}/config/${key}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar configuração específica do projeto:", error);
    return { success: false, error: error.response.data };
  }
};

export const addOrUpdateProjectConfig = async (id, key, value) => {
  try {
    const response = await api.post(`/projects/${id}/config`, { key, value });
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao adicionar ou atualizar configuração do projeto:",
      error
    );
    return { success: false, error: error.response.data };
  }
};

// Função para deletar uma configuração específica de um projeto
export const deleteProjectConfigKey = async (id, key) => {
  try {
    const response = await api.delete(`/projects/${id}/config/${key}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao deletar configuração específica do projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para atualizar a conta do Google Ads vinculada ao projeto
export const updateGoogleAdsAccount = async (projectId, googleAdsAccountId) => {
  try {
    const response = await api.put(
      `/projects/${projectId}/google-ads-account`,
      {
        googleAdsAccountId,
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return {
      success: false,
      error: {
        status: error.response?.status || 500,
        message:
          error.response?.data?.message || "Erro ao atualizar conta Google Ads",
      },
    };
  }
};

// Função para buscar o ID da conta do Google Ads vinculada ao projeto
export const getGoogleAdsAccountId = async (id) => {
  try {
    const response = await api.get(`/projects/${id}/google-ads-account`);
    return response.data.googleAdsAccountId;
  } catch (error) {
    console.error(
      "Erro ao buscar o ID da conta do Google Ads do projeto:",
      error
    );
    return { success: false, error: error.response.data };
  }
};

// Função para atualizar o ID do Google Ad Manager vinculado ao projeto
export const updateAdManagerAccount = async (id, admanagerId) => {
  try {
    const response = await api.put(`/projects/${id}/admanager-account`, {
      admanagerId,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao atualizar a conta do Google Ad Manager no projeto:",
      error
    );
    return { success: false, error: error.response.data };
  }
};

// Função para buscar o ID do Google Ad Manager vinculado ao projeto
export const getAdManagerAccountId = async (id) => {
  try {
    const response = await api.get(`/projects/${id}/admanager-account`);
    return response.data.admanagerId;
  } catch (error) {
    console.error(
      "Erro ao buscar o ID da conta do Google Ad Manager do projeto:",
      error
    );
    return { success: false, error: error.response.data };
  }
};
// Função para obter o domínio de um projeto
export const getDomainInProject = async (id) => {
  try {
    const response = await api.get(`/projects/${id}/domain`);
    return response.data.domain;
  } catch (error) {
    console.error("Erro ao obter o domínio do projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para adicionar ou atualizar o domínio de um projeto
export const addOrUpdateDomainInProject = async (id, domain) => {
  try {
    const response = await api.put(`/projects/${id}/domain`, { domain });
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao adicionar ou atualizar o domínio do projeto:",
      error
    );
    return { success: false, error: error.response.data };
  }
};

// Função para excluir (definir como nulo) o domínio de um projeto
export const deleteDomainFromProject = async (id) => {
  try {
    const response = await api.delete(`/projects/${id}/domain`);
    return response.data;
  } catch (error) {
    console.error("Erro ao excluir o domínio do projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para adicionar ou atualizar o managerId de um projeto
export const addOrUpdateManagerInProject = async (id, managerIdentifier) => {
  try {
    const response = await api.put(`/projects/${id}/manager`, {
      managerIdentifier,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao adicionar ou atualizar o managerId no projeto:",
      error
    );
    return { success: false, error: error.response.data };
  }
};

// Função para buscar o managerId de um projeto
export const getManagerIdInProject = async (id) => {
  try {
    const response = await api.get(`/projects/${id}/manager`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar o managerId do projeto:", error);
    return { success: false, error: error.response.data };
  }
};

// Função para remover o managerId de um projeto (definir como nulo)
export const removeManagerFromProject = async (id, managerIdentifier) => {
  try {
    const response = await api.delete(`/projects/${id}/manager`, {
      data: { managerIdentifier },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao remover o managerId do projeto:", error);
    return { success: false, error: error.response.data };
  }
};

export const getLastReport = async (projectId) => {
  try {
    const response = await api.get(`/reports/get-last-report/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter o último relatório:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getCampaignReportHistory = async (
  projectId,
  campaignId,
  date,
  minimumReports = 6
) => {
  try {
    const response = await api.get(
      `/reports/get-campaign-report-history/${projectId}/${campaignId}/${date}/${minimumReports}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter histórico de relatórios da campanha:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para modificar o lance de um grupo de anúncios no Google Ads
export const modifyAdGroupBid = async ({
  projectId,
  campaignId,
  adGroupId,
  adjustValue,
  adjustType = "cpc_bid_micros",
}) => {
  try {
    const response = await api.post("/gads/adgroup-bid-modify", {
      projectId,
      campaignId,
      adGroupId,
      adjustValue,
      adjustType,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao modificar o lance do grupo de anúncios:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Definir CPC mínimo
export const setCpcMin = async (campaignId, adGroupId, cpcMin, projectId) => {
  try {
    const response = await api.post("/gads/add-cpc", {
      campaignId,
      adGroupId,
      cpcMin,
      projectId,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao definir o CPC mínimo:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Definir CPC máximo
export const setCpcMax = async (campaignId, adGroupId, cpcMax, projectId) => {
  try {
    const response = await api.post("/gads/add-cpc", {
      campaignId,
      adGroupId,
      cpcMax,
      projectId,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao definir o CPC máximo:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Buscar o CPC atual
export const getCurrentCpc = async (projectId, campaignId, adGroupId) => {
  try {
    const response = await api.get(
      `/gads/get-cpc/${projectId}/${campaignId}/${adGroupId}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar o CPC atual:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter o relatório específico com base no projectId, campaignId e date
export const getCampaignReportByDate = async (
  projectId,
  campaignId,
  adGroupId,
  date
) => {
  try {
    const response = await api.get(
      `/reports/get-last-report/${projectId}/${campaignId}/${adGroupId}/${date}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter o relatório da campanha:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter eventos em tempo real de um projeto específico
export const getRealTimeEvents = async (projectId) => {
  try {
    const response = await api.get(`/ga4/get-rt-events/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter eventos em tempo real:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para adicionar o GA4 Property ID a um projeto
export const addPropertyIdToProject = async (projectId, propertyId) => {
  try {
    const response = await api.post("/add-property-id", {
      projectId,
      propertyId,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao adicionar Property ID ao projeto:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para gerar QR code para pagamento PIX
export const generatePixPayment = async (credits, description) => {
  try {
    const response = await api.post("/pix/generate-pix", {
      credits,
      description,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao gerar pagamento PIX:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para verificar o status do pagamento PIX
export const getPixPaymentStatus = async (paymentId) => {
  try {
    const response = await api.get(`/pix/payment-status/${paymentId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao verificar status do pagamento PIX:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter o relatório agregado com base no projectId e período
export const getAggregatedReport = async (projectId, period) => {
  try {
    const response = await api.get(
      `/reports/get-aggregated-report/${projectId}/${period}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter relatório agregado:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para resetar os campos googleAdsId e googleRefreshToken de um projeto
export const resetGoogleAdsFields = async (projectId) => {
  try {
    const response = await api.post(`/projects/${projectId}/reset-google-ads`);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erro ao resetar os campos do Google Ads:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter logs com filtros e paginação
export const getLogs = async ({
  page = 1,
  limit = 50,
  startDate,
  endDate,
  level,
  method,
  statusCode,
  url,
  ip,
  minResponseTime,
  maxResponseTime,
  search,
} = {}) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
    });

    // Adiciona filtros opcionais
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);
    if (level) queryParams.append("level", level);
    if (method) queryParams.append("method", method);
    if (statusCode) queryParams.append("statusCode", statusCode);
    if (url) queryParams.append("url", url);
    if (ip) queryParams.append("ip", ip);
    if (minResponseTime) queryParams.append("minResponseTime", minResponseTime);
    if (maxResponseTime) queryParams.append("maxResponseTime", maxResponseTime);
    if (search) queryParams.append("search", search);

    const response = await api.get(`/logs?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter logs:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter estatísticas dos logs
export const getLogsStats = async () => {
  try {
    const response = await api.get("/logs/stats");
    return response.data;
  } catch (error) {
    console.error("Erro ao obter estatísticas dos logs:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter relatório de vendas PIX
export const getPixSalesReport = async ({
  period,
  date,
  startDate,
  endDate,
  status,
  minAmount,
  maxAmount,
  page = 1,
  limit = 50,
} = {}) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
    });

    if (period) queryParams.append("period", period);
    if (date) queryParams.append("date", date);
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);
    if (status) queryParams.append("status", status);
    if (minAmount) queryParams.append("minAmount", minAmount);
    if (maxAmount) queryParams.append("maxAmount", maxAmount);

    const response = await api.get(
      `/pix/sales-report?${queryParams.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter relatório de vendas:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter métricas de vendas
export const getPixSalesMetrics = async (period = "day") => {
  try {
    const response = await api.get(`/pix/sales-metrics?period=${period}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter métricas de vendas:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter detalhes de uma venda específica
export const getPixSaleDetails = async (saleId) => {
  try {
    const response = await api.get(`/pix/sales/${saleId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter detalhes da venda:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter logs dos workers
export const getWorkersLogs = async ({
  type,
  limit = 50,
  startDate,
  endDate,
  isCronWorker,
  cpu_threshold,
  memory_threshold,
  minutes,
  workerId,
  error_types,
  order = "desc",
} = {}) => {
  try {
    let queryParams = new URLSearchParams();

    if (type) queryParams.append("type", type);
    if (limit) queryParams.append("limit", limit);
    if (startDate) queryParams.append("start_date", startDate);
    if (endDate) queryParams.append("end_date", endDate);
    if (isCronWorker !== undefined)
      queryParams.append("isCronWorker", isCronWorker);
    if (cpu_threshold) queryParams.append("cpu_threshold", cpu_threshold);
    if (memory_threshold)
      queryParams.append("memory_threshold", memory_threshold);
    if (minutes) queryParams.append("minutes", minutes);
    if (workerId) queryParams.append("workerId", workerId);
    if (error_types) queryParams.append("error_types", error_types);
    if (order) queryParams.append("order", order);

    const response = await api.get(
      `/admin/workers/logs?${queryParams.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter logs dos workers:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função específica para buscar informações do sistema
export const getSystemInfo = async () => {
  try {
    const response = await api.get(
      "/admin/workers/logs?type=system_info&limit=1"
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter informações do sistema:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para buscar alertas
export const getWorkerAlerts = async () => {
  try {
    const response = await api.get(
      "/admin/workers/logs?type=worker_memory_alert"
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter alertas dos workers:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para buscar histórico de um worker específico
export const getWorkerHistory = async (workerId) => {
  try {
    const response = await api.get(`/admin/workers/logs?workerId=${workerId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter histórico do worker:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getCronWorkers = async () => {
  try {
    const response = await api.get(
      "/admin/workers/logs?type=worker_memory&isCronWorker=true"
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter workers CRON:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getRecentWorkerLogs = async (minutes = 5) => {
  try {
    const response = await api.get(`/admin/workers/logs?minutes=${minutes}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter logs recentes:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getWorkerErrors = async () => {
  try {
    const response = await api.get("/admin/workers/logs?type=worker_error");
    return response.data;
  } catch (error) {
    console.error("Erro ao obter erros dos workers:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getHighCpuWorkers = async (threshold = 80) => {
  try {
    const response = await api.get(
      `/admin/workers/logs?cpu_threshold=${threshold}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter workers com CPU alta:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getWorkerLogsByPeriod = async (startDate, endDate) => {
  try {
    const response = await api.get(
      `/admin/workers/logs?start_date=${startDate}&end_date=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter logs por período:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getWorkerLogs = async ({
  type,
  isCronWorker,
  cpu_threshold,
  minutes,
  limit = 50,
  workerId,
  start_date,
  end_date,
} = {}) => {
  try {
    const params = new URLSearchParams();

    if (type) params.append("type", type);
    if (isCronWorker !== undefined) params.append("isCronWorker", isCronWorker);
    if (cpu_threshold) params.append("cpu_threshold", cpu_threshold);
    if (minutes) params.append("minutes", minutes);
    if (limit) params.append("limit", limit);
    if (workerId) params.append("workerId", workerId);
    if (start_date) params.append("start_date", start_date);
    if (end_date) params.append("end_date", end_date);

    const response = await api.get(`/admin/workers/logs?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter logs dos workers:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para buscar a cotação do dólar em tempo real
export const getExchangeRate = async () => {
  try {
    const response = await fetch(
      "https://api.exchangerate-api.com/v4/latest/USD"
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar a cotação do dólar.");
    }
    const data = await response.json();
    return data.rates.BRL; // Retorna a taxa de USD para BRL
  } catch (error) {
    console.error("Erro ao buscar a cotação do dólar:", error);
    return null;
  }
};

// Função para criar sessão de checkout do Stripe
export const createStripeCheckoutSession = async (planName) => {
  try {
    const response = await api.post("/stripe/create-checkout-session", {
      planName,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao criar sessão de checkout:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter informações da assinatura atual
export const getCurrentSubscription = async () => {
  try {
    const response = await api.get("/stripe/subscription");
    return response.data;
  } catch (error) {
    console.error("Erro ao obter informações da assinatura:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para mudar o plano da assinatura
export const changeSubscriptionPlan = async (planName) => {
  try {
    const response = await api.post("/stripe/change-plan", { planName });
    return response.data;
  } catch (error) {
    console.error("Erro ao mudar o plano:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const calculateProration = async (newPlanName) => {
  try {
    const response = await api.post("/stripe/calculate-proration", {
      newPlanName,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao calcular prorrogação:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para cancelar a assinatura
export const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await api.post("/stripe/cancel-subscription", {
      subscriptionId,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao cancelar assinatura:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getBillingHistory = async () => {
  try {
    const response = await api.get("/stripe/billing-history");
    return response.data;
  } catch (error) {
    console.error("Erro ao obter histórico de faturamento:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getUserInfo = async () => {
  try {
    const response = await api.get("/accounts/me");
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar informações do usuário:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getAdGroupsUsage = async (userId) => {
  try {
    const response = await api.get(`/accounts/${userId}/ad-groups-usage`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar uso de grupos de anúncios:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const getUserProfile = async () => {
  try {
    const response = await api.get("/accounts/me");
    return response.data;
  } catch (error) {
    console.error("Erro ao carregar perfil:", error);
    throw error.response?.data || error;
  }
};

export const updateUserProfile = async (data) => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("Usuário não autenticado");

    const response = await api.put(`/accounts/update-account/${userId}`, data);
    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar perfil:", error);
    throw error.response?.data || error;
  }
};

export const changePassword = async ({ id, oldPassword, newPassword }) => {
  try {
    const response = await api.post("/accounts/change-password", {
      id: Number(id),
      oldPassword: String(oldPassword),
      newPassword: String(newPassword),
    });

    // Se a resposta contiver success: false, é um erro de negócio
    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    // Se for um erro HTTP com resposta do servidor
    if (error.response?.data) {
      throw new Error(error.response.data.message);
    }
    // Se for um erro já tratado (do throw acima)
    if (error instanceof Error) {
      throw error;
    }
    // Fallback para outros tipos de erro
    throw new Error("Erro ao alterar senha");
  }
};

export const deleteAccount = async () => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("Usuário não autenticado");

    const response = await api.delete(`/accounts/delete-account/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao deletar conta:", error);
    throw error.response?.data || error;
  }
};

export const getSubscriptionInfo = async (userId) => {
  try {
    const response = await api.get("/stripe/admin/subscriptions/local");
    // Encontra a assinatura ativa mais recente para o usuário
    const userSubscriptions = response.data.subscriptions
      .filter((sub) => sub.accountId === userId)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const activeSubscription =
      userSubscriptions.find((sub) => sub.status === "active") ||
      userSubscriptions[0];

    return { success: true, data: activeSubscription };
  } catch (error) {
    console.error("Erro ao obter informações da assinatura:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const createManualSubscription = async (
  accountId,
  planName,
  endDate
) => {
  try {
    const response = await api.post("/stripe/admin/subscriptions/manual", {
      accountId,
      planName,
      endDate,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erro ao criar assinatura manual:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para verificar se uma assinatura é manual
export const verifyManualSubscription = async (subscriptionId) => {
  try {
    const response = await api.get(
      `/stripe/admin/subscriptions/verify/${subscriptionId}`
    );
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erro ao verificar assinatura manual:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para buscar projetos (todos ou por accountId)
export const getProjects = async (accountId = null) => {
  try {
    const response = await api.get("/admin/projects");
    let projects = response.data;

    if (accountId) {
      projects = projects.filter((project) => project.accountId === accountId);
    }

    return {
      success: true,
      data: projects,
      total: projects.length,
    };
  } catch (error) {
    // Tratamento específico para erro de permissão
    if (error.response?.status === 403) {
      return {
        success: false,
        error: {
          status: 403,
          message: "Você não tem permissão para acessar estes projetos",
        },
      };
    }

    // Tratamento para outros erros
    return {
      success: false,
      error: {
        status: error.response?.status || 500,
        message: error.response?.data?.message || "Erro ao buscar projetos",
      },
    };
  }
};

// Função para gerar relatório manual
export const generateTodayReport = async (projectId, keyName) => {
  try {
    const response = await api.post("/reports/get-today-report", {
      projectId,
      keyName,
    });
    return { success: true, data: response.data };
  } catch (error) {
    return {
      success: false,
      error: {
        status: error.response?.status || 500,
        message: error.response?.data?.message || "Erro ao gerar relatório",
      },
    };
  }
};

export default api;

const getUserId = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.id;
  } catch (error) {
    console.error("Erro ao decodificar token:", error);
    return null;
  }
};

// Função para adicionar/atualizar MCC ID
export const updateProjectMcc = async (projectId, mccId) => {
  try {
    const response = await api.put(`/projects/${projectId}/mcc`, {
      mccId,
    });
    return { success: true, data: response.data };
  } catch (error) {
    return {
      success: false,
      error: {
        status: error.response?.status || 500,
        message: error.response?.data?.message || "Erro ao atualizar MCC",
      },
    };
  }
};

// Função para remover MCC
export const removeProjectMcc = async (projectId) => {
  try {
    const response = await api.delete(`/projects/${projectId}/mcc`);
    return { success: true, data: response.data };
  } catch (error) {
    return {
      success: false,
      error: {
        status: error.response?.status || 500,
        message: error.response?.data?.message || "Erro ao remover MCC",
      },
    };
  }
};

// Função para buscar relatório do GAM por chave
export const getGamReportByKey = async (projectId, startDate, endDate, key) => {
  try {
    const response = await api.get(
      `/gam/gam-report-key/${projectId}/${startDate}/${endDate}/${key}`
    );
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erro ao buscar relatório do GAM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para definir o tracking URL template
export const setTrackingUrlTemplate = async (
  projectId,
  trackingUrlTemplate = null
) => {
  try {
    const payload = { projectId };
    if (trackingUrlTemplate) {
      payload.trackingUrlTemplate = trackingUrlTemplate;
    }

    const response = await api.post("/gads/tracking-url-template", payload);
    return {
      success: true,
      message:
        response.data.message || "Template de URL atualizado com sucesso",
    };
  } catch (error) {
    console.error("Erro ao definir tracking URL template:", error);
    return {
      success: false,
      message: "Erro ao atualizar template de URL",
      error: error.response?.data || error.message,
    };
  }
};

// Função para obter o tracking URL template
export const getTrackingUrlTemplate = async (projectId) => {
  try {
    const response = await api.get(`/gads/tracking-url-template/${projectId}`);
    return {
      success: true,
      data: {
        trackingUrlTemplate: response.data.data.trackingUrlTemplate,
      },
      message: response.data.message,
    };
  } catch (error) {
    console.error("Erro ao obter tracking URL template:", error);
    return {
      success: false,
      error: error.response?.data || error.message,
      message: "Erro ao obter template de URL",
    };
  }
};

// Função para buscar dados agrupados do GAM
export const getGamGroupedReport = async (
  projectId,
  startDate,
  endDate,
  groupBy
) => {
  try {
    const response = await api.get(
      `/gam/gam-grouped-report/${projectId}/${startDate}/${endDate}/${groupBy}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar relatório agrupado do GAM:", error);
    return { success: false, error: error.message };
  }
};

// Função para buscar palavras-chave do Google Ads
export const getAdGroupKeywords = async (
  projectId,
  startDate,
  endDate,
  campaignId,
  adGroupId
) => {
  try {
    const params = {
      projectId,
      startDate,
      endDate,
    };

    // Adiciona o parâmetro correto baseado no que foi recebido
    if (campaignId) {
      params.campaignId = campaignId;
    }
    if (adGroupId) {
      params.adGroupId = adGroupId;
    }

    const response = await api.get(`/gads/ad-group-keywords`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar palavras-chave do Google Ads:", error);
    return { success: false, error: error.message };
  }
};

// Função para buscar relatório de placements
export const getPlacementsReport = async (
  projectId,
  { campaignId, adGroupId },
  startDate,
  endDate
) => {
  try {
    const payload = {
      projectId,
      startDate,
      endDate,
    };

    // Adiciona apenas o ID que foi fornecido
    if (campaignId) {
      payload.campaignId = campaignId;
    } else if (adGroupId) {
      payload.adGroupId = adGroupId;
    }

    const response = await api.post(`/gads/placements-report`, payload);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar relatório de placements:", error);
    return { success: false, error: error.message };
  }
};

export const getCpcLogs = async (date, campaignId, adGroupId, projectId) => {
  try {
    const response = await api.get(`/logs/cpc`, {
      params: {
        date,
        campaignId,
        adGroupId,
        projectId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar logs de CPC:", error);
    throw error;
  }
};

// Função para obter recomendações de CPC da IA
export const getCpcLimits = async (projectId, campaignId, adGroupId, date) => {
  try {
    const response = await api.get(
      `/projects/${projectId}/campaign/${campaignId}/cpc-limits`,
      {
        params: {
          adGroupId,
          date,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter recomendações de CPC da IA:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter a URL de autenticação do Google Tag Manager
export const getGtmAuthUrl = async (projectId) => {
  try {
    const response = await api.get(`/gtm/${projectId}/auth-url`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter URL de autenticação do GTM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para listar contas do Google Tag Manager
export const listGtmAccounts = async (projectId) => {
  try {
    const response = await api.get(`/gtm/${projectId}/accounts`);
    return response.data;
  } catch (error) {
    console.error("Erro ao listar contas do GTM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para listar containers de uma conta do Google Tag Manager
export const listGtmContainers = async (projectId, accountId) => {
  try {
    const response = await api.get(
      `/gtm/${projectId}/accounts/${accountId}/containers`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao listar containers do GTM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para salvar os IDs do GTM no projeto
export const saveGtmContainer = async (
  projectId,
  gtmAccountId,
  gtmContainerId
) => {
  try {
    const response = await api.post(`/gtm/${projectId}/save-container`, {
      gtmAccountId,
      gtmContainerId,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao salvar container do GTM no projeto:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para remover a integração do Google Tag Manager de um projeto
export const resetGtmFields = async (projectId) => {
  try {
    const response = await api.post(`/gtm/${projectId}/reset-gtm`);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erro ao remover integração do GTM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para obter detalhes das contas Google Ads disponíveis
export const getGoogleAdsAccountsDetails = async (projectId) => {
  try {
    const response = await api.get(`/gads/${projectId}/accounts-details`);
    return response.data;
  } catch (error) {
    console.error("Erro ao obter detalhes das contas Google Ads:", error);
    return {
      success: false,
      error: true,
      message:
        error.response?.data?.message || "Erro ao buscar contas Google Ads",
    };
  }
};

// Função para verificar a integração com o Google Ads
export const verifyGoogleAdsIntegration = async (projectId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`/gads/${projectId}/verify-integration`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      success: false,
      integration_status: "error",
      error: {
        message: error.message || "Erro ao verificar integração com Google Ads",
      },
    };
  }
};

// Função para resetar o campo admanagerId de um projeto
export const resetAdManagerFields = async (projectId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.delete(`/projects/${projectId}/admanager`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao resetar integração do Google Ad Manager:", error);
    throw error;
  }
};

// Função para resetar os campos do Google Ad Manager de um projeto
export const resetGoogleAdManagerFields = async (projectId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      `/projects/${projectId}/reset-google-admanager`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao resetar integração do Google Ad Manager:", error);
    throw error;
  }
};

// Função para listar as tags de conversão do Google Ads
export const listConversionActions = async (projectId, params) => {
  try {
    const response = await api.post("/gads/list-conversion-actions", {
      projectId,
      ...params,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao listar tags de conversão:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para buscar tags no Google Tag Manager
export const searchGtmTags = async (projectId, tagName, exactMatch = true) => {
  try {
    const response = await api.get(`/gtm/${projectId}/search-tags`, {
      params: { tagName, exactMatch },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar tags no GTM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para criar uma ação de conversão no Google Ads
export const createConversionAction = async (projectId, name) => {
  try {
    const response = await api.post("/gads/create-conversion-action", {
      projectId,
      name,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao criar ação de conversão:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para criar um objetivo de conversão no Google Ads
export const createConversionGoal = async (
  projectId,
  conversionActionResourceName,
  name
) => {
  try {
    const response = await api.post("/gads/create-conversion-goal", {
      projectId,
      conversionActionResourceName,
      name,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao criar objetivo de conversão:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para criar uma tag no Google Tag Manager
export const createGtmTag = async (projectId, tagConfig) => {
  try {
    const response = await api.post(`/gtm/${projectId}/create-tag`, tagConfig);
    return response.data;
  } catch (error) {
    console.error("Erro ao criar tag no GTM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

// Função para publicar alterações no Google Tag Manager
export const publishGtmChanges = async (projectId, notes) => {
  try {
    const response = await api.post(`/gtm/${projectId}/publish`, { notes });
    return response.data;
  } catch (error) {
    console.error("Erro ao publicar alterações no GTM:", error);
    return { success: false, error: error.response?.data || error.message };
  }
};

export const toggleAutomaticCpcForCampaign = async ({
  projectId,
  campaignId,
  adGroupId,
  enabled,
}) => {
  try {
    const response = await api.post("gads/toggle-automatic-cpc", {
      projectId,
      campaignId,
      adGroupId,
      enabled,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao ajustar CPC automático:", error);
    return {
      success: false,
      error: error.response?.data || "Erro ao ajustar CPC automático",
    };
  }
};
