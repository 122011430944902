import React, { useState, useEffect } from "react";
import {
  FaTag,
  FaExternalLinkAlt,
  FaCheck,
  FaSpinner,
  FaTrash,
  FaExclamationTriangle,
} from "react-icons/fa";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import {
  getGtmAuthUrl,
  listGtmAccounts,
  listGtmContainers,
  saveGtmContainer,
  resetGtmFields,
} from "../../utils/api";

const IntegrateGtm = ({
  projectId,
  existingGtmAccountId,
  existingGtmContainerId,
}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [removingGtm, setRemovingGtm] = useState(false);
  const [gtmAccounts, setGtmAccounts] = useState([]);
  const [containers, setContainers] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContainer, setSelectedContainer] = useState("");
  const [integrated, setIntegrated] = useState(
    existingGtmAccountId && existingGtmContainerId
  );
  const [authError, setAuthError] = useState(null);
  const [authWarning, setAuthWarning] = useState(null);
  const [accountName, setAccountName] = useState("");
  const [containerName, setContainerName] = useState("");
  const [containerPublicId, setContainerPublicId] = useState("");

  // Verificar parâmetros de URL para feedback da autenticação
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Verificar se há mensagem de sucesso
    if (params.get("gtm-success") === "true") {
      toast.success(
        "Autenticação com o Google Tag Manager realizada com sucesso!"
      );
      // Limpa os parâmetros da URL para evitar mensagens duplicadas em recargas
      cleanUrlParams();
    }

    // Verificar se há erro na autenticação
    const errorParam = params.get("gtm-error");
    if (errorParam) {
      let errorMessage = "Erro ao autenticar com o Google Tag Manager.";

      switch (errorParam) {
        case "token_invalido":
          errorMessage = "O token de acesso recebido é inválido.";
          break;
        case "projeto_nao_encontrado":
          errorMessage = "Projeto não encontrado durante a autenticação.";
          break;
        case "erro_permissao":
          errorMessage =
            "Você não tem permissões suficientes para acessar o Tag Manager.";
          break;
        case "erro_autenticacao":
          errorMessage = "Falha na autenticação com o Google Tag Manager.";
          break;
      }

      setAuthError(errorMessage);
      toast.error(errorMessage);
      cleanUrlParams();
    }

    // Verificar se há avisos
    const warningParam = params.get("gtm-warning");
    if (warningParam) {
      let warningMessage = "Atenção ao integrar com o Google Tag Manager.";

      if (warningParam === "token_nao_validado") {
        warningMessage =
          "Não foi possível validar o token, mas a integração foi salva. Tente novamente se necessário.";
      }

      setAuthWarning(warningMessage);
      toast.warn(warningMessage);
      cleanUrlParams();
    }
  }, [location]);

  // Função para limpar os parâmetros da URL
  const cleanUrlParams = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // Remove todos os parâmetros relacionados ao GTM
    params.delete("gtm-success");
    params.delete("gtm-error");
    params.delete("gtm-warning");

    // Atualiza a URL sem recarregar a página
    const newUrl = `${url.pathname}${
      params.toString() ? `?${params.toString()}` : ""
    }`;
    window.history.replaceState({}, document.title, newUrl);
  };

  // Carregar contas do GTM se houver token e se não estiver integrado
  useEffect(() => {
    // Se já está integrado, não fazer requisições desnecessárias
    if (integrated && existingGtmAccountId && existingGtmContainerId) {
      setSelectedAccount(existingGtmAccountId);
      setSelectedContainer(existingGtmContainerId);
      return;
    }

    const fetchGtmAccounts = async () => {
      try {
        setLoading(true);
        const result = await listGtmAccounts(projectId);
        if (result.accounts) {
          setGtmAccounts(result.accounts);

          // Se já tem conta integrada, seleciona-a
          if (existingGtmAccountId) {
            setSelectedAccount(existingGtmAccountId);

            // Guardar o nome da conta para exibição
            const account = result.accounts.find(
              (a) => a.accountId === existingGtmAccountId
            );
            if (account) {
              setAccountName(account.name);
            }
          }
        }
      } catch (error) {
        console.error("Erro ao buscar contas GTM:", error);
        // Se obtivermos uma resposta 401, o token não é válido
        if (error.response?.status === 401) {
          setAuthError(
            "O token de autenticação do GTM expirou ou é inválido. Por favor, reconecte sua conta."
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchGtmAccounts();
  }, [projectId, existingGtmAccountId, integrated]);

  // Quando uma conta é selecionada, carregar seus containers se não estiver integrado
  useEffect(() => {
    // Se já está integrado, não fazer requisições desnecessárias
    if (integrated && existingGtmAccountId && existingGtmContainerId) {
      return;
    }

    const fetchContainers = async () => {
      if (!selectedAccount) {
        setContainers([]);
        return;
      }

      try {
        setLoading(true);
        const result = await listGtmContainers(projectId, selectedAccount);
        if (result.containers) {
          setContainers(result.containers);

          // Se já tem container integrado, seleciona-o
          if (existingGtmContainerId) {
            setSelectedContainer(existingGtmContainerId);

            // Guardar nome e ID público do container para exibição
            const container = result.containers.find(
              (c) => c.containerId === existingGtmContainerId
            );
            if (container) {
              setContainerName(container.name);
              setContainerPublicId(container.publicId);
            }
          }
        }
      } catch (error) {
        console.error("Erro ao buscar containers:", error);
        toast.error("Erro ao carregar containers");
      } finally {
        setLoading(false);
      }
    };

    fetchContainers();
  }, [selectedAccount, projectId, existingGtmContainerId, integrated]);

  const handleGtmAuthentication = async () => {
    try {
      setLoading(true);
      const authResult = await getGtmAuthUrl(projectId);

      if (authResult.authUrl) {
        window.location.href = authResult.authUrl;
      } else {
        throw new Error("URL de autenticação não recebida");
      }
    } catch (error) {
      console.error("Erro na autenticação GTM:", error);
      toast.error("Erro ao iniciar autenticação do GTM");
      setLoading(false);
    }
  };

  const handleSaveContainer = async () => {
    if (!selectedAccount || !selectedContainer) {
      toast.error("Selecione uma conta e um container");
      return;
    }

    try {
      setLoading(true);
      const result = await saveGtmContainer(
        projectId,
        selectedAccount,
        selectedContainer
      );

      if (result.success || !result.error) {
        toast.success("Container do GTM integrado com sucesso!");
        setIntegrated(true);

        // Guardar informações selecionadas para exibição
        const account = gtmAccounts.find(
          (a) => a.accountId === selectedAccount
        );
        if (account) {
          setAccountName(account.name);
        }

        const container = containers.find(
          (c) => c.containerId === selectedContainer
        );
        if (container) {
          setContainerName(container.name);
          setContainerPublicId(container.publicId);
        }

        // Limpa mensagens de erro/aviso quando a integração é bem-sucedida
        setAuthError(null);
        setAuthWarning(null);
      } else {
        throw new Error(result.error || "Erro ao integrar container");
      }
    } catch (error) {
      console.error("Erro ao salvar container:", error);
      toast.error("Erro ao salvar configuração do GTM");
    } finally {
      setLoading(false);
    }
  };

  const handleResetGtm = async () => {
    if (
      !window.confirm(
        "Tem certeza que deseja remover a integração com o Google Tag Manager?"
      )
    ) {
      return;
    }

    try {
      setRemovingGtm(true);
      const result = await resetGtmFields(projectId);

      if (result.success) {
        setIntegrated(false);
        setSelectedAccount("");
        setSelectedContainer("");
        setAccountName("");
        setContainerName("");
        setContainerPublicId("");
        setAuthError(null);
        setAuthWarning(null);
        toast.success(
          "Integração com o Google Tag Manager removida com sucesso"
        );
      } else {
        throw new Error(result.error || "Erro ao remover integração");
      }
    } catch (error) {
      console.error("Erro ao remover integração do GTM:", error);
      toast.error("Erro ao remover integração: " + error.message);
    } finally {
      setRemovingGtm(false);
    }
  };

  // Renderizar mensagens de erro ou aviso
  const renderAuthMessages = () => {
    if (!authError && !authWarning) return null;

    return (
      <div className="mb-4">
        {authError && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md flex items-start">
            <FaExclamationTriangle className="text-red-500 mt-1 mr-2 flex-shrink-0" />
            <p className="text-sm">{authError}</p>
          </div>
        )}

        {authWarning && (
          <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded-md flex items-start">
            <FaExclamationTriangle className="text-yellow-500 mt-1 mr-2 flex-shrink-0" />
            <p className="text-sm">{authWarning}</p>
          </div>
        )}
      </div>
    );
  };

  // Renderizar o indicador de carregamento global
  const renderGlobalLoadingIndicator = () => {
    if (!loading || removingGtm) return null;

    return (
      <div className="flex items-center justify-center py-2 mb-3 bg-blue-50 rounded-md">
        <FaSpinner className="animate-spin text-blue-500 mr-2" />
        <span className="text-sm text-blue-700">
          Carregando dados do Google Tag Manager...
        </span>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      {renderAuthMessages()}
      {renderGlobalLoadingIndicator()}

      {integrated ? (
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              <span className="text-green-700">
                Integrado com Google Tag Manager
              </span>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setIntegrated(false)}
                disabled={removingGtm}
                className={`text-sm text-blue-600 hover:underline ${
                  removingGtm ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Alterar
              </button>
              <button
                onClick={handleResetGtm}
                disabled={removingGtm}
                className={`text-sm text-red-600 hover:underline flex items-center gap-1 ${
                  removingGtm ? "opacity-50" : ""
                }`}
              >
                {removingGtm ? (
                  <>
                    <FaSpinner className="animate-spin" size={12} />
                    <span>Removendo...</span>
                  </>
                ) : (
                  <>
                    <FaTrash size={12} />
                    <span>Remover</span>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="bg-gray-50 p-3 rounded-md">
            <p className="text-sm mb-1">
              <strong>Conta:</strong> {accountName || existingGtmAccountId}
            </p>
            <p className="text-sm mb-1">
              <strong>Container:</strong>{" "}
              {containerName || existingGtmContainerId}
            </p>
            {containerPublicId && (
              <p className="text-sm">
                <strong>ID Público:</strong> {containerPublicId}
              </p>
            )}
          </div>
        </div>
      ) : gtmAccounts.length > 0 ? (
        <>
          <div className="space-y-3">
            <div>
              <label
                htmlFor="gtm-account"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Conta GTM
              </label>
              <select
                id="gtm-account"
                className="w-full border border-gray-300 rounded-md p-2 text-sm"
                value={selectedAccount}
                onChange={(e) => {
                  setSelectedAccount(e.target.value);
                  setSelectedContainer("");
                }}
                disabled={loading}
              >
                <option value="">Selecione uma conta</option>
                {gtmAccounts.map((account) => (
                  <option key={account.accountId} value={account.accountId}>
                    {account.name} ({account.accountId})
                  </option>
                ))}
              </select>
            </div>

            {selectedAccount && (
              <div>
                <label
                  htmlFor="gtm-container"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Container
                </label>
                {loading ? (
                  <div className="flex items-center space-x-2 bg-gray-50 p-2 rounded-md">
                    <FaSpinner className="animate-spin text-blue-500" />
                    <span className="text-sm text-gray-500">
                      Carregando containers...
                    </span>
                  </div>
                ) : containers.length > 0 ? (
                  <select
                    id="gtm-container"
                    className="w-full border border-gray-300 rounded-md p-2 text-sm"
                    value={selectedContainer}
                    onChange={(e) => setSelectedContainer(e.target.value)}
                  >
                    <option value="">Selecione um container</option>
                    {containers.map((container) => (
                      <option
                        key={container.containerId}
                        value={container.containerId}
                      >
                        {container.name} ({container.publicId})
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="text-sm text-gray-500 bg-gray-50 p-2 rounded-md">
                    Nenhum container disponível para esta conta
                  </p>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-end pt-2">
            <button
              onClick={handleSaveContainer}
              disabled={loading || !selectedAccount || !selectedContainer}
              className={`flex items-center gap-2 px-4 py-2 rounded-md ${
                loading || !selectedAccount || !selectedContainer
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700 text-white"
              }`}
            >
              {loading ? <FaSpinner className="animate-spin" /> : <FaTag />}
              <span>Integrar Container</span>
            </button>
          </div>
        </>
      ) : (
        <div className="text-center py-4">
          <FaTag className="text-gray-400 text-4xl mx-auto mb-4" />
          <h3 className="text-lg font-medium mb-2">
            Integre com o Google Tag Manager
          </h3>
          <p className="text-sm text-gray-600 mb-4">
            Conecte o Tag Manager para gerenciar as tags do seu site ou
            aplicativo.
          </p>
          <button
            onClick={handleGtmAuthentication}
            disabled={loading}
            className={`flex items-center gap-2 px-4 py-2 mx-auto rounded-md ${
              loading
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 text-white"
            }`}
          >
            {loading ? (
              <FaSpinner className="animate-spin" />
            ) : (
              <FaExternalLinkAlt />
            )}
            <span>Conectar ao Google Tag Manager</span>
          </button>
          {authError && (
            <p className="text-sm text-red-600 mt-4">
              <FaExclamationTriangle className="inline mr-1" />
              Reconecte sua conta para resolver o problema de autenticação.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default IntegrateGtm;
