import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FaExclamationTriangle, FaCheckCircle, FaTimes } from "react-icons/fa";

/**
 * Modal de consentimento para template de acompanhamento
 *
 * @param {Object} props
 * @param {boolean} props.isOpen - Indica se o modal está aberto
 * @param {Function} props.onClose - Função chamada quando o modal é fechado
 * @param {Function} props.onConfirm - Função chamada quando o usuário confirma a ação
 * @param {string} props.templateName - Nome do template selecionado
 * @param {React.ReactNode|string} props.templateDescription - Descrição do template selecionado (pode ser JSX)
 */
const TrackingTemplateConsentModal = ({
  isOpen,
  onClose,
  onConfirm,
  templateName = "",
  templateDescription = "",
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-start">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex items-center"
                  >
                    <FaExclamationTriangle className="text-amber-500 mr-2" />
                    Confirmação de Aplicação de Template
                  </Dialog.Title>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <FaTimes />
                  </button>
                </div>

                <div className="mt-4">
                  <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-4">
                    <p className="text-sm text-amber-700">
                      Você está prestes a aplicar o template de acompanhamento{" "}
                      <strong>{templateName}</strong>. Esta ação irá modificar
                      configurações em sua conta do Google Ads.
                    </p>
                  </div>

                  <h4 className="font-medium text-gray-900 mb-2">
                    Descrição do Template:
                  </h4>
                  <div className="text-sm text-gray-600 mb-4">
                    {typeof templateDescription === "string" ? (
                      <p>
                        {templateDescription ||
                          "Template de acompanhamento padrão."}
                      </p>
                    ) : (
                      templateDescription
                    )}
                  </div>

                  <h4 className="font-medium text-gray-900 mb-2">
                    O que vai acontecer:
                  </h4>
                  <ul className="list-disc list-inside text-sm text-gray-600 mb-4 space-y-1">
                    <li>
                      Alterações serão feitas nas configurações de sua conta do
                      Google Ads
                    </li>
                    <li>
                      Serão aplicados templates de rastreamento em suas
                      campanhas
                    </li>
                    <li>
                      Estruturas de URL serão atualizadas para incluir
                      parâmetros de rastreamento
                    </li>
                    <li>
                      As alterações podem afetar a forma como suas campanhas são
                      monitoradas
                    </li>
                  </ul>

                  <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
                    <p className="text-sm text-red-700 font-medium">
                      Termo de Responsabilidade:
                    </p>
                    <p className="text-sm text-red-700 mt-1">
                      A KaizenAds não se responsabiliza por quaisquer
                      consequências diretas ou indiretas resultantes da
                      aplicação deste template. Ao confirmar, você reconhece que
                      está ciente das alterações que serão feitas e assume total
                      responsabilidade por elas.
                    </p>
                  </div>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={onClose}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={onConfirm}
                  >
                    <FaCheckCircle className="mr-2" />
                    Confirmar e Prosseguir
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TrackingTemplateConsentModal;
