// LastCpcAdjust.jsx

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import dayjs from "dayjs";
import { FaArrowUp, FaArrowDown, FaCalendar } from "react-icons/fa";
import { getCpcLogs } from "../utils/api";

const LastCpcAdjust = ({ campaignId, adGroupId, projectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [cpcLogs, setCpcLogs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCpcLogs = async (date) => {
    try {
      setIsLoading(true);
      const response = await getCpcLogs(date, campaignId, adGroupId, projectId);

      // Ordenar os logs por data e hora em ordem decrescente
      if (response.logs) {
        response.logs.sort((a, b) => {
          const dateA = `${response.date} ${a.timestamp}`;
          const dateB = `${response.date} ${b.timestamp}`;
          return (
            dayjs(dateB, "YYYY-MM-DD HH:mm:ss").valueOf() -
            dayjs(dateA, "YYYY-MM-DD HH:mm:ss").valueOf()
          );
        });
      }

      setCpcLogs(response);
    } catch (error) {
      console.error("Erro ao buscar logs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchCpcLogs(selectedDate);
    }
  }, [isModalOpen, selectedDate]);

  const lastLog = cpcLogs?.logs?.[cpcLogs.logs.length - 1];

  const isIncreased = lastLog?.newCpc > lastLog?.currentCpc;
  const isDecreased = lastLog?.newCpc < lastLog?.currentCpc;
  const lastAdjustTime = lastLog ? lastLog.timestamp : "N/A";

  const openModal = (event) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const renderModal = () => {
    return ReactDOM.createPortal(
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={closeModal}
      >
        <div
          className="bg-white rounded-lg max-w-2xl w-full relative mx-4 max-h-[80vh] flex flex-col"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-6 border-b">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>

            <div className="flex items-center justify-between">
              <h2 className="text-xl font-bold">Histórico de Ajustes de CPC</h2>
              <div className="flex items-center gap-2">
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="border rounded px-2 py-1 text-sm"
                />
                <FaCalendar className="text-gray-500" />
              </div>
            </div>
          </div>

          <div className="p-6 overflow-y-auto flex-1">
            {isLoading ? (
              <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <div className="space-y-4">
                {cpcLogs?.logs?.map((log, index) => {
                  const isIncreased = log.newCpc > log.currentCpc;
                  const isDecreased = log.newCpc < log.currentCpc;
                  const changePercentage = (
                    ((log.newCpc - log.currentCpc) / log.currentCpc) *
                    100
                  ).toFixed(2);
                  const changeColor = isIncreased
                    ? "text-green-500"
                    : isDecreased
                    ? "text-red-500"
                    : "text-gray-500";

                  return (
                    <div
                      key={index}
                      className="p-4 bg-gray-50 rounded-lg shadow-sm hover:shadow-md transition-shadow"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-medium text-gray-600">
                          {log.timestamp}
                        </span>
                        <div className="flex items-center gap-3">
                          <div className="flex items-center gap-1">
                            <span className="text-sm font-medium">
                              R$ {log.currentCpc}
                            </span>
                            <span className="text-gray-400 mx-1">→</span>
                            <span
                              className={`text-sm font-medium ${changeColor}`}
                            >
                              R$ {log.newCpc}
                            </span>
                          </div>
                          <div
                            className={`flex items-center gap-1 ${changeColor}`}
                          >
                            {isIncreased && (
                              <>
                                <FaArrowUp />
                                <span className="text-xs">
                                  +{changePercentage}%
                                </span>
                              </>
                            )}
                            {isDecreased && (
                              <>
                                <FaArrowDown />
                                <span className="text-xs">
                                  {changePercentage}%
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="text-sm text-gray-500">{log.reason}</p>
                    </div>
                  );
                })}

                {(!cpcLogs?.logs || cpcLogs.logs.length === 0) && (
                  <div className="text-center text-gray-500 py-8">
                    Nenhum log encontrado para esta data
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>,
      document.body
    );
  };

  return (
    <>
      <button
        className="inline-flex items-center gap-2 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded hover:bg-gray-200 transition-colors"
        onClick={openModal}
      >
        <span className="flex items-center">
          {isIncreased && <FaArrowUp className="text-green-500" />}
          {isDecreased && <FaArrowDown className="text-red-500" />}
        </span>
        Ver logs
      </button>

      {isModalOpen && renderModal()}
    </>
  );
};

export default LastCpcAdjust;
