import React from "react";
import { FaLink, FaSpinner, FaCheck } from "react-icons/fa";
import toast from "react-hot-toast";

const UrlConfigStep = ({
  selectedProject,
  targetUrl,
  setTargetUrl,
  urlLoading,
  setUrlLoading,
  urlFetched,
  setUrlFetched,
  postId,
  setPostId,
  onBack,
  onNext,
}) => {
  if (!selectedProject) return null;

  const handleUrlSubmit = async (e) => {
    e.preventDefault();

    if (!targetUrl.trim()) {
      toast.error("Por favor, informe a URL de destino");
      return;
    }

    try {
      setUrlLoading(true);

      // Fazer a requisição para buscar o conteúdo da URL
      const response = await fetch(targetUrl);

      if (!response.ok) {
        throw new Error(`Erro ao acessar a URL: ${response.statusText}`);
      }

      const html = await response.text();

      // Extrair o post ID do link shortlink
      const shortlinkRegex = /<link rel='shortlink' href='[^']*\?p=(\d+)' \/>/;
      const match = html.match(shortlinkRegex);

      if (match && match[1]) {
        setPostId(match[1]);
        toast.success(`URL processada com sucesso! Post ID: ${match[1]}`);
        setUrlFetched(true);
      } else {
        // Tenta buscar pelo ID na tag article como fallback
        const articleRegex = /<article\s+[^>]*id=["']post-(\d+)["'][^>]*>/;
        const articleMatch = html.match(articleRegex);

        if (articleMatch && articleMatch[1]) {
          setPostId(articleMatch[1]);
          toast.success(
            `URL processada com sucesso! Post ID: ${articleMatch[1]}`
          );
          setUrlFetched(true);
        } else {
          toast.error(
            "Não foi possível encontrar o Post ID na URL automaticamente. Por favor, informe manualmente."
          );
          // Mesmo sem o Post ID, permitimos ir para o próximo passo, onde o usuário poderá inserir manualmente
          setUrlFetched(true);
          setPostId("");
        }
      }
    } catch (error) {
      console.error("Erro ao processar URL:", error);
      toast.error(`Erro ao processar URL: ${error.message}`);
      // Permite prosseguir mesmo com erro, para inserção manual do Post ID
      setUrlFetched(true);
      setPostId("");
    } finally {
      setUrlLoading(false);
    }
  };

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
      <div className="flex items-center mb-6">
        <div className="bg-blue-100 p-2 rounded-full mr-3">
          <FaLink className="text-blue-500" />
        </div>
        <div>
          <h2 className="text-xl font-medium">
            URL da Página com Botões para {selectedProject.projectName}
          </h2>
          <p className="text-gray-500 text-sm">
            Informe a URL da página onde estão os botões que deseja rastrear
          </p>
        </div>
      </div>

      <form onSubmit={handleUrlSubmit}>
        <div className="mb-4">
          <label
            htmlFor="targetUrl"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            URL da Página
          </label>
          <input
            type="url"
            id="targetUrl"
            value={targetUrl}
            onChange={(e) => setTargetUrl(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="Ex: https://exemplo.com/pagina-com-botoes"
            required
            disabled={urlFetched}
          />
          <p className="text-xs text-gray-500 mt-1">
            A URL completa da página onde estão os botões que deseja rastrear
            como conversões.
          </p>
        </div>

        <div className="flex justify-end space-x-3 mt-6">
          <button
            type="button"
            onClick={onBack}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Voltar
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
            disabled={urlLoading || urlFetched}
          >
            {urlLoading ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Processando...
              </>
            ) : urlFetched ? (
              <>
                <FaCheck className="mr-2" />
                URL Processada
              </>
            ) : (
              "Processar URL"
            )}
          </button>
          {urlFetched && (
            <button
              type="button"
              onClick={onNext}
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center"
            >
              Continuar
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default UrlConfigStep;
