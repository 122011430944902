import React, { useCallback, memo } from "react";

// Componente de botão memoizado para evitar re-renderizações desnecessárias
const ActionButton = memo(({ onClick, disabled, className, children }) => (
  <button
    type="button"
    className={className}
    disabled={disabled}
    onMouseDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) onClick();
    }}
  >
    {children}
  </button>
));

// Componente de spinner memoizado para evitar re-renderizações
const LoadingSpinner = memo(() => (
  <div
    className="w-4 h-4 border-2 border-white border-t-transparent border-solid rounded-full animate-spin mr-2"
    style={{
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
    }}
  ></div>
));

const AiRecommendationModal = ({
  aiRecommendation,
  onClose,
  onApply,
  applyingRecommendations,
}) => {
  // Função para formatar o texto da explicação em parágrafos
  const formatExplanation = (text) => {
    if (!text) return [];

    // Regex mais precisa para identificar pontos que não fazem parte de números decimais
    // Usa lookahead e lookbehind para verificar se há dígitos antes e depois do ponto
    const regex = /(?<!\d)\.(?!\d)(?=\s|$)/g;

    // Divide o texto em frases usando a regex
    const sentences = text.split(regex);

    // Filtra frases vazias e adiciona o ponto final de volta
    return sentences
      .map((sentence) => sentence.trim())
      .filter((sentence) => sentence.length > 0)
      .map((sentence) => (sentence.endsWith(".") ? sentence : sentence + "."));
  };

  const explanationParagraphs = formatExplanation(aiRecommendation.explicacao);

  // Funções de manipulação de eventos com useCallback para evitar recriações
  const closeModal = useCallback(() => {
    if (applyingRecommendations) return;
    onClose();
  }, [applyingRecommendations, onClose]);

  const applyRecommendations = useCallback(() => {
    if (applyingRecommendations) return;
    onApply();
  }, [applyingRecommendations, onApply]);

  // Memoizando o conteúdo do botão de aplicar para evitar re-renderizações
  const applyButtonContent = React.useMemo(() => {
    return applyingRecommendations ? (
      <>
        <LoadingSpinner />
        <span>Aplicando...</span>
      </>
    ) : (
      "Aplicar Recomendações"
    );
  }, [applyingRecommendations]);

  return (
    <div className="fixed inset-0 z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={closeModal}
      ></div>
      <div className="fixed inset-0 flex items-center justify-center pointer-events-none">
        <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[85vh] overflow-y-auto pointer-events-auto">
          <div className="flex justify-between items-center mb-5">
            <h3 className="text-xl font-medium text-gray-800">
              Recomendações Inteligentes
            </h3>
            <ActionButton
              onClick={closeModal}
              disabled={applyingRecommendations}
              className="text-gray-400 hover:text-gray-600 transition-colors focus:outline-none"
              aria-label="Fechar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </ActionButton>
          </div>

          <div className="mb-6">
            <div className="text-gray-600 mb-5 space-y-4 bg-gray-50 p-5 rounded-lg">
              {explanationParagraphs.map((paragraph, index) => (
                <p key={index} className="leading-relaxed text-sm">
                  {paragraph}
                </p>
              ))}
            </div>

            <div className="overflow-hidden rounded-lg border border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Período
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      CPC Min
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      CPC Max
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {["dawn", "morning", "afternoon", "evening"].map((period) => (
                    <tr key={period} className="hover:bg-gray-50">
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-700">
                        {period === "dawn"
                          ? "00:00-05:59"
                          : period === "morning"
                          ? "06:00-11:59"
                          : period === "afternoon"
                          ? "12:00-17:59"
                          : "18:00-23:59"}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                        {aiRecommendation.cpcMin[period] !== null
                          ? aiRecommendation.cpcMin[period]
                          : "Sem dados"}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                        {aiRecommendation.cpcMax[period] !== null
                          ? aiRecommendation.cpcMax[period]
                          : "Sem dados"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <ActionButton
              onClick={closeModal}
              disabled={applyingRecommendations}
              className="px-4 py-2 text-sm border border-gray-200 rounded text-gray-600 hover:bg-gray-50 transition-colors focus:outline-none"
            >
              Cancelar
            </ActionButton>
            <ActionButton
              onClick={applyRecommendations}
              disabled={applyingRecommendations}
              className="px-4 py-2 text-sm bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors focus:outline-none flex items-center justify-center min-w-[140px]"
            >
              {applyButtonContent}
            </ActionButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AiRecommendationModal);
