import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  getCurrentCpc,
  setCpcMin,
  setCpcMax,
  getCpcLimits,
} from "../utils/api";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import AiRecommendationModal from "./AiRecommendationModal"; // Importando o novo componente

// Componente de spinner memoizado para evitar re-renderizações
const LoadingSpinner = React.memo(({ color = "blue-500" }) => (
  <div
    className={`w-4 h-4 border-2 border-${color} border-t-transparent border-solid rounded-full animate-spin mr-2`}
    style={{
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
    }}
  ></div>
));

const CpcEditor = ({ campaignId, adGroupId, projectId, onClose }) => {
  const [cpcMinValues, setCpcMinValues] = useState({
    dawn: "",
    morning: "",
    afternoon: "",
    evening: "",
  });
  const [cpcMaxValues, setCpcMaxValues] = useState({
    dawn: "",
    morning: "",
    afternoon: "",
    evening: "",
  });

  const [loading, setLoading] = useState(true); // Estado para o loading
  const [aiLoading, setAiLoading] = useState(false); // Estado para loading da análise IA
  const [aiLoadingStep, setAiLoadingStep] = useState(""); // Estado para mostrar o passo atual da análise
  const [aiRecommendation, setAiRecommendation] = useState(null); // Estado para armazenar recomendações da IA
  const [showAiModal, setShowAiModal] = useState(false); // Estado para controlar a exibição do modal
  const [applyingRecommendations, setApplyingRecommendations] = useState(false); // Estado para controlar o loading do botão de aplicar recomendações

  // Referências para armazenar os valores anteriores
  const previousMinValues = useRef({});
  const previousMaxValues = useRef({});

  // Função para formatar o valor, convertendo vírgula para ponto
  const formatValue = useCallback((value) => {
    if (!value) return null; // Retorna null para valores vazios
    const formattedValue = value.toString().replace(",", ".");
    return parseFloat(formattedValue); // Converte para float
  }, []);

  // Usando useCallback para funções que são passadas como props para outros componentes
  const fetchCpcValues = useCallback(async () => {
    try {
      setLoading(true); // Inicia o loading
      const cpcData = await getCurrentCpc(projectId, campaignId, adGroupId);
      if (cpcData) {
        const { other } = cpcData;

        const initialMinValues = {
          dawn: other?.cpcMin?.dawn || "",
          morning: other?.cpcMin?.morning || "",
          afternoon: other?.cpcMin?.afternoon || "",
          evening: other?.cpcMin?.evening || "",
        };

        const initialMaxValues = {
          dawn: other?.cpcMax?.dawn || "",
          morning: other?.cpcMax?.morning || "",
          afternoon: other?.cpcMax?.afternoon || "",
          evening: other?.cpcMax?.evening || "",
        };

        setCpcMinValues(initialMinValues);
        setCpcMaxValues(initialMaxValues);

        // Armazena os valores iniciais
        previousMinValues.current = { ...initialMinValues };
        previousMaxValues.current = { ...initialMaxValues };
      }
    } catch (error) {
      toast.error("Erro ao carregar os valores de CPC.");
    } finally {
      setLoading(false); // Termina o loading
    }
  }, [projectId, campaignId, adGroupId]);

  const updateCpcValue = useCallback(
    async (fieldType, period, value, projectId) => {
      try {
        // Formata o valor para float ou null
        const formattedValue = formatValue(value);

        let response;
        if (fieldType === "min") {
          // Cria um objeto com todos os valores atuais e atualiza apenas o período específico
          const updatedValues = {
            dawn: formatValue(cpcMinValues.dawn),
            morning: formatValue(cpcMinValues.morning),
            afternoon: formatValue(cpcMinValues.afternoon),
            evening: formatValue(cpcMinValues.evening),
            [period]: formattedValue, // Já formatado como float ou null
          };

          response = await setCpcMin(
            campaignId,
            adGroupId,
            updatedValues,
            projectId
          );
          setCpcMinValues((prev) => ({ ...prev, [period]: value }));
        } else if (fieldType === "max") {
          // Cria um objeto com todos os valores atuais e atualiza apenas o período específico
          const updatedValues = {
            dawn: formatValue(cpcMaxValues.dawn),
            morning: formatValue(cpcMaxValues.morning),
            afternoon: formatValue(cpcMaxValues.afternoon),
            evening: formatValue(cpcMaxValues.evening),
            [period]: formattedValue, // Já formatado como float ou null
          };

          response = await setCpcMax(
            campaignId,
            adGroupId,
            updatedValues,
            projectId
          );
          setCpcMaxValues((prev) => ({ ...prev, [period]: value }));
        }

        // Verifica se o status da resposta é 201 (Created)
        if (response && response.success) {
          // Atualiza o valor armazenado após a mudança
          if (fieldType === "min") {
            previousMinValues.current[period] = value;
          } else {
            previousMaxValues.current[period] = value;
          }
          toast.success(`${period} atualizado com sucesso!`);
        } else {
          toast.error(
            `Erro ao atualizar o CPC: ${
              response?.message || "Erro desconhecido"
            }`
          );
        }
      } catch (error) {
        toast.error(
          `Erro ao atualizar o CPC: ${error.message || "Erro desconhecido"}`
        );
      }
    },
    [campaignId, adGroupId, cpcMinValues, cpcMaxValues, formatValue]
  );

  const handleSaveCpc = useCallback(
    (fieldType, period, value, projectId) => {
      // Se o modal de IA estiver aberto, não permite editar os valores
      if (showAiModal) {
        toast.error(
          "Feche o modal de recomendações antes de editar os valores."
        );
        return;
      }

      // Verifica se o valor é válido
      if (!value) {
        // Se o campo estiver vazio, envia como null
        updateCpcValue(fieldType, period, null, projectId);
        return;
      }

      const currentValue =
        fieldType === "min"
          ? previousMinValues.current[period]
          : previousMaxValues.current[period];

      // Converte para float para comparação
      const newValue = formatValue(value);
      const prevValue = formatValue(currentValue);

      // Verifica se o valor mudou em relação ao valor atual
      if (newValue === prevValue) return;

      // Atualiza o valor diretamente, sem confirmação
      updateCpcValue(fieldType, period, value, projectId);
    },
    [showAiModal, formatValue, updateCpcValue]
  );

  // Função para buscar recomendações de CPC da IA
  const fetchAiRecommendations = useCallback(async () => {
    // Evita abrir o modal se já estiver aberto
    if (showAiModal) return;

    try {
      setAiLoading(true);
      // Obter a data de ontem usando dayjs
      const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

      // Simular etapas de análise com pequenos atrasos
      setAiLoadingStep("Buscando histórico de desempenho...");
      await new Promise((resolve) => setTimeout(resolve, 800));

      setAiLoadingStep("Analisando padrões de conversão por horário...");
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setAiLoadingStep("Calculando CPCs ideais baseados em ROI...");
      await new Promise((resolve) => setTimeout(resolve, 1200));

      setAiLoadingStep("Finalizando recomendações...");

      const response = await getCpcLimits(
        projectId,
        campaignId,
        adGroupId,
        yesterday // Usando a data de ontem
      );

      if (response.success) {
        setAiRecommendation(response.data);
        setShowAiModal(true);
      } else {
        toast.error("Não foi possível obter recomendações da IA.");
      }
    } catch (error) {
      toast.error("Erro ao buscar recomendações de CPC da IA.");
    } finally {
      setAiLoading(false);
      setAiLoadingStep("");
    }
  }, [showAiModal, projectId, campaignId, adGroupId]);

  // Função para aplicar as recomendações da IA
  const applyAiRecommendations = useCallback(async () => {
    if (!aiRecommendation || applyingRecommendations) return; // Evita múltiplas chamadas

    try {
      setApplyingRecommendations(true);

      // Atualiza os valores de CPC Min localmente
      const newMinValues = {
        dawn:
          aiRecommendation.cpcMin.dawn !== null
            ? parseFloat(aiRecommendation.cpcMin.dawn)
            : formatValue(cpcMinValues.dawn),
        morning:
          aiRecommendation.cpcMin.morning !== null
            ? parseFloat(aiRecommendation.cpcMin.morning)
            : formatValue(cpcMinValues.morning),
        afternoon:
          aiRecommendation.cpcMin.afternoon !== null
            ? parseFloat(aiRecommendation.cpcMin.afternoon)
            : formatValue(cpcMinValues.afternoon),
        evening:
          aiRecommendation.cpcMin.evening !== null
            ? parseFloat(aiRecommendation.cpcMin.evening)
            : formatValue(cpcMinValues.evening),
      };

      // Atualiza os valores de CPC Max localmente
      const newMaxValues = {
        dawn:
          aiRecommendation.cpcMax.dawn !== null
            ? parseFloat(aiRecommendation.cpcMax.dawn)
            : formatValue(cpcMaxValues.dawn),
        morning:
          aiRecommendation.cpcMax.morning !== null
            ? parseFloat(aiRecommendation.cpcMax.morning)
            : formatValue(cpcMaxValues.morning),
        afternoon:
          aiRecommendation.cpcMax.afternoon !== null
            ? parseFloat(aiRecommendation.cpcMax.afternoon)
            : formatValue(cpcMaxValues.afternoon),
        evening:
          aiRecommendation.cpcMax.evening !== null
            ? parseFloat(aiRecommendation.cpcMax.evening)
            : formatValue(cpcMaxValues.evening),
      };

      // Atualiza os estados locais uma única vez
      setCpcMinValues({
        dawn: newMinValues.dawn !== null ? String(newMinValues.dawn) : "",
        morning:
          newMinValues.morning !== null ? String(newMinValues.morning) : "",
        afternoon:
          newMinValues.afternoon !== null ? String(newMinValues.afternoon) : "",
        evening:
          newMinValues.evening !== null ? String(newMinValues.evening) : "",
      });

      setCpcMaxValues({
        dawn: newMaxValues.dawn !== null ? String(newMaxValues.dawn) : "",
        morning:
          newMaxValues.morning !== null ? String(newMaxValues.morning) : "",
        afternoon:
          newMaxValues.afternoon !== null ? String(newMaxValues.afternoon) : "",
        evening:
          newMaxValues.evening !== null ? String(newMaxValues.evening) : "",
      });

      // Envia todos os valores de CPC Min em uma única requisição
      const minResponse = await setCpcMin(
        campaignId,
        adGroupId,
        newMinValues,
        projectId
      );

      if (minResponse && minResponse.success) {
        // Atualiza os valores armazenados após a mudança
        previousMinValues.current = {
          dawn: newMinValues.dawn !== null ? String(newMinValues.dawn) : "",
          morning:
            newMinValues.morning !== null ? String(newMinValues.morning) : "",
          afternoon:
            newMinValues.afternoon !== null
              ? String(newMinValues.afternoon)
              : "",
          evening:
            newMinValues.evening !== null ? String(newMinValues.evening) : "",
        };
      } else {
        // Restaura os valores anteriores em caso de erro
        setCpcMinValues(previousMinValues.current);
        toast.error(
          `Erro ao atualizar CPC Min: ${
            minResponse?.message || "Erro desconhecido"
          }`
        );
      }

      // Envia todos os valores de CPC Max em uma única requisição
      const maxResponse = await setCpcMax(
        campaignId,
        adGroupId,
        newMaxValues,
        projectId
      );

      if (maxResponse && maxResponse.success) {
        // Atualiza os valores armazenados após a mudança
        previousMaxValues.current = {
          dawn: newMaxValues.dawn !== null ? String(newMaxValues.dawn) : "",
          morning:
            newMaxValues.morning !== null ? String(newMaxValues.morning) : "",
          afternoon:
            newMaxValues.afternoon !== null
              ? String(newMaxValues.afternoon)
              : "",
          evening:
            newMaxValues.evening !== null ? String(newMaxValues.evening) : "",
        };
      } else {
        // Restaura os valores anteriores em caso de erro
        setCpcMaxValues(previousMaxValues.current);
        toast.error(
          `Erro ao atualizar CPC Max: ${
            maxResponse?.message || "Erro desconhecido"
          }`
        );
      }

      // Fecha o modal apenas após todas as operações serem concluídas
      setShowAiModal(false);
      toast.success(
        "Valores de CPC atualizados com base nas recomendações da IA."
      );
    } catch (error) {
      // Restaura os valores anteriores em caso de erro
      setCpcMinValues(previousMinValues.current);
      setCpcMaxValues(previousMaxValues.current);
      toast.error(
        `Erro ao aplicar recomendações: ${error.message || "Erro desconhecido"}`
      );
    } finally {
      // Garante que o estado de loading seja atualizado apenas uma vez no final
      setApplyingRecommendations(false);
    }
  }, [
    aiRecommendation,
    applyingRecommendations,
    campaignId,
    adGroupId,
    projectId,
    cpcMinValues,
    cpcMaxValues,
    formatValue,
  ]);

  // Função para zerar todos os valores de CPC
  const resetAllCpcValues = useCallback(async () => {
    // Evita zerar valores se o modal de IA estiver aberto
    if (showAiModal) {
      toast.error("Feche o modal de recomendações antes de zerar os valores.");
      return;
    }

    try {
      // Valores vazios (string vazia) para todos os períodos
      // Usamos string vazia em vez de null para manter consistência com o restante do código
      const emptyValues = {
        dawn: "",
        morning: "",
        afternoon: "",
        evening: "",
      };

      // Atualiza os valores locais
      setCpcMinValues(emptyValues);
      setCpcMaxValues(emptyValues);

      // Valores nulos para a API (a API espera null para remover valores)
      const nullValues = {
        dawn: null,
        morning: null,
        afternoon: null,
        evening: null,
      };

      // Envia valores nulos para CPC Min
      const minResponse = await setCpcMin(
        campaignId,
        adGroupId,
        nullValues,
        projectId
      );

      // Envia valores nulos para CPC Max
      const maxResponse = await setCpcMax(
        campaignId,
        adGroupId,
        nullValues,
        projectId
      );

      if (minResponse.success && maxResponse.success) {
        // Atualiza os valores armazenados
        previousMinValues.current = { ...emptyValues };
        previousMaxValues.current = { ...emptyValues };
        toast.success("Todos os valores de CPC foram zerados com sucesso!");
      } else {
        // Se houver erro, restaura os valores anteriores
        setCpcMinValues(previousMinValues.current);
        setCpcMaxValues(previousMaxValues.current);
        toast.error("Erro ao zerar valores de CPC.");
      }
    } catch (error) {
      // Se houver erro, restaura os valores anteriores
      setCpcMinValues(previousMinValues.current);
      setCpcMaxValues(previousMaxValues.current);
      toast.error(
        `Erro ao zerar valores: ${error.message || "Erro desconhecido"}`
      );
    }
  }, [showAiModal, campaignId, adGroupId, projectId]);

  // Memoizando as funções que são passadas como props para o modal
  const handleCloseModal = useCallback(() => {
    setShowAiModal(false);
  }, []);

  useEffect(() => {
    fetchCpcValues();
  }, [fetchCpcValues, campaignId]);

  // Memoizando o componente do modal para evitar re-renderizações desnecessárias
  const aiModal = React.useMemo(() => {
    if (!showAiModal || !aiRecommendation) return null;

    return (
      <AiRecommendationModal
        aiRecommendation={aiRecommendation}
        onClose={handleCloseModal}
        onApply={applyAiRecommendations}
        applyingRecommendations={applyingRecommendations}
      />
    );
  }, [
    showAiModal,
    aiRecommendation,
    handleCloseModal,
    applyAiRecommendations,
    applyingRecommendations,
  ]);

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-medium text-gray-800 mb-4">
        Configuração de CPC
      </h2>

      {/* Botão de análise por IA */}
      <div className="flex flex-col items-center justify-center mb-5">
        <button
          onClick={fetchAiRecommendations}
          disabled={aiLoading}
          className="flex items-center text-blue-500 hover:text-blue-700 text-sm font-medium transition-colors"
        >
          {aiLoading ? (
            <LoadingSpinner color="blue-500" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clipRule="evenodd"
              />
            </svg>
          )}
          Otimizar com IA
        </button>

        {/* Mensagem de status da análise */}
        {aiLoading && aiLoadingStep && (
          <div className="mt-2 text-xs text-gray-500 animate-pulse">
            {aiLoadingStep}
          </div>
        )}
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <LoadingSpinner color="blue-500" />
          <p className="ml-2 text-gray-600 text-sm">Carregando...</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-6">
            {/* CPC Min */}
            <div className="col-span-1">
              <h3 className="text-sm font-medium text-gray-700 mb-3">
                Valor Mínimo
              </h3>
              <div className="space-y-4">
                {["dawn", "morning", "afternoon", "evening"].map((period) => (
                  <div key={period} className="relative">
                    <input
                      type="text"
                      id={`cpcMin-${period}`}
                      className="peer h-9 w-full bg-gray-50 border border-gray-200 rounded px-3 text-sm text-gray-800 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                      placeholder="0.00"
                      value={cpcMinValues[period]}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Formata o valor em tempo real para exibição
                        setCpcMinValues({
                          ...cpcMinValues,
                          [period]: value,
                        });
                      }}
                      onBlur={(e) =>
                        handleSaveCpc("min", period, e.target.value, projectId)
                      }
                    />
                    <label
                      htmlFor={`cpcMin-${period}`}
                      className="absolute -top-2 left-2 px-1 text-xs text-gray-500 bg-white"
                    >
                      {period === "dawn"
                        ? "00:00-05:59"
                        : period === "morning"
                        ? "06:00-11:59"
                        : period === "afternoon"
                        ? "12:00-17:59"
                        : "18:00-23:59"}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* CPC Max */}
            <div className="col-span-1">
              <h3 className="text-sm font-medium text-gray-700 mb-3">
                Valor Máximo
              </h3>
              <div className="space-y-4">
                {["dawn", "morning", "afternoon", "evening"].map((period) => (
                  <div key={period} className="relative">
                    <input
                      type="text"
                      id={`cpcMax-${period}`}
                      className="peer h-9 w-full bg-gray-50 border border-gray-200 rounded px-3 text-sm text-gray-800 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                      placeholder="0.00"
                      value={cpcMaxValues[period]}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Formata o valor em tempo real para exibição
                        setCpcMaxValues({
                          ...cpcMaxValues,
                          [period]: value,
                        });
                      }}
                      onBlur={(e) =>
                        handleSaveCpc("max", period, e.target.value, projectId)
                      }
                    />
                    <label
                      htmlFor={`cpcMax-${period}`}
                      className="absolute -top-2 left-2 px-1 text-xs text-gray-500 bg-white"
                    >
                      {period === "dawn"
                        ? "00:00-05:59"
                        : period === "morning"
                        ? "06:00-11:59"
                        : period === "afternoon"
                        ? "12:00-17:59"
                        : "18:00-23:59"}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Botão para zerar valores */}
          <div className="flex justify-center mt-6">
            <button
              onClick={resetAllCpcValues}
              className="px-4 py-2 text-xs bg-gray-50 border border-gray-200 rounded text-gray-600 hover:bg-gray-100 transition-colors"
            >
              Zerar valores
            </button>
          </div>
        </>
      )}

      {/* Modal de recomendações da IA */}
      {aiModal}
    </div>
  );
};

export default React.memo(CpcEditor);
